import React, { memo, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Icon from "@mui/material/Icon";
import Toaster from "../../common/Toaster";
import { formatDateNoTime, getFilteredData } from "../../common/utils";
import { getProject } from "../../reducers/project";
import { createTask, getTask, updateTask } from "../../reducers/task";
import "../style.css";
import "./task.css";
import CreateButton from "../../utils/createButton";

/**
 * Editor component
 * @param {import('react-router-dom').RouteComponentProps<{ slug?: string }>} props
 * @example
 * <Editor />
 */
function CreateTask() {
  const dispatch = useDispatch();
  const { task } = useSelector((state) => state.task);
  const { project } = useSelector((state) => state?.project);
  const { slug } = useParams();
  const location = useLocation();
  const { state } = location;
  const [toasterMessage, setToasterMessage] = useState();
  const [tasks, setTasks] = useState();
  const [toasterMessageSucces, setToasterMessageSucces] = useState(false);
  const [taskId, setTaskId] = useState();
  const [currentTask, setCurrentTask] = useState({});
  const [disabledbutton, setDisabledButton] = useState(true);
  const toDoTasks = getFilteredData(tasks, "TO DO");
  const projectId = location.pathname.match(/[0-9]+/)[0];

  useEffect(() => {
    setTaskId(task?.id);
  }, []);

  useEffect(() => {
    dispatch(getProject(projectId));
  }, []);

  useEffect(() => {
    setTasks(project?.Tasks);
  }, [project]);

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */

  const changeEstimation = (e) => {
    if (task) setDisabledButton(false);
    setCurrentTask({ ...currentTask, estimation: +e.target.value });
  };
  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeDeadline = (date) => {
    if (task) setDisabledButton(false);
    setCurrentTask({ ...currentTask, deadline: date });
  };
  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeTitle = (e) => {
    if (task ? task?.title?.trim() !== e.target.value?.trim() : e.target.value.replace(/\s/g, "").length !== 0)
      setDisabledButton(false);
    else setDisabledButton(true);

    setCurrentTask({ ...currentTask, title: e.target.value?.trimStart() });
  };
  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeDescription = (e) => {
    if (e.target.value !== "" || task) setDisabledButton(false);
    const limit = 500;

    if (currentTask?.description.length === limit || currentTask?.description.length === 499)
      setToasterMessage("The field must contain no more than 500 characters.");
    else if (currentTask?.description.length < limit - 2) setToasterMessage(null);
    setCurrentTask({ ...currentTask, description: e.target.value.slice(0, limit) });
  };

  const changeReference = (e) => {
    if (e.target.value !== "" || task) setDisabledButton(false);
    setCurrentTask({ ...currentTask, reference: e.target.value });
  };
  /**
   * Reset the form values
   */
  const defaultTaskValues = {
    title: "",
    description: "",
    taskType: "",
    label: "",
    milestone: "",
    Epic: "",
    estimation: "",
    notify: true,
    status: "TO DO",
    reference: "",
    relatedTo: null,
    deadline: currentTask.deadline ? "" : null,
    priority: "Low",
  };

  const reset = () => {
    if (task) {
      setCurrentTask({
        ...task,
        deadline: task?.deadline ? formatDateNoTime(new Date(task?.deadline)) : null,
      });
    } else {
      setCurrentTask(defaultTaskValues);
    }
  };

  /**
   * @type {React.MouseEventHandler<HTMLButtonElement>}
   */
  const submitForm = async (event) => {
    setToasterMessageSucces(false);
    event.preventDefault();
    const taskBody = {
      ...currentTask,
      title: currentTask?.title?.trim(),
      projectId,
      relatedTo: taskId !== task?.id ? taskId : null,
      nextTaskId: toDoTasks ? toDoTasks[0]?.id : null,
    };
    const editBody = {
      ...currentTask,
      slug,
      projectId,
    };
    const result = await dispatch(state?.isEditScreen ? updateTask(editBody) : createTask(taskBody));
    if (result.meta.requestStatus === "fulfilled") {
      setToasterMessageSucces(true);
    }
    dispatch(getProject(projectId));

    if (!state?.isEditScreen) {
      setCurrentTask(defaultTaskValues);
    }
  };
  useEffect(() => {
    reset();
    if (state?.isEditScreen) {
      dispatch(getTask(slug));
    }
  }, [slug]);
  useEffect(reset, [task]);

  return (
    <div className="editor-page">
      <Toaster
        message={
          task
            ? toasterMessageSucces && "The changes were successful."
            : toasterMessageSucces && "The task has been created."
        }
        type="success"
      />
      <Toaster message={toasterMessage} type="warning" />
      <div className="page">
        <div className="col-md-10 offset-md-1 col-xs-12">
          <form>
            <fieldset>
              <div className="container">
                <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                  <div className="col-sm-12" style={{ width: "auto" }}>
                    <div>
                      <fieldset className="form-group">
                        <p className="text-muted h5 py-2">Title</p>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Enter task title"
                          value={currentTask?.title || ""}
                          onChange={changeTitle}
                          maxLength={50}
                          data-testid="title-input"
                        />
                      </fieldset>{" "}
                      <fieldset className="form-group">
                        <p className="text-muted h5 py-2">Description</p>
                        <textarea
                          className="form-control form-control-lg"
                          type="text"
                          rows="6"
                          placeholder="Enter some brief about task.."
                          value={currentTask?.description || ""}
                          onChange={changeDescription}
                          data-testid="description-input"
                        />
                      </fieldset>
                    </div>
                    <div className="form-control form-control-lg deadline-story-input">
                      <div className="container ms-0">
                        <div className="row">
                          <div className="col-sm-12">
                            <fieldset className="form-group">
                              <p className="text-muted h5 py-2">Deadline</p>
                              <div className="form-field align-middle pt-1 date-box" style={{ maxWidth: "200px" }}>
                                <DatePicker
                                  className="form-control form-control-lg input-date task-date-input"
                                  onChange={(date) => changeDeadline(date)}
                                  id="due Date"
                                  selected={currentTask?.deadline || ""}
                                  minDate={new Date().toISOString().split("T")[0]}
                                  placeholderText="mm/dd/yyyy"
                                  data-testid="deadline-input"
                                />

                                <span
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    marginTop: "-27px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <Icon
                                    style={
                                      currentTask?.deadline
                                        ? { fontSize: "20px", color: "black" }
                                        : { fontSize: "20px", color: "#6C757D" }
                                    }
                                  >
                                    calendar_today
                                  </Icon>
                                </span>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12">
                            <fieldset className="form-group">
                              <p className="text-muted h5 py-2">Story Points Estimate</p>
                              <div className="d-flex" style={{ gap: "4px" }}>
                                <button
                                  type="button"
                                  className={`form-control form-control-lg${
                                    currentTask?.estimation === 1 ? `  story-selected` : ` story `
                                  }`}
                                  onClick={changeEstimation}
                                  value={1}
                                  data-testid="estimation-input"
                                  onKeyDown={(e) => e.key === "0" && e.preventDefault()}
                                >
                                  1
                                </button>
                                <button
                                  type="button"
                                  className={`form-control form-control-lg${
                                    currentTask?.estimation === 2 ? `  story-selected` : ` story `
                                  }`}
                                  onClick={changeEstimation}
                                  value={2}
                                  data-testid="estimation-input"
                                  onKeyDown={(e) => e.key === "0" && e.preventDefault()}
                                >
                                  2
                                </button>
                                <button
                                  type="button"
                                  className={`form-control form-control-lg${
                                    currentTask?.estimation === 3 ? `  story-selected` : ` story `
                                  }`}
                                  onClick={changeEstimation}
                                  value={3}
                                  data-testid="estimation-input"
                                  onKeyDown={(e) => e.key === "0" && e.preventDefault()}
                                >
                                  3
                                </button>
                                <button
                                  type="button"
                                  className={`form-control form-control-lg${
                                    currentTask?.estimation === 5 ? `  story-selected` : ` story `
                                  }`}
                                  onClick={changeEstimation}
                                  value={5}
                                  data-testid="estimation-input"
                                  onKeyDown={(e) => e.key === "0" && e.preventDefault()}
                                >
                                  5
                                </button>
                                <button
                                  type="button"
                                  className={`form-control form-control-lg${
                                    currentTask?.estimation === 8 ? `  story-selected` : ` story `
                                  }`}
                                  onClick={changeEstimation}
                                  value={8}
                                  data-testid="estimation-input"
                                  onKeyDown={(e) => e.key === "0" && e.preventDefault()}
                                >
                                  8
                                </button>
                                <button
                                  type="button"
                                  className={`form-control form-control-lg${
                                    currentTask?.estimation === 13 ? `  story-selected` : ` story `
                                  }`}
                                  onClick={changeEstimation}
                                  value={13}
                                  data-testid="estimation-input"
                                  onKeyDown={(e) => e.key === "0" && e.preventDefault()}
                                >
                                  13
                                </button>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                    <fieldset className="form-group">
                      <p className="text-muted h5 py-2 mt-2">Reference</p>
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Enter task reference"
                        value={currentTask?.reference || ""}
                        onChange={changeReference}
                        data-testid="reference-input"
                      />
                    </fieldset>{" "}
                    <div className="container me-0 pe-0 mt-4">
                      <CreateButton
                        disabled={
                          task
                            ? disabledbutton ||
                              currentTask?.title?.replace(/\s/g, "").length === 0 ||
                              currentTask?.estimation === ""
                            : currentTask?.title?.replace(/\s/g, "").length === 0 ||
                              currentTask?.deadline === "" ||
                              currentTask?.estimation === ""
                        }
                        onSubmit={submitForm}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}

export default memo(CreateTask);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../agent";
import { serializeError } from "../common/utils";

export const getOrganization = createAsyncThunk("organization/getAll", agent.Organization.getOrganization, {
  serializeError,
});

export const updateOrganization = createAsyncThunk(
  "organization/updateOrganization",
  agent.Organization.updateOrganization,
  { serializeError }
);

export const uploadLogo = createAsyncThunk("organization/uploadLogo", agent.Organization.uploadLogo, {
  serializeError,
});

export const deleteLogo = createAsyncThunk("organization/deleteLogo", agent.Organization.deleteLogo, {
  serializeError,
});

const initialState = {
  organization: [],
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganization.fulfilled, (organizationState, action) => {
      const state = organizationState;
      state.organization = action.payload.organization;
    });

    builder.addCase(updateOrganization.fulfilled, () => {});

    builder.addCase(uploadLogo.fulfilled, () => {});

    builder.addCase(deleteLogo.fulfilled, () => {});
  },
});

export const { organizationPageUnloaded } = organizationSlice.actions;

export default organizationSlice.reducer;

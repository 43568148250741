import { AES, enc } from "crypto-js";
import { PropTypes } from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { getCode, login, register } from "./authSlice";
import useTimeout from "../../hooks/useTimeout";
import "./auth.css";

/**
 * Auth screen component
 *
 * @example
 * <AuthScreen />
 */
function AuthScreen({ isRegisterScreen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(searchParams.get("email") ? searchParams.get("email") : "");
  const [isShown, setIsShown] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const user = JSON.parse(window.localStorage.getItem("user"));
  const confirmationCode = searchParams.get("code");
  const [errorMessage, setErrorMessage] = useState({});
  const [responseStatus, setResponseStatus] = useState();
  const [responseError, setResponseError] = useState();
  const [codeResponse, setCodeResponse] = useState();
  const token = window.localStorage.getItem("jwt");
  const [timeout] = useTimeout(() => {
    setErrorMessage({ ...errorMessage, passwordError: "" });
  }, 2000);
  useEffect(() => {
    if (user && !isRegisterScreen) {
      setEmail(user.email);
      setPassword(AES.decrypt(user.password, user.email).toString(enc.Utf8));
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (isRegisterScreen)
      dispatch(getCode({ code: confirmationCode })).then((response) => setCodeResponse(response.payload));
  }, []);
  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeUsername = (event) => {
    setUsername(event.target.value?.trimStart());
  };

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeEmail = (event) => {
    setEmail(event.target.value.replaceAll(" ", ""));
    setResponseError("");
  };

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changePassword = (event) => {
    const trimedPassword = event.target.value.replace(/\s/g, "");
    if (trimedPassword.length <= 30) setPassword(trimedPassword);
    setResponseError("");
  };

  const isChecked = () => {
    if (rememberMe) setRememberMe(false);
    else setRememberMe(true);
  };

  /**
   * @type {React.FormEventHandler<HTMLFormElement>}
   */
  useEffect(() => {
    if (responseStatus?.type.includes("fulfilled")) {
      navigate("/");
      setEmail("");
      setPassword("");
      setUsername("");
      window.localStorage.setItem("status", true);
    } else if (responseStatus) {
      setResponseError("The email address or password is incorrect or the account does not exist");
    }
  }, [responseStatus]);

  const validation = (event) => {
    const message = username ? event?.target?.value?.trimStart() : event?.target?.value?.replaceAll(" ", "");
    const lowerCase = /[a-z]/g;
    const upperCase = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCase = /.*[-'/`~!#$@_%+=.,^&(){}[\];:"<>?\\]/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    const emptyStringMessage = "This field is required.";
    if (isRegisterScreen) {
      if (username === "" && email === "" && password === "")
        setErrorMessage({
          ...errorMessage,
          usernameError: emptyStringMessage,
          passwordError: emptyStringMessage,
          emailError: emptyStringMessage,
        });
      else {
        if (username === "")
          setErrorMessage({
            ...errorMessage,
            usernameError: emptyStringMessage,
          });
        if (email === "")
          setErrorMessage({
            ...errorMessage,
            emailError: emptyStringMessage,
          });
        if (password === "")
          setErrorMessage({
            ...errorMessage,
            passwordError: emptyStringMessage,
          });
        if (username === "" && password === "")
          setErrorMessage({
            ...errorMessage,
            usernameError: emptyStringMessage,
            passwordlError: emptyStringMessage,
          });
        if (username === "" && email === "")
          setErrorMessage({
            ...errorMessage,
            usernameError: emptyStringMessage,
            emailError: emptyStringMessage,
          });

        if (email === "" && password === "")
          setErrorMessage({
            ...errorMessage,
            emailError: emptyStringMessage,
            passwordError: emptyStringMessage,
          });
      }
    }
    if (event?.target?.id === "password")
      if (!message.match(lowerCase))
        setErrorMessage({ ...errorMessage, passwordError: "Password should contains lowercase letters!" });
      else if (!message.match(upperCase))
        setErrorMessage({ ...errorMessage, passwordError: "Password should contain uppercase letters!" });
      else if (!message.match(numbers))
        setErrorMessage({ ...errorMessage, passwordError: "Password should contains numbers also!" });
      else if (!message.match(specialCase))
        setErrorMessage({ ...errorMessage, passwordError: "Password should contains special caracters also!" });
      else if (message.length < 8)
        setErrorMessage({ ...errorMessage, passwordError: "Password length should be more than 8." });
      else if (message.replace(/\s/g, "").length === 30) {
        setErrorMessage({
          ...errorMessage,
          passwordError: "The length of the password cannot be more than 30 characters",
        });

        timeout();
      } else setErrorMessage({ ...errorMessage, passwordError: "" });
    else if (event?.target?.id === "email")
      if (!message.match(emailRegex))
        setErrorMessage({ ...errorMessage, emailError: "Please match the format requested ex: example@domain.com" });
      else setErrorMessage({ ...errorMessage, emailError: "" });
    else if (event?.target?.id === "username")
      if (message.match(specialCase))
        setErrorMessage({
          ...errorMessage,
          usernameError: "Please create a username with only alphanumeric characters.",
        });
      else if (message.length < 4)
        setErrorMessage({ ...errorMessage, usernameError: "Username length should be more than 4." });
      else if (message.length === 225)
        setErrorMessage({
          ...errorMessage,
          usernameError: "The length of the username cannot be more than 225 characters",
        });
      else setErrorMessage({ ...errorMessage, usernameError: "" });
  };

  const authenticateUser = (event) => {
    event.preventDefault();
    validation();
    setIsShown(false);
    if (Object.values(errorMessage).every((item) => item === "") && email !== "" && password !== "")
      dispatch(
        isRegisterScreen
          ? register({
              ...codeResponse,
              username: username?.trim(),
              email: email?.trim(),
              password,
              code: confirmationCode,
            })
          : login({ email, password })
      ).then((response) => {
        setResponseStatus(response);
      });

    if (rememberMe) {
      window.localStorage.setItem(
        "user",
        JSON.stringify({
          email,
          password: AES.encrypt(password, email).toString(),
        })
      );
    } else {
      window.localStorage.setItem("user", null);
    }
  };

  if (!codeResponse && isRegisterScreen) {
    return null;
  }

  return (
    <div className="auth-page">
      {!token && (
        <div className="container page">
          <div className="row d-flex align-items-center justify-content-center px-4 px-lg-0 px-md-0">
            <div className="auth_box ">
              <form onSubmit={authenticateUser} className={`${isRegisterScreen && "py-4 "} form`}>
                {" "}
                <p
                  style={{ fontSize: "40px", fontWeight: "600", paddingBottom: "30px" }}
                  className="text-xs-center m-0 font"
                >
                  {isRegisterScreen ? "Sign Up" : "Log In"}
                </p>
                {responseError && !isRegisterScreen && (
                  <div
                    className="d-flex justify-content-center align-items-center text-center bg-danger text-white rounded mb-2"
                    style={{ minHeight: "40px" }}
                  >
                    <Icon style={{ fontSize: "18px", color: "white", marginRight: "4px" }}>warning</Icon>
                    {responseError}
                  </div>
                )}
                <fieldset disabled={token}>
                  {isRegisterScreen && (
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-lg input"
                        type="text"
                        pattern="[0-9]*"
                        placeholder="Organization"
                        value={codeResponse?.organization?.name}
                        disabled
                      />
                    </fieldset>
                  )}
                  {isRegisterScreen ? (
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-lg input"
                        type="text"
                        placeholder="Username"
                        autoComplete="username"
                        maxLength={225}
                        name="username"
                        value={username}
                        onChange={(e) => {
                          changeUsername(e);
                          validation(e);
                        }}
                        id="username"
                      />
                      {isRegisterScreen && <div style={{ color: "red" }}>{errorMessage.usernameError}</div>}
                    </fieldset>
                  ) : null}
                  <fieldset className="form-group">
                    <input
                      className={`${
                        responseError && !isRegisterScreen && "border border-danger"
                      } form-control form-control-lg input`}
                      type="text"
                      placeholder="Email"
                      value={email}
                      disabled={searchParams.get("email") !== null}
                      onChange={(e) => {
                        changeEmail(e);
                        validation(e);
                      }}
                      id="email"
                    />{" "}
                    <div style={{ color: "red" }}>{errorMessage.emailError}</div>
                  </fieldset>
                  {isRegisterScreen && (
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-lg input"
                        type="text"
                        pattern="[0-9]*"
                        placeholder="Role"
                        value={codeResponse?.role?.name}
                        disabled
                      />
                    </fieldset>
                  )}
                  <fieldset className="form-group">
                    {" "}
                    <div className="input-group align-middle input">
                      <input
                        className={`${
                          responseError && !isRegisterScreen && "border border-danger input"
                        } form-control form-control-lg input border-end-0`}
                        type={isShown ? "text" : "password"}
                        autoComplete="new-password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => {
                          changePassword(e);
                          validation(e);
                        }}
                        id="password"
                      />{" "}
                      <span
                        className={`${
                          responseError && !isRegisterScreen && "border border-danger"
                        } input-group-text bg-white border-start`}
                        id="basic-addon1"
                      >
                        <button
                          type="button"
                          onClick={() => (isShown === false ? setIsShown(true) : setIsShown(false))}
                          className="text-center me-2"
                          style={{
                            border: 0,
                            background: "none",
                            padding: 0,
                          }}
                        >
                          {isShown ? (
                            <Icon style={{ fontSize: "20px", color: "#6C757D", marginBottom: "-3px" }}>visibility</Icon>
                          ) : (
                            <Icon style={{ fontSize: "20px", color: "#6C757D", marginBottom: "-3px" }}>
                              visibility_off
                            </Icon>
                          )}
                        </button>
                      </span>
                    </div>
                    <div style={{ color: "red" }}>{errorMessage.passwordError}</div>
                  </fieldset>
                  {isRegisterScreen && (
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-lg input"
                        type="text"
                        pattern="[0-9]*"
                        placeholder="Confirmation code"
                        value={confirmationCode}
                        disabled
                      />
                    </fieldset>
                  )}
                  <div>
                    {!isRegisterScreen && (
                      <div className="form-check" style={{ fontSize: "17px" }}>
                        <label className="form-check-label font" htmlFor="flexCheckDefault">
                          Remember me
                          <input
                            className="form-check-input check-box"
                            type="checkbox"
                            id="flexCheckDefault"
                            onChange={isChecked}
                            checked={rememberMe}
                          />
                        </label>
                      </div>
                    )}
                  </div>{" "}
                  <button
                    className={`btn btn-lg send_button mt-2 ${isRegisterScreen && "mb-3"}`}
                    type="submit"
                    disabled={!isRegisterScreen ? email === "" || password === "" || errorMessage.passwordError : false}
                  >
                    {isRegisterScreen ? "Sign up" : "Log in"}
                  </button>
                  {!isRegisterScreen && (
                    <div style={{ marginTop: "20px" }}>
                      <Link to="forgot-password" className="forgot-password">
                        Forgot password?
                      </Link>
                    </div>
                  )}
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
AuthScreen.defaultProps = {
  isRegisterScreen: false,
};
AuthScreen.propTypes = {
  isRegisterScreen: PropTypes.bool,
};

export default memo(AuthScreen);

import config from "./config";

const API_ROOT = config.apiUrl;
const TRACE_API_ROOT = config.traceApiUrl;

/**
 * Serialize object to URL params
 *
 * @param {Record<string, unknown>} object
 * @returns {String}
 */
function serialize(object) {
  const params = [];

  Object.keys(object).forEach((param) => {
    if (Object.hasOwnProperty.call(object, param) && object[param] != null) {
      params.push(`${param}=${encodeURIComponent(object[param])}`);
    }
  });

  return params.join("&");
}

let token = null;

/**
 *
 * @typedef {Object} ApiError
 * @property {{[property: string]: string}} errors
 */

/**
 * @typedef  {Object} UserAuth
 * @property {Object} user
 * @property {String} user.email
 * @property {String} user.username
 * @property {String} user.bio
 * @property {String} user.image
 * @property {String} user.role
 * @property {String} user.createdBy
 * @property {String} user.token
 * @property {String} user.role
 * @property {String} user.rate

*
 * @typedef  {Object}  Profile
 * @property {String}  username
 * @property {String}  bio
 * @property {String}  image


 *
 * @typedef  {Object}   Article
 * @property {String}   title
 * @property {String}   slug
 * @property {String}   body
 * @property {String}   description
 * @property {Profile}  author
 * @property {Boolean}  favorited
 * @property {Number}   favoritesCount
 * @property {String}   createdAt
 * @property {String}   updatedAt
 *
 * @typedef  {Object}  ArticleResponse
 * @property {Article} article
 *
 * @typedef  {Object}    ArticlesResponse
 * @property {Article[]} articles
 * @property {Number}    articlesCount
 *
 * @typedef  {Object}   Project
 * @property {String}   title
 * @property {String}   slug
 * @property {String}   description
 * @property {Profile}  author
 * @property {String}   createdAt
 * @property {String}   email
 * @property {String}   password
 * @property {String}   updatedAt
 * @property {String}   members
 * @property {Date}     endDate
 * @property {String}   image
 * @property {Date}     startDate
 * @property {Number}   budget
 * @property {String}   status
 * @property {String}   tasks
 * @property {String}   attachments
 * @property {String}   comments
 * @property {String}   notify
 * @property {String}   reference
 * @property {String}   manager
 * @property {String}   userId
 * @property {String}   projectId

 *
 * @typedef  {Object}  ProjectResponse
 * @property {Project} project
 *
 * @typedef  {Object}    ProjectsResponse
 * @property {Project[]} projects
 * @property {Number}    projectsCount
 *
 *
 * @typedef  {Object}   User
 *
 * @typedef  {Object}  UserResponse
 *
 * * @typedef  {Object}   Trace
 * @property {String}   email
 * @property {String}   slug
 * @property {String}   role
 * @property {String}   senderPassword
 * @property {String}   senderEmail
 *
 * @typedef  {Object}  TraceResponse
 * @property {User} user
 *
 * @typedef  {Object}   Task
 * @property {String}   title
 * @property {String}   slug
 * @property {String}   taskType
 * @property {String}   description
 * @property {Profile}  status
 * @property {Array}   assignees
 * @property {String}   label
 * @property {String}   Epic
 * @property {Date}     deadline
 * @property {String}   milestone
 * @property {Number}   estimation
 * @property {String}   reference
 * @property {Array}   attachments
 * @property {Array}   comments
 * @property {Boolean}   notify

 *
 * @typedef  {Object}  TaskResponse
 * @property {Task} task
 *
 * @typedef  {Object}    TasksResponse
 * @property {Task[]}    tasks
 * @property {Number}    tasksCount
 *
 * @typedef  {Object}  Comment
 * @property {String}  id
 * @property {String}  body
 * @property {Profile} author
 * @property {String}  createdAt
 * @property {String}  updatedAt
 *
 * @typedef  {Object}  CommentResponse
 * @property {Comment} comment
 *
 * @typedef  {Object}    CommentsResponse
 * @property {Comment[]} comments
 *
 * @typedef  {Object}  ProfileResponse
 * @property {Profile} profile
 * 
 *  @typedef  {Object} Organization
 * @property {Object} organization
 * @property {String} organization.name
 * @property {String} organization.email
 * @property {String} organization.logo
 * 
 * @typedef  {Object}  OrganizationResponse
 * @property {Organization} organization
 */

/**
 * API client
 *
 * @param {String} url The endpoint
 * @param {Object} body The request's body
 * @param {('GET'|'DELETE'|'PUT'|'POST')} [method='GET'] The request's method
 *
 * @throws {@link ApiError API Error}
 *
 * @returns {Promise<Object>} API response's body
 */
const agent = async (url, body, method = "GET", configUpload = null, isTarce = false) => {
  const headers = new Headers();

  if (!configUpload && body) {
    headers.set("Content-Type", "application/json");
  }

  if (token) {
    headers.set("Authorization", `Token ${token}`);
  }

  const response = await fetch(`${isTarce ? TRACE_API_ROOT : API_ROOT}${url}`, {
    method,
    headers,
    body: body && !configUpload ? JSON.stringify(body) : body || undefined,
  });
  let result;

  try {
    result = await response.json();
  } catch (error) {
    result = { errors: { [response.status]: [response.statusText] } };
  }

  if (!response.ok) throw result;

  return result;
};

const requests = {
  /**
   * Send a DELETE request
   *
   * @param {String} url The endpoint
   * @returns {Promise<Object>}
   */
  del: (url) => agent(url, undefined, "DELETE"),
  /**
   * Send a GET request
   *
   * @param {String} url The endpoint
   * @param {Object} [query={}] URL parameters
   * @param {Number} [query.limit=10]
   * @param {Number} [query.page]
   * @param {String} [query.author]
   * @param {String} [query.tag]
   * @param {String} [query.favorited]
   * @returns {Promise<Object>}
   */
  get: (url, query, isTrace) => {
    const newQuery = query ? { ...query } : {};
    if (Number.isSafeInteger(query?.page)) {
      newQuery.limit = query.limit ? query.limit : 10;
      newQuery.offset = query.page * query.limit;
    }
    delete newQuery.page;
    const isEmptyQuery = query == null || Object.keys(query).length === 0;

    return isEmptyQuery
      ? agent(url, null, "GET", undefined, isTrace)
      : agent(`${url}?${serialize(query)}`, null, "GET", null, isTrace);
  },
  /**
   * Send a PUT request
   *
   * @param {String} url The endpoint
   * @param {Record<string, unknown>} body The request's body
   * @returns {Promise<Object>}
   */
  put: (url, body) => agent(url, body, "PUT"),
  /**
   * Send a POST request
   *
   * @param {String} url The endpoint
   * @param {Record<string, unknown>} body The request's body
   * @returns {Promise<Object>}
   */
  post: (url, body, configUpload, isTrace) => agent(url, body, "POST", configUpload, isTrace),
};

const Auth = {
  /**
   * Get current user
   *
   * @returns {Promise<UserAuth>}
   */
  current: () => requests.get("/user"),
  /**
   * Get confirmation code
   *
   * @returns {Promise<UserAuth>}
   */
  getCode: (codeInfo) => requests.get("/code-info", codeInfo),
  /**
   * Login with email and password
   *
   * @param {String} email
   * @param {String} password
   * @returns {Promise<UserAuth>}
   */
  login: (email, password) => requests.post("/users/login", { user: { email, password } }),
  /**
   * Register with username, email and password
   *
   * @param {String} username
   * @param {String} email
   * @param {String} password
   * @param {Number} [query.role]
   * @param {String} [query.createdBy]
   * @returns {Promise<UserAuth>}
   */
  register: (registerPayload) => requests.post("/register-user", registerPayload),

  /**
   * send email to reset password
   *
   * @param {String}   email
   * @returns {Promise<UserAuth>}
   */

  forgotPassword: (email) => requests.post("/sendEmailToResetPassword", { user: { email } }),

  /**
   * reset password
   *
   * @param {String}   email
   * @param {String}   password
   * @returns {Promise<UserAuth>}
   */

  resetPassword: (email, password) => requests.put("/resetPassword", { user: { email, password } }),
  /**
   * Update user
   *
   * @param {Object}  user
   * @param {String} [user.email]
   * @param {String} [user.username]
   * @param {String} [user.bio]
   * @param {String} [user.image]
   * @param {String} [user.password]
   *  @param {String} [user.role]
   * @param {String} [user.rate]
   * @returns {Promise<UserAuth>}
   */
  save: (user) => requests.put("/user", { user }),
  /**
   * Upload user avatar
   *
   * @returns {Promise<UserAuth>}
   */
  uploadAvatar: (file) => requests.post("/user-image", file, true),
  /**
   * Delete user avatar
   *
   * @returns {Promise<UserAuth>}
   */
  deleteAvatar: (file) => requests.del("/user-image", file),
};

const Articles = {
  /**
   * Get all articles
   *
   * @param {Object} query Article's query parameters
   * @param {Number} [query.limit=10]
   * @param {Number} [query.page]
   * @param {String} [query.author]
   * @param {String} [query.tag]
   * @param {String} [query.favorited]
   * @returns {Promise<ArticlesResponse>}
   */
  all: (query) => requests.get(`/articles`, query),
  /**
   * Get all articles from author
   *
   * @param {String} author Article's author
   * @param {Number} [page]
   * @returns {Promise<ArticlesResponse>}
   */
  byAuthor: (author) => requests.get(`/articles`, { author }),
  /**
   * Get all articles by tag
   *
   * @param {String} tag Article's tag
   * @param {Number} page
   * @returns {Promise<ArticlesResponse>}
   */
  byTag: (tag, page) => requests.get(`/articles`, { tag, page }),
  /**
   * Remove one article
   *
   * @param {String} slug Article's slug
   * @returns {Promise<{}>}
   */
  del: (slug) => requests.del(`/article/${slug}`),

  /**
   * Favorite one article
   *
   * @param {String} slug Article's slug
   * @returns {Promise<ArticleResponse>}
   */
  favorite: (slug) => requests.post(`/setFavoriteArticle/${slug}`),
  /**
   * Get article favorited by author
   *
   * @param {String} username Username
   * @param {Number} [page]
   * @returns {Promise<ArticlesResponse>}
   */
  favoritedBy: (username, page) =>
    requests.get(`/getFavoriteArticle`, {
      favorited: username,
      limit: 5,
      page,
    }),
  /**
   * Get all articles in the user's feed
   *
   * @param {Number} [page]
   * @returns {Promise<ArticlesResponse>}
   */
  feed: (page) => requests.get("/articles/feed", { page }),
  /**
   * Get one article by slug
   *
   * @param {String} slug Article's slug
   * @returns {Promise<ArticleResponse>}
   */
  get: (slug) => requests.get(`/article/${slug}`),
  /**
   * Unfavorite one article
   *
   * @param {String} slug Article's slug
   * @returns {Promise<ArticleResponse>}
   */
  unfavorite: (slug) => requests.post(`/removeFavoriteArticle/${slug}`),
  /**
   * Update one article
   *
   * @param {Partial<Article>} article
   * @returns {Promise<ArticleResponse>}
   */
  update: ({ slug, ...article }) => requests.put(`/article/${slug}`, { article }),
  /**
   * Create a new article
   *
   * @param {Object}   article
   * @param {String}   article.title
   * @param {String}   article.description
   * @param {String}   article.body
   * @returns {Promise<ArticleResponse>}
   */
  create: (article) => requests.post("/articles", { article }),
  /**
   * Upload article image
   *
   * @param {Partial<Article>} article
   * @returns {Promise<ArticleResponse>}
   */
  uploadImage: ({ file, articleId }) => requests.post(`/article/${articleId}/image`, file, true),
  /**
   * Delete article image
   *
   * @param {Partial<Article>} article
   * @returns {Promise<ArticleResponse>}
   */
  deleteImage: ({ file, articleId }) => requests.del(`/article/${articleId}/image`, file),
};

const Organization = {
  /**
   * Get one organization
   *
   * @returns {Promise<OrganizationResponse>}
   */
  getOrganization: () => requests.get(`/organization`),

  /**
   * Update organization
   *
   * @param {Partial<Organization>} organization
   * @returns {Promise<OrganizationResponse>}
   */
  updateOrganization: (organization) => requests.put("/organization", organization),

  /**
   * Upload organization logo
   *
   * @param {Partial<Organization>} organization
   * @returns {Promise<OrganizationResponse>}
   */
  uploadLogo: (file) => requests.post("/organization/image", file, true),
  /**
   * Delete organization logo
   *
   * @param {Partial<Organization>} organization
   * @returns {Promise<OrganizationResponse>}
   */
  deleteLogo: (file) => requests.del("/organization/image", file),
};

const Projects = {
  /**
   * Get all projects
   *
   * @param {Object} query Project's query parameters
   * @param {Number} [query.limit=10]
   * @param {Number} [query.page]
   * @param {String} [query.author]
   * @returns {Promise<ProjectsResponse>}
   */
  all: (query) => requests.get(`/projects`, query),

  /**
   * Get all users
   *
   * @param {Object} query Project's query parameters
   * @param {Number} [query.limit=10]

   * @returns {Promise<ProjectsResponse>}
   */
  allUsers: (query) => requests.get(`/users`, query),

  /**
   * Get all currencies
   *
   * @param {Object} query Project's query parameters
   * @param {Number} [query.limit=10]

   * @returns {Promise<ProjectsResponse>}
   */
  allCurrencies: (query) => requests.get(`/currencies`, query),

  /**
   * Get all projects from author
   *
   * @param {String} author Project's author
   * @param {Number} [page]
   * @returns {Promise<ProjectsResponse>}
   */
  byAuthor: (author, page) => requests.get(`/projects`, { author, limit: 5, page }),

  /**
   * Remove one project
   *
   * @param {String} slug Project's slug
   * @returns {Promise<{}>}
   */
  del: (slug) => requests.del(`/project/${slug}`),

  /**
   * Get all projects in the user's feed
   *
   * @param {Number} [page]
   * @returns {Promise<ProjectsResponse>}
   */
  feed: (page) => requests.get("/projects/feed", { page }),
  /**
   * Get one projects by slug
   *
   * @param {String} slug Project's slug
   * @returns {Promise<ProjectResponse>}
   */
  get: (slug) => requests.get(`/project/${slug}`),

  /**
   * get online users
   *
   * @param {String} slug Project's slug
   * @returns {Promise<ProjectResponse>}
   */
  getOnlineUsers: (projectId) => requests.get(`/online/${projectId}`),

  /**
   * Update one project
   *
   * @param {Partial<Project>} project
   * @returns {Promise<ProjectResponse>}
   */
  update: ({ slug, ...project }) => requests.put(`/project/${slug}`, { project }),
  /**
   * Assign one user
   *
   * @param {Partial<Project>} project
   * @returns {Promise<ProjectResponse>}
   */
  assignUser: ({ userId, projectId }) => requests.put(`/user/${userId}`, { user: { projectId } }),

  /**
   * Assign one user
   *
   * @param {Partial<Project>} project
   * @returns {Promise<ProjectResponse>}
   */
  unAssignUser: ({ userId, projectId }) => requests.put(`/user/remove/${userId}`, { user: { projectId } }),

  /**
   * Delete one user
   *
   * @param {String} slug User slug
   * @returns {Promise<{}>}
   */
  delUser: (userId) => requests.del(`/userDelete/${userId}`),

  /**
   * Create a new project
   *
   * @param {Object}   project
   * @param {String}   project.title
   * @param {String}   project.description
   * @param {String}   project.members
   * @param {Date}     project.endDate
   * @param {String}   project.image
   * @param {Date}     project.startDate
   * @param {Number}   project.budget
   * @param {String}   project.notify


   * @returns {Promise<ProjectResponse>}
   */

  create: (project) => requests.post("/projects", { project }),
  /**
   * Assign one user
   *
   * @param {Partial<Project>} project
   * @returns {Promise<ProjectResponse>}
   */

  /**
   * Upload project file
   *
   * @param {Partial<Task>} task
   * @returns {Promise<TaskResponse>}
   */
  uploadFile: ({ file, projectId }) => requests.post(`/upload/project/${projectId}`, file, true),
};

const Tasks = {
  /**
   * Get all tasks
   *
   * @param {Object} query Task's query parameters
   * @param {Number} [query.limit=10]
   * @param {Number} [query.page]
   * @param {String} [query.author]
   * @returns {Promise<TaskResponse>}
   */
  all: (query) => requests.get(`/tasks`, query),
  /**
   * Get one task by slug
   *
   * @param {String} slug Task's slug
   * @returns {Promise<TaskResponse>}
   */
  get: (slug) => requests.get(`/task/${slug}`),
  /**
   * Create a new task
   *
   * @param {Object}  task
   * @param {String}  task.title
   * @param {String}  task.description
   * @param {String}  task.taskType
   * @param {String}  task.Epic
   * @param {Date}    task.deadline
   * @param {String}  task.milestone
   * @param {Number}  task.estimation
   * @param {String}  task.status
   * @param {String}  task.label
   * @param {String} task.relatedTo
     @param {Boolean}  task.notify
   *
   * @returns {Promise<TaskResponse>}
   */

  create: (task) => requests.post("/tasks", { task }),
  /**
   * Remove one task
   *
   * @param {String} slug Task's slug
   * @returns {Promise<{}>}
   */

  del: (slug) => {
    requests.del(`/task/${slug}`);
  },
  /**
   * Update one task
   *
   * @param {Partial<Task>} task
   * @returns {Promise<TaskResponse>}
   */
  update: ({ slug, ...task }) => requests.put(`/task/${slug}`, { task }),

  /**
   * Assign one user
   *
   * @param {Partial<Task>} task
   * @returns {Promise<TaskResponse>}
   */
  assignUser: ({ userId, taskId }) => requests.put(`/user/${userId}`, { user: { taskId } }),

  /**
   * Assign one user
   *
   * @param {Partial<Task>} task
   * @returns {Promise<TaskResponse>}
   */
  unAssignUser: ({ userId, taskId }) => requests.put(`/user/remove/${userId}`, { user: { taskId } }),

  /**
   * Upload task file
   *
   * @param {Partial<Task>} task
   * @returns {Promise<TaskResponse>}
   */
  upload: ({ file, taskId }) => requests.post(`/upload/task/${taskId}`, file, true),

  /**
   * Delete Upload file
   *
   * @param {Partial<Task>} task
   * @returns {Promise<TaskResponse>}
   */
  deleteUpload: (slug) => {
    requests.del(`/deleteUpload/${slug}`);
  },
};

const ProjectComments = {
  /**
   * Create a new comment for project
   *
   * @param {String} slug Project's slug
   * @param {Object} comment
   * @returns {Promise<CommentResponse>}
   */
  create: (slug, comment) => requests.post(`/project/${slug}/comments`, { comment }),

  /**
   * Edit a comment for project
   *
   * @param {String} commentId Comment's id
   * @param {Object} comment
   * @returns {Promise<CommentResponse>}
   */
  edit: (commentId, comment) => requests.put(`/comment/${commentId}`, { comment }),
  /**
   * Remove one comment
   *
   * @param {String} slug Project's slug
   * @param {String} commentId Comment's id
   * @returns {Promise<{}>}
   */
  delete: (slug) => requests.del(`/comment/${slug}`),
  /**
   * Get all comments for one project
   *
   * @param {String} slug Project's slug
   * @returns {Promise<CommentsResponse>}
   * get: (slug) => requests.get(`/project/${slug}`),
   */
};

const User = {
  /**
   * Invite user
   *
   * @param {Object}   user
   * @param {String}   user.role
   * @param {String}   user.email
   * @returns {Promise<UserResponse>}
   */

  inviteUser: (user) => requests.post("/invite-member", user),
  /**
   * Get all roles
   *
   * @param {Object} query User's query parameters
   * @param {Number} [query.limit=10]

   * @returns {Promise<UserResponse>}
   */
  allRoles: (query) => requests.get(`/roles`, query),
};

const TaskComments = {
  /**
   * Create a new comment for task
   *
   * @param {String} slug Task's slug
   * @param {Object} comment
   * @returns {Promise<CommentResponse>}
   */
  create: (slug, comment) => requests.post(`/task/${slug}/comments`, { comment }),
  /**
   * Edit a comment for task
   *
   * @param {String} commentId Comment's id
   * @param {Object} comment
   * @returns {Promise<CommentResponse>}
   */
  edit: (commentId, comment) => requests.put(`/comment/${commentId}`, { comment }),
  /**
   * Remove one comment
   *
   * @param {String} slug Task's slug
   * @param {String} commentId Comment's id
   * @returns {Promise<{}>}
   * delete: (slug) => requests.del(`/comment/${slug}`),
   */

  /**
   * Get all comments for one task
   *
   * @param {String} slug Task's slug
   * @returns {Promise<CommentsResponse>}
   * get: (slug) => requests.get(`/task/${slug}`),
   */
};

const Comments = {
  /**
   * Create a new comment for article
   *
   * @param {String} slug Article's slug
   * @param {Object} comment
   * @returns {Promise<CommentResponse>}
   */
  create: (slug, comment) => requests.post(`/article/${slug}/comments`, { comment }),
  /**
   * Edit a comment for project
   *
   * @param {String} commentId Comment's id
   * @param {Object} comment
   * @returns {Promise<CommentResponse>}
   */
  edit: (commentId, comment) => requests.put(`/comment/${commentId}`, { comment }),
  /**
   * Get all comments for one article
   *
   * @param {String} slug Article's slug
   * @returns {Promise<CommentsResponse>}
   */
  forArticle: (slug) => requests.get(`/articles/${slug}/comments`),
};

const Profile = {
  /**
   * Get the profile of an user
   *
   * @param {number} id User's username
   * @returns {Profile<ProfileResponse>}
   */
  get: (id) => requests.get(`/profiles/${id}`),
};

const Trace = {
  /**
   * @returns {Profile<TraceResponse>}
   */
  get: (query) => requests.get(`/trace`, query, true),
  /**
   * @returns {Profile<TraceResponse>}
   */

  create: (gitAccess) => requests.post("/git-access", gitAccess, null, true),

  connectProject: ({ gitAccessId, projectId }) =>
    requests.post(`/git-access/${gitAccessId}/project`, { projectId }, null, true),
  /**
   * Get git access by userId
   *
   * @returns {Promise<TraceResponse>}
   */
  getGitAccess: (userId) => requests.get(`/git-access/${userId}`, null, true),
  /**
   * Get commits
   *
   * @returns {Profile<TraceResponse>}
   */
  getGitCommits: (query) => requests.get(`/git-access/commits`, query, true),
};

export default {
  Articles,
  Projects,
  Tasks,
  Auth,
  ProjectComments,
  TaskComments,
  Comments,
  Profile,
  User,
  Trace,
  Organization,
  setToken: (_token) => {
    token = _token;
  },
};

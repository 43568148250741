import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { getUser, logout, selectIsAuthenticated, selectUser } from "../../features/auth/authSlice";
import { projectPageUnloaded } from "../../reducers/project";
import { getOrganization } from "../../reducers/organization";
import { LogoutModal } from "../../common/utils";
import useWindowSize from "../../hooks/useWindowSize";
import "../style.css";
import "./header.css";

/**
 * Navbar when there isn't a logged user
 *
 * @example
 * <LoggedOutNavbar />
 */
function LoggedOutNavbar() {
  return (
    <div className="w-100 m-0">
      <img src="https://api.images.northweb.solutions/important/logo.png" alt="" width="100" height="40" />
    </div>
  );
}

/**
 * Navbar when there is a logged user
 *
 * @example
 * <LoggedInNavbar />
 */
function LoggedInNavbar({ setQuery, width }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentUser = useSelector(selectUser);
  const [isHover, setIsHover] = useState(false);
  const { project } = useSelector((state) => state.project);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getOrganization());
  }, []);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const logoutStyle = {
    border: 0,
    background: "none",
    color: isHover ? "rgba(0,0,0,.6)" : "rgba(0, 0, 0, 0.3)",
  };

  const clearProjectStore = () => {
    dispatch(projectPageUnloaded());
  };

  return (
    <div>
      <ul className="nav navbar-nav pull-xs-right fs-5 justify-content-between d-flex flex-row me-2">
        {" "}
        <div className="d-flex">
          {isAuthenticated === true ? (
            <li className={`nav-item ${width >= 360 && width < 620 && "pe-5"} ${width > 760 && width < 800 && "pe-4"}`}>
              {width > 760 &&
                (pathname === "/" ||
                pathname === "/users" ||
                pathname === `/project-tasks/${project?.id}` ||
                pathname === `/project-detail/${project?.id}/project-tasks/${project?.id}` ? (
                  <div
                    className="form-field align-middle pt-1"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <input
                      type="search"
                      className="form-control"
                      placeholder={
                        pathname === "/" ? "Search project" : `${pathname === "/users" ? "Search user" : "Search task"}`
                      }
                      aria-label="search"
                      aria-describedby="basic-addon1"
                      autoComplete="false"
                      onChange={(event) => setQuery(event.target.value?.trim())}
                      style={{ zIndex: 1 }}
                    />
                    <span style={{ position: "absolute", right: "8px", marginTop: "-30px" }}>
                      <Icon style={{ fontSize: "20px", color: "#6C757D" }}>search</Icon>
                    </span>
                  </div>
                ) : null)}
            </li>
          ) : null}

          {width > 900 && width < 1200 && (
            <li className="nav-item pe-5" data-bs-dismiss="offcanvas" aria-label="Close">
              <Link to={`/user/${currentUser?.id}`} className="nav-link">
                <div className="d-flex">
                  {currentUser?.image ? (
                    <img
                      src={currentUser?.image}
                      width="30"
                      height="30"
                      className="rounded-circle me-1"
                      alt={currentUser?.username}
                    />
                  ) : (
                    <Icon className="example" style={{ fontSize: "30px", marginTop: "5px" }}>
                      account_circle
                    </Icon>
                  )}
                  <p className="example mb-0">{currentUser?.username}</p>
                </div>
              </Link>
            </li>
          )}
        </div>
        <nav
          className={`navbar navbar navbar-expand-xl py-0 `}
          style={width < 1200 && width >= 391 ? { width: "50px" } : { zIndex: 1030 }}
        >
          <div className="container-fluid d-flex align-items-center  justify-content-end pe-0">
            <button
              className="navbar-toggler border-0 p-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
              aria-controls="offcanvasDarkNavbar"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="offcanvas offcanvas-end text-bg"
              tabIndex="-1"
              id="offcanvasDarkNavbar"
              aria-labelledby="offcanvasDarkNavbarLabel"
              style={{ backgroundColor: "#6508FE" }}
            >
              <div className="offcanvas-header justify-content-end" style={{ marginRight: "34px", marginTop: "14px" }}>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  style={{ fontSize: "25px", opacity: "inherit" }}
                />
              </div>

              <div className="offcanvas-body" style={{ overflowY: "hidden" }}>
                <ul className="navbar-nav justify-content-end flex-grow-1 m-0">
                  {isAuthenticated === true ? (
                    <li className="nav-item m-0">
                      <div className="dropdown d-flex">
                        <a
                          className="nav-link dropdown-toggle d-flex pb-0 pe-0"
                          href="# "
                          id="navbarDropdownMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          disabled
                        >
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/555/555417.png"
                            alt=""
                            className="rounded m-1"
                            height={width < 1200 ? "28" : "22"}
                          />
                        </a>

                        {width < 1200 && (
                          <p
                            className="mb-1"
                            style={{
                              fontSize: "31px",
                              color: "white",
                              fontWeight: "bold",
                              marginLeft: "17px",
                            }}
                          >
                            Language
                          </p>
                        )}
                        <ul
                          className="dropdown-menu position-absolute"
                          style={{ minWidth: 0 }}
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <li>
                            <a className="dropdown-item" href="# ">
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/555/555417.png"
                                alt=""
                                className="rounded m-1"
                                height="28"
                              />
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="# ">
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/206/206657.png"
                                alt=""
                                className="rounded m-1"
                                height="28"
                              />
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="# ">
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/555/555629.png"
                                alt=""
                                className="rounded m-1"
                                height="28"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ) : null}
                  <li className="nav-item m-0">
                    <div className="dropdown pe-1 d-flex">
                      {width < 1200 ? (
                        <div className="d-flex">
                          {" "}
                          <p className="nav-link d-flex align-items-center mb-0 pb-1">
                            <Icon style={{ fontSize: "30px", color: "white" }}>notifications_none</Icon>

                            <p className="mb-0 ms-4" style={{ fontSize: "31px", fontWeight: "bold", color: "white" }}>
                              Notifications
                            </p>
                          </p>
                        </div>
                      ) : (
                        <p className="nav-link d-flex">
                          <Icon style={{ fontSize: "26px", color: "white" }}>notifications_none</Icon>
                        </p>
                      )}
                    </div>
                  </li>
                  {currentUser?.role?.name === "manager" && (
                    <li className="nav-item m-0" data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link to="/organization" className="nav-link d-flex">
                        {width < 1200 ? (
                          <div className="d-flex align-items-center example">
                            {" "}
                            <Icon style={{ fontSize: "30px" }}>business_center</Icon>
                            <p className="mb-0 ms-4" style={{ fontSize: "31px", fontWeight: "bold" }}>
                              Organization
                            </p>
                          </div>
                        ) : (
                          <Icon className="example" style={{ fontSize: "26px" }}>
                            business_center
                          </Icon>
                        )}
                      </Link>
                    </li>
                  )}
                  {currentUser?.role?.name === "manager" && (
                    <li className="nav-item m-0" data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link to="/users" className="nav-link d-flex">
                        {width < 1200 ? (
                          <div className="d-flex align-items-center example">
                            {" "}
                            <Icon style={{ fontSize: "30px" }}>people</Icon>
                            <p className="mb-0 ms-4 " style={{ fontSize: "31px", fontWeight: "bold" }}>
                              All users
                            </p>
                          </div>
                        ) : (
                          <Icon className="example" style={{ fontSize: "26px" }}>
                            people
                          </Icon>
                        )}
                      </Link>
                    </li>
                  )}
                  <li className="nav-item m-0" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/news-feed" className="nav-link d-flex mb-2 pb-0 example">
                      {width < 1200 ? (
                        <div className="d-flex align-items-center example">
                          <Icon style={{ fontSize: "30px" }}>feed</Icon>
                          <p className="mb-0 ms-4" style={{ fontSize: "31px", fontWeight: "bold" }}>
                            News Feed
                          </p>
                        </div>
                      ) : (
                        <div className="d-flex example">
                          {" "}
                          <Icon style={{ fontSize: "26px" }}>feed</Icon>
                          <p className="mb-0"> News Feed</p>
                        </div>
                      )}
                    </Link>
                  </li>
                  {currentUser?.role?.name === "manager" && (
                    <li className="nav-item m-0" data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link to="/create-project" className="nav-link d-flex py-1" onClick={clearProjectStore}>
                        {width < 1200 ? (
                          <div className="d-flex align-items-center example">
                            {" "}
                            <Icon style={{ fontSize: "30px" }}>create</Icon>
                            <p className="mb-0 ms-4" style={{ fontSize: "31px", fontWeight: "bold" }}>
                              Create Project
                            </p>
                          </div>
                        ) : (
                          <div className="d-flex example">
                            {" "}
                            <Icon style={{ fontSize: "26px" }}>create</Icon>
                            <p className="mb-0">Create Project</p>
                          </div>
                        )}
                      </Link>
                    </li>
                  )}
                  <li className="nav-item m-0" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/settings" className="nav-link pb-0 mb-2 example">
                      {width < 1200 ? (
                        <div className="d-flex align-items-center example">
                          {" "}
                          <Icon style={{ fontSize: "30px" }}>settings</Icon>
                          <p className="mb-0 ms-4" style={{ fontSize: "31px", fontWeight: "bold" }}>
                            Settings
                          </p>
                        </div>
                      ) : (
                        <Icon className="example" style={{ fontSize: "30px" }}>
                          settings
                        </Icon>
                      )}
                    </Link>
                  </li>
                  <li className="nav-item m-0" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to={`/user/${currentUser?.id}`} className="nav-link d-flex pb-0 mb-2 example ">
                      {width < 1200 ? (
                        <div className="d-flex align-items-center example">
                          {currentUser?.image ? (
                            <img
                              src={currentUser?.image}
                              width="30"
                              height="30"
                              className="rounded-circle"
                              alt={currentUser?.username}
                            />
                          ) : (
                            <Icon style={{ fontSize: "30px" }}>account_circle</Icon>
                          )}
                          <p className="mb-0 ms-4" style={{ marginTop: "-6px", fontSize: "31px", fontWeight: "bold" }}>
                            {currentUser?.username}
                          </p>
                        </div>
                      ) : (
                        <div className="d-flex example">
                          {currentUser?.image ? (
                            <img
                              src={currentUser?.image}
                              width="30"
                              height="30"
                              className="rounded-circle me-1"
                              alt={currentUser?.username}
                            />
                          ) : (
                            <Icon style={{ fontSize: "30px" }}>account_circle</Icon>
                          )}
                          <p className="mb-0">{currentUser?.username}</p>
                        </div>
                      )}
                    </Link>
                  </li>
                  <li className="nav-item m-0" data-bs-dismiss="offcanvas" aria-label="Close">
                    <div className="d-flex">
                      <button
                        className="text-center p-0"
                        type="button"
                        style={logoutStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        data-bs-toggle="modal"
                        data-bs-target="#logoutModal"
                      >
                        {width < 1200 ? (
                          <div className="d-flex align-items-center example">
                            {" "}
                            <Icon style={{ fontSize: "30px" }}>logout</Icon>
                            <p className="mb-0 ms-4" style={{ fontSize: "31px", fontWeight: "bold" }}>
                              Log out
                            </p>
                          </div>
                        ) : (
                          <Icon className="example" style={{ fontSize: "26px", marginTop: "9px" }}>
                            logout
                          </Icon>
                        )}
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </ul>
    </div>
  );
}
LoggedInNavbar.propTypes = {
  setQuery: PropTypes.func,
  width: PropTypes.number,
};
LoggedInNavbar.defaultProps = {
  setQuery: () => {},
  width: 0,
};

/**
 * App header
 *
 * @example
 * <Header />
 */
function Header({ setQuery }) {
  const currentUser = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { organization } = useSelector((state) => state.organization);
  const { project } = useSelector((state) => state.project);
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const token = window.localStorage.getItem("jwt");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    dispatch(logout());
    window.localStorage.setItem("status", false);
    navigate("/");
  };

  const firstPart = organization?.name?.slice(0, organization?.name?.lastIndexOf(" ")).toUpperCase();
  const lastPart = organization?.name?.split(" ").pop().toUpperCase();

  return (
    <div>
      <LogoutModal logoutUser={logoutUser} id="logoutModal" fontSize={15} />
      <div className="pb-1 sticky" style={isAuthenticated ? { position: "fixed" } : { position: "relative" }}>
        <div style={{ backgroundColor: isAuthenticated ? "#6508FE" : "" }}>
          <nav className="navbar navbar-light me-2" style={{ height: "60px" }}>
            <div className={`container ${isAuthenticated && width > 900 && "justify-content-between px-0"}`}>
              <Link to="/" className="navbar-brand me-0" style={{ marginLeft: "10px" }}>
                {isAuthenticated && (
                  <div className="d-flex ms-2" style={{ color: "white" }}>
                    {organization?.logo === null && currentUser !== undefined ? (
                      <img
                        src="https://api.images.northweb.solutions/important/logo-pm.jpg"
                        alt=""
                        width="40"
                        height="40"
                      />
                    ) : null}
                    {organization?.logo && <img src={organization?.logo} alt="" width="40" height="40" />}
                    {organization?.name?.length < 14 ? (
                      <div className="m-0 ms-1 ">{organization?.name}</div>
                    ) : (
                      <div className="m-0 ms-1 d-grid brand">
                        <span className="firstPart" style={{ overflow: "hidden" }}>
                          {firstPart}
                        </span>
                        <span className="lastPart" style={{ overflow: "hidden" }}>
                          {lastPart}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </Link>

              {token ? <LoggedInNavbar width={width} setQuery={setQuery} /> : <LoggedOutNavbar />}
            </div>
          </nav>
        </div>
        {token && (
          <div className={`d-flex justify-content-center ${width <= 760 && "bg-white"} p-1`}>
            {width <= 760 &&
              (pathname === "/" ||
              pathname === "/users" ||
              pathname === `/project-tasks/${project?.id}` ||
              pathname === `/project-detail/${project?.id}/project-tasks/${project?.id}` ? (
                <div
                  className="form-field align-middle pt-1"
                  style={{ position: "relative", display: "inline-block", width: "338px" }}
                >
                  <input
                    type="search"
                    className="form-control"
                    placeholder={
                      pathname === "/" ? "Search project" : `${pathname === "/users" ? "Search user" : "Search task"}`
                    }
                    aria-label="search"
                    aria-describedby="basic-addon1"
                    autoComplete="false"
                    onChange={(event) => setQuery(event.target.value?.trim())}
                    style={{ zIndex: 1 }}
                  />
                  <span style={{ position: "absolute", right: "8px", marginTop: "-30px" }}>
                    <Icon style={{ fontSize: "20px", color: "#6C757D" }}>search</Icon>
                  </span>
                </div>
              ) : null)}
          </div>
        )}
      </div>
    </div>
  );
}
Header.propTypes = {
  setQuery: PropTypes.func,
};

Header.defaultProps = {
  setQuery: () => {},
};

export default memo(Header);

import { configureStore } from "@reduxjs/toolkit";
// import { connectRouter, routerMiddleware } from 'connected-react-router';

import authReducer from "../features/auth/authSlice";
import commentsReducer from "../features/comments/commentsSlice";
import organizationReducer from "../reducers/organization";
import articleReducer from "../reducers/article";
import articlesReducer from "../reducers/articleList";
import commonReducer from "../reducers/common";
import profileReducer from "../reducers/profile";
import projectReducer from "../reducers/project";
import projectsReducer from "../reducers/projectList";
import tasksReducer from "../reducers/taskList";
import taskReducer from "../reducers/task";
import userReducer from "../reducers/user";
import usersReducer from "../reducers/users";
import localStorageMiddleware from "./middleware";
import currenciesReducer from "../reducers/currencies";
import rolesReducer from "../reducers/roles";
import loadingReducer from "../reducers/loading";
import errorsReducer from "../reducers/errors";
import traceReducer from "../reducers/trace";
import gitAccessReducer from "../reducers/gitAccess";
import gitCommitsReducer from "../reducers/gitCommits";

export function makeStore(preloadedState) {
  return configureStore({
    reducer: {
      project: projectReducer,
      projects: projectsReducer,
      task: taskReducer,
      taskList: tasksReducer,
      article: articleReducer,
      articles: articlesReducer,
      auth: authReducer,
      comments: commentsReducer,
      common: commonReducer,
      profile: profileReducer,
      user: userReducer,
      users: usersReducer,
      currencies: currenciesReducer,
      organization: organizationReducer,
      roles: rolesReducer,
      loading: loadingReducer,
      errors: errorsReducer,
      trace: traceReducer,
      gitAccess: gitAccessReducer,
      gitCommits: gitCommitsReducer,
      // router: connectRouter(history),
    },
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      // routerMiddleware(history),
      localStorageMiddleware,
    ],
  });
}

const store = makeStore();

export default store;

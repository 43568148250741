import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllArticles } from "../../reducers/articleList";

/**
 * Show a list with the available pages
 *
 * @example
 * <ArticleListPagination />
 */
function ArticleListPagination() {
  const dispatch = useDispatch();
  const articlesCount = useSelector((state) => state.articles.articlesCount);
  const currentPage = useSelector((state) => state.articles.currentPage);
  const articlesPerPage = useSelector((state) => state.articles.articlesPerPage);

  if (articlesCount <= articlesPerPage) {
    return null;
  }

  const pages = Array.from({ length: Math.ceil(articlesCount / articlesPerPage) }, (_, number) => number);

  const handleClickPage = (page) => () => {
    dispatch(getAllArticles({ page }));
  };

  return (
    <nav>
      <ul className="pagination">
        {pages.map((page) => {
          const isActivePage = page === currentPage;

          return (
            <li
              className={isActivePage ? "page-item active" : "page-item"}
              onClick={handleClickPage(page)}
              key={page.toString()}
              onKeyDown={handleClickPage(page)}
              role="presentation"
            >
              <button type="button" className="page-link">
                {page + 1}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default memo(ArticleListPagination);

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import agent from "../agent";
import { serializeError } from "../common/utils";

export const getGitCommits = createAsyncThunk("trace/getGitAccessCommmits", agent.Trace.getGitCommits, {
  serializeError,
});

const initialState = {
  gitCommits: undefined,
};

const gitCommitsSlice = createSlice({
  name: "gitCommits",
  initialState,
  reducers: {
    gitCommitsPageUnloaded: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getGitCommits.fulfilled, (gitCommitsState, action) => {
      const state = gitCommitsState;
      state.gitCommits = action.payload;
    });

    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      () => {}
    );

    builder.addDefaultCase(() => {});
  },
});

export const { gitCommitsPageUnloaded } = gitCommitsSlice.actions;

export default gitCommitsSlice.reducer;

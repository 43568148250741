import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import agent from "../../agent";
import { failureReducer, isApiError, loadingReducer, Status } from "../../common/utils";

/**
 * @typedef {object} User
 * @property {string} email
 * @property {string} username
 * @property {string} bio
 * @property {string} image
 *
 *
 * @typedef {object} AuthState
 * @property {Status} status
 * @property {string} token
 * @property {User}   user
 */
/**
 * Get auth slice
 *
 * @param {object} state
 * @returns {AuthState}
 */
export const selectAuthSlice = (state) => state.auth;

/**
 * Send a register request
 *
 * @param {object} argument
 * @param {string} argument.username
 * @param {string} argument.email
 * @param {string} argument.password
 */
export const register = createAsyncThunk("auth/register", async (registerPayload, thunkApi) => {
  try {
    const {
      user: { token, ...user },
    } = await agent.Auth.register(registerPayload);

    return { token, user };
  } catch (error) {
    if (isApiError(error)) {
      return thunkApi.rejectWithValue(error);
    }

    throw error;
  }
});

/**
 * Send a login request
 *
 * @param {object} argument
 * @param {string} argument.email
 * @param {string} argument.password
 */
export const login = createAsyncThunk("auth/login", async ({ email, password }, thunkApi) => {
  try {
    const {
      user: { token, ...user },
    } = await agent.Auth.login(email, password);

    return { token, user };
  } catch (error) {
    if (isApiError(error)) {
      return thunkApi.rejectWithValue(error);
    }

    throw error;
  }
});

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (email, thunkApi) => {
  try {
    const {
      user: { ...user },
    } = await agent.Auth.forgotPassword(email);

    return { user };
  } catch (error) {
    if (isApiError(error)) {
      return thunkApi.rejectWithValue(error);
    }

    throw error;
  }
});

export const resetPassword = createAsyncThunk("auth/resetPassword", async ({ email, password }) => {
  const { user } = await agent.Auth.resetPassword(email, password);

  return { user };
});

export const getCode = createAsyncThunk("auth/getCode", async (code) => {
  const response = await agent.Auth.getCode(code);

  return response;
});

export const uploadAvatar = createAsyncThunk("auth/uploadAvatar", async (image) => {
  const response = await agent.Auth.uploadAvatar(image);

  return response;
});

export const deleteAvatar = createAsyncThunk("auth/deleteAvatar", async (image) => {
  const response = await agent.Auth.deleteAvatar(image);

  return response;
});
/**
 * Send a get current user request
 */
export const getUser = createAsyncThunk(
  "auth/getUser",
  async () => {
    const {
      user: { token, ...user },
    } = await agent.Auth.current();

    return { token, user };
  },
  {
    condition: (_, { getState }) => Boolean(selectAuthSlice(getState()).token),
  }
);

/**
 * Get current user
 *
 * @param {object} state
 * @returns {User}
 */
export const selectUser = (state) => selectAuthSlice(state).user;

/**
 * Get is authenticated
 *
 * @param {object} state
 * @returns {boolean}
 */
export const selectIsAuthenticated = createSelector(
  (state) => selectAuthSlice(state).token,
  selectUser,
  (token, user) => Boolean(token && user)
);
/**
 * Send a update user request
 *
 * @param {object} argument
 * @param {string} argument.email
 * @param {string} argument.username
 * @param {string} argument.bio
 * @param {string} argument.image
 * @param {string} argument.password
 */
export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async ({ email, username, bio, image, password, roleId, rate, id, currencyId }, thunkApi) => {
    try {
      const {
        user: { token, ...user },
      } = await agent.Auth.save({ email, username, bio, image, password, roleId, rate, id, currencyId });

      return { token, user };
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  }
);

/**
 * @type {AuthState}
 */
const initialState = {
  status: Status.IDLE,
};

/**
 * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{token: string, user: User}>} action
 */
function successReducer(reducerState, action) {
  const state = reducerState;
  state.status = Status.SUCCESS;
  state.token = action.payload?.token;
  state.user = action.payload?.user;
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /**
     * Log out the user
     */
    logout: () => initialState,
    /**
     * Update token
     *
     * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
     */
    setToken(tokenState, action) {
      const state = tokenState;
      state.token = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, successReducer)
      .addCase(register.fulfilled, successReducer)
      .addCase(getUser.fulfilled, successReducer)
      .addCase(updateUser.fulfilled, successReducer)
      .addCase(forgotPassword.fulfilled, successReducer);

    builder
      .addCase(login.rejected, failureReducer)
      .addCase(register.rejected, failureReducer)
      .addCase(updateUser.rejected, failureReducer)
      .addCase(forgotPassword.rejected, successReducer);

    builder.addMatcher((action) => /auth\/.*\/pending/.test(action.type), loadingReducer);
  },
});

export const { setToken, logout } = authSlice.actions;

export default authSlice.reducer;

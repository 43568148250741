import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectIsAuthenticated } from "../features/auth/authSlice";
import { appLoad, clearRedirect } from "../reducers/common";
import { clearErrors } from "../reducers/errors";
import { getAllProjects } from "../reducers/projectList";
import Breadcrumbs from "./Breadcrumbs";
import Header from "./Header/Header";
import "./style.css";
import Toaster from "../common/Toaster";
import Spinner from "../utils/spinner";
import RoutesComponent from "../app/routes";
import useTimeout from "../hooks/useTimeout";
import useWindowSize from "../hooks/useWindowSize";
// import { getScreenWidth } from "../common/utils";

function App() {
  const dispatch = useDispatch();
  const redirectTo = useSelector((state) => state.common.redirectTo);
  const appLoaded = useSelector((state) => state.common.appLoaded);
  const { loading } = useSelector((state) => state.loading);
  const { errors } = useSelector((state) => state.errors);
  const { organization } = useSelector((state) => state.organization);
  const { width } = useWindowSize();
  const [query, setQuery] = useState("");
  const token = window.localStorage.getItem("jwt");
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoading = loading < 2 ? false : !!loading;

  const location = useLocation();

  const [timeout] = useTimeout(() => {
    dispatch(clearErrors());
  }, 4000);

  timeout();

  useEffect(() => {
    const link = document.querySelector('link[rel="shortcut icon"]');
    if (!token) {
      link.setAttribute("href", "https://api.images.northweb.solutions/important/logo-pm.jpg");
    }
    if (token && organization?.logo) {
      link.setAttribute("href", organization?.logo);
    } else {
      link.setAttribute("href", "https://api.images.northweb.solutions/important/logo-pm.jpg");
    }
  }, [isAuthenticated, organization]);

  useEffect(() => {
    if (organization?.name) {
      document.getElementsByTagName("title")[0].innerHTML = !token ? "Project Management" : organization?.name;
    }
  }, [organization, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAllProjects());
    }
  }, []);

  useEffect(() => {
    if (redirectTo) {
      dispatch(clearRedirect());
    }
  }, [redirectTo]);

  useEffect(() => {
    dispatch(appLoad(token));
  }, []);

  useEffect(() => {
    if (!token && !isAuthenticated) document.body.classList.add("purple");
    else document.body.classList.remove("purple");
  }, [isAuthenticated, token]);

  if (appLoaded) {
    return (
      <>
        <Header setQuery={setQuery} />
        {token && location.pathname !== "/" ? (
          <Breadcrumbs />
        ) : (
          <div className="container" style={width < 760 ? { marginTop: "120px" } : { marginTop: "90px" }} />
        )}

        <Toaster message={errors} type="danger" />
        {isLoading && (
          <p className="d-flex justify-content-center w-100 position-absolute flex-column align-items-center top-50">
            <Spinner />
          </p>
        )}
        <div className={isLoading ? "d-none" : ""}>
          <RoutesComponent query={query} />
        </div>
      </>
    );
  }
}

export default memo(App);

import React, { memo, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { getProfile, profilePageUnloaded } from "../reducers/profile";
import { selectUser } from "../features/auth/authSlice";
import EditButton from "../utils/editButton";
import TimeSheet from "./TimeSheet/TimeSheet";

/**
 * Go to edit profile
 *
 * @example
 * <EditProfile />
 */

function UserInfo({ profile, currentUser }) {
  const location = useLocation();

  return (
    <div className="profile-page container">
      <div className="user-info" style={{ backgroundColor: "#F1F1F1", minHeight: "232px" }}>
        <div className="container">
          <div className="row">
            {currentUser?.username === profile?.username ? (
              <div className="col-12">
                {currentUser?.image ? (
                  <img src={currentUser?.image} className="user-img" alt={currentUser?.username} />
                ) : (
                  <Icon style={{ fontSize: "115px", color: "#6C757D", marginTop: "5px" }}>account_circle</Icon>
                )}
                <h4>{currentUser?.username}</h4>
                <p>{currentUser?.bio}</p>
                <div style={{ marginRight: "6px" }}>
                  <EditButton link={`${location.pathname}/edit-profile`} />
                </div>
              </div>
            ) : (
              <div className="col-xs-12 col-md-10 offset-md-1">
                {profile?.image ? (
                  <img src={profile?.image} className="user-img" alt={profile?.username} />
                ) : (
                  <Icon style={{ fontSize: "115px", color: "#6C757D", marginTop: "5px" }}>account_circle</Icon>
                )}
                <h4>{profile?.username}</h4>
                <p>{profile?.bio}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

UserInfo.propTypes = {
  profile: PropTypes.objectOf(PropTypes.string).isRequired,
  currentUser: PropTypes.objectOf(PropTypes.string).isRequired,
  username: PropTypes.string.isRequired,
};

/**
 * Profile screen component
 * @param {import('react-router-dom').RouteComponentProps<{ username: string }>} props
 * @example
 * <Profile />
 */
function Profile() {
  const currentUser = useSelector(selectUser);
  const profile = useSelector((state) => state.profile);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProfile = dispatch(getProfile(id));
    return () => {
      fetchProfile.abort();
    };
  }, []);

  useEffect(() => () => dispatch(profilePageUnloaded()), []);

  if (!profile) {
    return null;
  }

  return (
    <div className="profile-page">
      <UserInfo profile={profile} currentUser={currentUser} />
      <div className="container page">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 flex-wrap">
            <div className="card border-0">
              <div className="card-body bg-light">
                <div className="d-flex justify-content-start align-items-center bg-white px-3 pb-2 pt-3">
                  <Icon style={{ fontSize: "25px", color: "#6C757D", marginTop: "9px" }}>email</Icon>
                  <p
                    style={{
                      color: "#6C757D",
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                    className="m-0 ms-3 mt-2"
                  >
                    Email:
                  </p>
                  <p
                    style={{
                      color: "#6C757D",
                      fontSize: "17px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    className=" m-0 ms-2 mt-2"
                  >
                    {profile?.email}
                  </p>
                </div>
                <div className="col d-flex justify-content-start bg-white px-3 py-2">
                  <Icon style={{ fontSize: "25px", color: "#6C757D", marginTop: "9px" }}>folder</Icon>
                  <p style={{ color: "#6C757D", fontWeight: "bold", fontSize: "17px" }} className="m-0 ms-3 mt-2">
                    Projects:
                  </p>
                  <div className="d-flex flex-wrap">
                    {profile.projects?.map((project) => (
                      <p
                        style={{
                          color: "#6C757D",
                          fontSize: "17px",
                          margin: 0,
                        }}
                        className="ms-2 mt-2"
                        key={project.id}
                      >
                        {project.title}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center bg-white px-3 py-2 ">
                  <Icon style={{ fontSize: "27px", color: "#6C757D", marginTop: "5px" }}>person</Icon>
                  <p
                    style={{
                      color: "#6C757D",
                      fontWeight: "bold",
                      fontSize: "17px",
                      marginLeft: "14px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Role:
                  </p>
                  <p
                    style={{
                      color: "#6C757D",
                      fontSize: "17px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    className="m-0 ms-2 mt-1"
                  >
                    {profile?.role?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>{" "}
          <TimeSheet projects={profile?.projects} />
        </div>
      </div>{" "}
    </div>
  );
}

export default memo(Profile);

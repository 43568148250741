import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@mui/material";
import { selectUser } from "./authSlice";
import { connectProject } from "../../reducers/trace";
import { createGitAccess, getGitAccess } from "../../reducers/gitAccess";
import CreateButton from "../../utils/createButton";
import { formatDateGitAccess, generateKey } from "../../common/utils";
import "../../components/style.css";
import "./settings.css";

function SettingsScreen() {
  const [userToken, setUserToken] = useState("");
  const [gitType, setGitType] = useState();
  const [projectId, setProjectId] = useState("");
  const [customApi, setCustomApi] = useState("");
  const currentUser = useSelector(selectUser);
  const { gitAccess } = useSelector((state) => state.gitAccess);
  const [activeCurrentIndex, setActiveCurrentIndex] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) dispatch(getGitAccess(currentUser?.id));
  }, [currentUser]);

  const changeToken = (event) => {
    event.preventDefault();
    setUserToken(event.target.value);
  };

  const changeGitType = (event) => {
    event.preventDefault();
    setGitType(event.target.value);
  };

  const changeProjectId = (event) => {
    event.preventDefault();
    setProjectId(event.target.value);
  };
  const changeCustomApi = (event) => {
    event.preventDefault();
    setCustomApi(event.target.value);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    const body = {
      userId: currentUser?.id,
      token: userToken,
      type: gitType,
      customApi,
    };
    await dispatch(createGitAccess(body)).then((response) =>
      dispatch(connectProject({ gitAccessId: response.payload.id, projectId: +projectId }))
    );
    dispatch(getGitAccess(currentUser?.id));

    setUserToken("");
    setGitType("");
    setProjectId("");
    setCustomApi("");
  };

  const toggleShowAccordion = (id) => {
    if (activeCurrentIndex === id) {
      setActiveCurrentIndex();
    } else {
      setActiveCurrentIndex(id);
    }
  };

  return (
    <div className="settings-page">
      <div className="container">
        <div className="d-flex mt-4">
          <button
            className="btn blue_button mt-sm-3 mt-md-3 mt-3 mt-lg-0"
            type="submit"
            data-bs-toggle="modal"
            data-bs-target="#gitAccessModal"
          >
            Set git access
          </button>
          <div className="modal" id="gitAccessModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Set git access</h4>
                </div>
                <div className="mb-3 modal-body">
                  <label htmlFor="token-text" className="col-form-label w-100">
                    Enter user token:
                    <input
                      className="form-control"
                      id="token-text"
                      value={userToken}
                      onChange={changeToken}
                      placeholder="User token"
                    />
                  </label>

                  <label className="col-form-label w-100" htmlFor="role">
                    Enter git type:
                    <select
                      id="role"
                      className="form-select"
                      onChange={(e) => {
                        changeGitType(e);
                      }}
                      value={gitType}
                    >
                      <option type="others">Select role</option>
                      <option type="gitlab">gitlab</option>
                      <option type="github">github</option>
                    </select>
                  </label>
                  <label htmlFor="project-id" className="col-form-label w-100">
                    Enter project Id:
                    <input
                      className="form-control"
                      id="project-id"
                      value={projectId}
                      onChange={changeProjectId}
                      placeholder="Project id"
                    />
                  </label>
                  <label htmlFor="custom-api" className="col-form-label w-100">
                    Enter custom api:
                    <input
                      className="form-control"
                      id="custom-api"
                      value={customApi}
                      onChange={changeCustomApi}
                      placeholder="Custom api"
                    />
                  </label>
                </div>

                <div className="modal-footer d-flex">
                  <CreateButton
                    onSubmit={submitForm}
                    disabled={
                      userToken?.replace(/\s/g, "").length === 0 ||
                      gitType?.replace(/\s/g, "").length === undefined ||
                      projectId?.replace(/\s/g, "").length === 0
                    }
                    modal
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 flex-wrap">
          <div className="accordion" id="accordionExample" style={{ width: "300px" }}>
            {gitAccess?.map(
              (git) =>
                git?.gitUsername !== null && (
                  <div className="accordion-item" key={generateKey()}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed px-3"
                        type="button"
                        onClick={() => toggleShowAccordion(git?.id)}
                      >
                        <div className="d-flex align-items-center">
                          <Icon style={{ fontSize: "20px" }} className="">
                            {activeCurrentIndex === git?.id ? "expand_less" : "expand_more"}
                          </Icon>
                          <div className="d-flex align-items-start pe-2 ps-1" style={{ width: "155px" }}>
                            {" "}
                            {git?.gitUsername}
                          </div>
                          <div className="d-flex align-items-end ps-2 pe-0">
                            <p className="mb-0">{formatDateGitAccess(new Date(git?.createdAt))}</p>
                          </div>
                        </div>
                      </button>{" "}
                    </h2>
                    {activeCurrentIndex === git?.id && (
                      <div className="accordion-body pt-0 px-3">
                        It is shown by default, until the collapse plugin adds the appropriate classes that we use to
                        style each element.
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(SettingsScreen);

import agent from "../agent";
import { login, logout, register } from "../features/auth/authSlice";

const localStorageMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case register.fulfilled.type:
    case login.fulfilled.type:
      window.localStorage.setItem("jwt", action.payload.token);
      agent.setToken(action.payload.token);
      break;

    case logout.type:
      window.localStorage.removeItem("jwt");
      agent.setToken(undefined);
      break;
    default:
      break;
  }

  return next(action);
};

export default localStorageMiddleware;

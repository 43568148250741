import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import useTimeout from "../../hooks/useTimeout";
import { resetPassword, logout } from "./authSlice";

function ResetPassword() {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isShownNewPassword, setIsShownNewPassword] = useState(false);
  const [isShownConfirmedPassword, setIsShownConfirmedPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [sentSuccessfuly, setSentSuccessfuly] = useState(false);
  const [inputName, setInputName] = useState();
  const comparePaswords = newPassword !== confirmedPassword;
  const [timeout] = useTimeout(() => {
    setErrorMessage("");
  }, 2000);
  const validation = (event) => {
    const message = event.target.value.replace(/\s/g, "");

    const lowerCase = /[a-z]/g;
    const upperCase = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCase = /.*[-' /`~!#$@_%+= ., ^&() { } [\] ;:"<>?\\]/;

    if (!message.match(lowerCase)) {
      setErrorMessage("Password should contains lowercase letters!");
    } else if (!message.match(upperCase)) {
      setErrorMessage("Password should contain uppercase letters!");
    } else if (!message.match(numbers)) {
      setErrorMessage("Password should contains numbers also!");
    } else if (!message.match(specialCase)) {
      setErrorMessage("Password should contains special caracters also!");
    } else if (message.length < 8) {
      setErrorMessage("Password length should be more than 8.");
    } else if (message.length === 30) {
      setErrorMessage("The length of the password cannot be more than 30 characters");

      timeout();
    } else {
      setErrorMessage("");
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    const email = search.slice(search.indexOf("=") + 1);

    if (!comparePaswords) {
      dispatch(resetPassword({ email, password: newPassword })).then((response) =>
        setSentSuccessfuly(response?.type.includes("fulfilled"))
      );
    }
  };

  useEffect(() => {
    if (sentSuccessfuly === true) {
      dispatch(logout());
    }
  }, [sentSuccessfuly]);

  return (
    <div className="auth-page">
      <div className="container page">
        <div className="row d-flex align-items-center justify-content-center px-4 px-lg-0 px-md-0">
          {!sentSuccessfuly ? (
            <div className="auth_box">
              <form onSubmit={submitForm} className="form">
                <p style={{ fontSize: "40px", fontWeight: "600" }} className="text-xs-center m-0 font">
                  Reset password
                </p>
                <p className="text-xs-center font">Enter your new password</p>
                <fieldset className="form-group">
                  {" "}
                  <div className="input-group align-middle w-auto">
                    <input
                      className="form-control form-control-lg border-end-0"
                      type={isShownNewPassword ? "text" : "password"}
                      autoComplete="new-password"
                      placeholder="New password"
                      name="new-password"
                      value={newPassword}
                      maxLength={30}
                      onChange={(e) => {
                        setInputName(e.target.name);
                        setNewPassword(e.target.value.replace(/\s/g, ""));
                        validation(e);
                      }}
                    />{" "}
                    <span className="input-group-text bg-white border-start" id="basic-addon1">
                      <button
                        type="button"
                        onClick={() => setIsShownNewPassword(!isShownNewPassword)}
                        className="text-center me-2"
                        style={{
                          border: 0,
                          background: "none",
                          padding: 0,
                        }}
                      >
                        {isShownNewPassword ? (
                          <Icon style={{ fontSize: "20px", color: "#6C757D", marginBottom: "-3px" }}>visibility</Icon>
                        ) : (
                          <Icon style={{ fontSize: "20px", color: "#6C757D", marginBottom: "-3px" }}>
                            visibility_off
                          </Icon>
                        )}
                      </button>
                    </span>
                  </div>{" "}
                  {inputName === "new-password" && <div style={{ color: "red" }}>{errorMessage}</div>}
                </fieldset>
                <fieldset className="form-group">
                  {" "}
                  <div className="input-group align-middle w-auto">
                    <input
                      className="form-control form-control-lg border-end-0 input"
                      type={isShownConfirmedPassword ? "text" : "password"}
                      autoComplete="new-password"
                      placeholder="Confirm password"
                      disabled={inputName !== "confirm-password" && newPassword?.length < 8}
                      name="confirm-password"
                      maxLength={30}
                      value={confirmedPassword}
                      onChange={(e) => {
                        setInputName(e.target.name);
                        setConfirmedPassword(e.target.value.replace(/\s/g, ""));
                        validation(e);
                      }}
                    />{" "}
                    <span className="input-group-text bg-white border-start" id="basic-addon1">
                      <button
                        type="button"
                        onClick={() => setIsShownConfirmedPassword(!isShownConfirmedPassword)}
                        className="text-center me-2"
                        style={{
                          border: 0,
                          background: "none",
                          padding: 0,
                        }}
                      >
                        {isShownConfirmedPassword ? (
                          <Icon style={{ fontSize: "20px", color: "#6C757D", marginBottom: "-3px" }}>visibility</Icon>
                        ) : (
                          <Icon style={{ fontSize: "20px", color: "#6C757D", marginBottom: "-3px" }}>
                            visibility_off
                          </Icon>
                        )}
                      </button>
                    </span>
                  </div>{" "}
                  {inputName === "confirm-password" && (
                    <div style={{ color: "red" }}>
                      {" "}
                      {errorMessage && comparePaswords && confirmedPassword && "The passwords is not the same."}
                    </div>
                  )}
                </fieldset>
                <div>
                  <button
                    className="btn btn-lg send_button mt-2"
                    type="submit"
                    disabled={newPassword === "" || confirmedPassword === "" || comparePaswords || errorMessage}
                  >
                    Sent
                  </button>
                  <button
                    className="btn btn-lg cancel_button mt-2"
                    type="button"
                    onClick={() => navigate(pathname.includes("edit-profile") ? -1 : "/login")}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="auth_box" style={{ minHeight: "483px" }}>
              <form className="form">
                <fieldset className="form-group">
                  <div className="d-flex justify-content-center">
                    <Icon className="d-flex justify-content-center" style={{ fontSize: "105px", color: "#13B7FF" }}>
                      check_circle_outline
                    </Icon>
                  </div>
                  <p style={{ fontSize: "40px", fontWeight: "600" }} className="m-0 text-xs-center font">
                    Password Changed!
                  </p>
                  <p style={{ marginBottom: "70px" }} className="text-xs-center  font">
                    Your password has been changed successfully.
                  </p>
                </fieldset>{" "}
                <Link to="/login">
                  <button className="btn btn-lg send_button" type="submit">
                    Log in
                  </button>
                </Link>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../agent";
import { profilePageUnloaded } from "./profile";
import { serializeError } from "../common/utils";

export const getAllCurrencies = createAsyncThunk("currencies/getAll", agent.Projects.allCurrencies, { serializeError });

const initialState = {
  currencies: [],
};

const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (currenciesState, action) => {
      const state = currenciesState;
      state.tab = action.payload;
      delete state.tag;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCurrencies.fulfilled, (currenciesState, action) => {
      const state = currenciesState;
      state.currencies = action.payload.currencies;
    });

    builder.addMatcher(
      (action) => [profilePageUnloaded.type].includes(action.type),
      () => initialState
    );
  },
});

export const changeTab = (tab) => (dispatch) => {
  dispatch(currenciesSlice.actions.changeTab(tab));
  return dispatch(getAllCurrencies());
};

export const { homePageUnloaded } = currenciesSlice.actions;

export default currenciesSlice.reducer;

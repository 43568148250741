import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../agent";
import { serializeError } from "../common/utils";
import { profilePageUnloaded } from "./profile";

export const getProjectsByAuthor = createAsyncThunk(
  "projectList/getProjectsByAuthor",
  ({ author, page } = {}) => agent.Projects.byAuthor(author, page),
  { serializeError }
);

export const getAllProjects = createAsyncThunk("projectList/getAll", agent.Projects.all, { serializeError });

const initialState = {
  projects: [],
  projectsCount: 0,
  currentPage: 0,
  projectsPerPage: 10,
  tab: undefined,
  author: undefined,
};

const projectListSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeProjectTab: (tabState, action) => {
      const state = tabState;
      state.tab = action.payload;
      delete state.tag;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProjects.fulfilled, (projectsState, action) => {
      const state = projectsState;
      state.projects = action.payload.projects;
      state.projectsCount = action.payload.projectsCount;
      state.currentPage = action.meta.arg?.page ?? 0;
    });

    builder.addCase(getProjectsByAuthor.fulfilled, (_, action) => ({
      projects: action.payload.projects,
      projectsCount: action.payload.projectsCount,
      currentPage: action.meta.arg?.page ?? 0,
      author: action.meta.arg?.author,
      projectsPerPage: 5,
    }));

    builder.addMatcher(
      (action) => [profilePageUnloaded.type].includes(action.type),
      () => initialState
    );
  },
});

export const changeProjectTab = (tab) => (dispatch) => {
  dispatch(projectListSlice.actions.changeProjectTab(tab));
  return dispatch(getAllProjects({}));
};

export const { homePageUnloaded } = projectListSlice.actions;

export default projectListSlice.reducer;

import { Editor } from "@tinymce/tinymce-react";
import React, { memo, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Icon from "@mui/material/Icon";
import Toaster from "../../common/Toaster";
import { formatDateNoTime, generateKey } from "../../common/utils";
import Members from "../../common/Members";
import { selectUser } from "../../features/auth/authSlice";
import { deleteUpload, clearRedirect } from "../../reducers/common";
import { getAllCurrencies } from "../../reducers/currencies";
import {
  assignUser,
  createProject,
  getProject,
  projectPageUnloaded,
  unAssignUser,
  updateProject,
  uploadFile,
} from "../../reducers/project";
import { getAllUsers } from "../../reducers/users";
import "../style.css";
import CreateButton from "../../utils/createButton";

function CreateProject() {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.project);
  const currentUser = useSelector(selectUser);
  const { slug } = useParams();
  const { deleteUploadRes } = useSelector((state) => state.common);
  const [disabledButton, setDisabledButton] = useState(true);
  const [image, setImage] = useState("");
  const [users, setUsers] = useState();
  const [toasterMessage, setToasterMessage] = useState(false);
  const [toasterMessageSucces, setToasterMessSucces] = useState(false);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [currency, setCurrency] = useState({});
  const [currentProject, setCurrentProject] = useState({
    startDate: "",
    endDate: "",
    description: "",
  });

  const editorRef = useRef(null);

  useEffect(() => {
    if (project) setCurrency(project?.currency?.id);
    else setCurrency(2);
  }, [project]);

  useEffect(() => {
    const getCurrencies = async () => {
      const result = await dispatch(getAllCurrencies());
      setAllCurrencies(result.payload.currencies);
    };
    getCurrencies();
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      const result = await dispatch(getAllUsers());
      setUsers(result.payload.users);
    };
    getUsers();
  }, []);

  const onChange = (e) => {
    setDisabledButton(false);
    const payload = {
      userId: e.target.value,
      projectId: project.id,
    };
    dispatch(assignUser(payload));
    const member = users.find((user) => user.id === +e.target.value);
    setCurrentProject({ ...currentProject, members: [...currentProject.members, member] });
  };

  const onDelete = (id) => {
    setDisabledButton(false);
    const payload = {
      userId: id,
      projectId: project.id,
    };
    dispatch(unAssignUser(payload));
    const updatedMember = currentProject?.members.filter((member) => member.id !== +id);
    setCurrentProject({ ...currentProject, members: updatedMember });
  };

  useEffect(() => {
    const isImageCover = project?.Attachments.filter((attachment) => attachment.isCover === true);
    setCurrentProject({ ...currentProject, Attachments: isImageCover });
  }, [project]);

  const fileRemove = (file) => {
    setDisabledButton(false);
    dispatch(deleteUpload(file.id));
    const updatedImages = currentProject?.Attachments.filter((fileId) => fileId.id !== file.id);
    setCurrentProject({ ...currentProject, Attachments: updatedImages });
  };
  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeBudget = (e) => {
    if (project) setDisabledButton(false);
    setCurrentProject({ ...currentProject, budget: e.target.value });
  };
  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */

  const changeTitle = (event) => {
    const title = event.target.value;
    if (project ? project?.title?.trim() !== title?.trim() : title.replace(/\s/g, "").length !== 0)
      setDisabledButton(false);
    else setDisabledButton(true);

    setCurrentProject({ ...currentProject, title: title?.trimStart() });
  };

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeDescription = (content) => {
    if (currentProject.description !== content) {
      setDisabledButton(false);
    }
    const regex = /(<([^>]+)>)/gi;
    const trimedContent = content.replace(regex, " ").trim();
    const limit = 501;

    if (trimedContent.length >= limit || trimedContent.length === 499) {
      setToasterMessage("The field must contain no more than 500 characters.");
    }
    if (trimedContent.length < limit) {
      setCurrentProject({ ...currentProject, description: content });
    }
    if (trimedContent.length < limit - 3) {
      setToasterMessage(false);
    }
  };

  const changeCurrency = (e) => {
    if (project) setDisabledButton(false);
    setCurrency(+e.target.value);
  };

  /**
   * Reset the form values
   */
  const defaultProjectValues = {
    title: "",
    description: "",
    budget: "",
    members: [],
    startDate: "",
    endDate: "",
    notify: true,
    status: "Planned",
    Attachments: [],
  };

  const reset = () => {
    if (project) {
      const attachments = project?.Attachments.filter((attachment) => attachment.isCover === true);
      setCurrentProject({
        ...project,
        Attachments: attachments,
        startDate: formatDateNoTime(new Date(project?.startDate)),
        endDate: formatDateNoTime(new Date(project?.endDate)),
        currencyId: currency,
      });
    } else {
      setCurrentProject(defaultProjectValues);
    }
  };

  /**
   * @type {React.MouseEventHandler<HTMLButtonElement>}
   */

  const submitForm = async (event) => {
    event.preventDefault();
    const projectBody = {
      ...currentProject,
      slug,
      title: currentProject?.title?.trim(),
      manager: currentUser.id,
      currencyId: currency,
    };

    const result = await dispatch(slug ? updateProject(projectBody) : createProject(projectBody));

    if (result.meta.requestStatus === "fulfilled") {
      setToasterMessSucces(true);
    }
    if (slug) {
      if (image) dispatch(getProject(slug));
    } else setCurrentProject(defaultProjectValues);
  };

  useEffect(() => {
    reset();
    if (slug) {
      dispatch(getProject(slug));
    }
  }, [slug]);

  useEffect(reset, [project]);
  useEffect(() => () => dispatch(projectPageUnloaded()), []);

  async function dragNdrop(event) {
    const filename = URL.createObjectURL(event.target.files[0]);
    const file = document.getElementById("uploadFile")?.files[0];
    setImage(file); // will store file name with src
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    formData.append("isCover", true);
    const result = await dispatch(uploadFile({ file: formData, projectId: project.id }));
    setDisabledButton(false);
    dispatch(getProject(slug));
    if (result.meta.requestStatus === "fulfilled" && currentProject?.Attachments.length >= 1) {
      dispatch(deleteUpload(currentProject?.Attachments[0].id));
    }

    const preview = document.getElementById("preview");
    const previewImg = document.createElement("img"); // creating new image tag
    previewImg.setAttribute("src", filename); // setting attribute src of newly created "img" element to "filename"
    previewImg.setAttribute("style", "width: 50%", "height: 50%;");
    preview.innerHTML = "";
    preview.appendChild(previewImg); // finally append "img" element to parent element "preview"
  }
  useEffect(() => {
    if (slug) dispatch(getProject(slug));
    dispatch(clearRedirect());
  }, [deleteUploadRes]);

  // 2. drag

  const drag = () => {
    // while dragging it will fire this function that will add the following className
    document.getElementById("uploadFile");
  };

  // 3. finally the drop()
  const drop = () => {
    document.getElementById("uploadFile");
  };

  // we are done...

  const ImageConfig = {
    default:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcTzsiKgJMt1ybLmqDpC2ynJWW-9nkk_chxrjQggfTUlhUeUdA&s",
    jpg: "https://cdn-icons-png.flaticon.com/256/337/337940.png",
    jpeg: "https://cdn-icons-png.flaticon.com/256/337/337940.png",
    png: "https://cdn-icons-png.flaticon.com/256/7271/7271048.png",
  };

  return (
    <div className="editor-page">
      <Toaster
        message={
          project
            ? toasterMessageSucces && "The changes were successful."
            : toasterMessageSucces && "The project has been created."
        }
        type="success"
      />
      <Toaster message={toasterMessage} type="warning" />
      <div className="page">
        <div className="col-md-10 offset-md-1 col-xs-12">
          <form>
            <fieldset>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xs-12">
                    <div>
                      <fieldset className="form-group">
                        <p className="text-muted h5 py-2">Name</p>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Enter project name"
                          value={currentProject?.title || ""}
                          onChange={changeTitle}
                          data-testid="title-input"
                          maxLength={50}
                        />
                      </fieldset>{" "}
                      <fieldset className="form-group">
                        <p className="text-muted h5 py-2">Overview</p>
                        <Editor
                          onInit={(editor) => {
                            editorRef.current = editor;
                          }}
                          initialValue=""
                          value={currentProject?.description || ""}
                          onEditorChange={changeDescription}
                          apiKey="ohu4mzel9wdugza1y231epy17k8iloze7z78ssej5t96b9lt"
                          init={{
                            height: 200,
                            menubar: false,
                            branding: false,
                            elementpath: false,
                            selector: "textarea",
                            plugins: ["emoticons", "lists"],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist checklist outdent indent | " +
                              "removeformat emoticons",
                          }}
                        />
                      </fieldset>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <fieldset className="form-group me-1">
                        <p className="text-muted h5 py-2">Start Date</p>
                        <div className="form-field align-middle pt-1 date-box">
                          <DatePicker
                            className="form-control form-control-lg input-date"
                            onChange={(date) => setCurrentProject({ ...currentProject, startDate: date })}
                            id="startDate"
                            selected={currentProject?.startDate || ""}
                            data-testid="startDate-input"
                            placeholderText="mm/dd/yyyy"
                            minDate={new Date().toISOString().split("T")[0]}
                            maxDate={currentProject?.endDate}
                          />

                          <span
                            style={{
                              position: "absolute",
                              right: "10px",
                              marginTop: "-27px",
                              backgroundColor: "white",
                            }}
                          >
                            <Icon
                              style={
                                currentProject?.startDate
                                  ? { fontSize: "20px", color: "black" }
                                  : { fontSize: "20px", color: "#6C757D" }
                              }
                            >
                              calendar_today
                            </Icon>
                          </span>
                        </div>
                      </fieldset>
                      <fieldset className="form-group">
                        <p className="text-muted h5 py-2">Due Date</p>
                        <div className="form-field align-middle pt-1 date-box">
                          <DatePicker
                            className="form-control form-control-lg input-date"
                            onChange={(date) => setCurrentProject({ ...currentProject, endDate: date })}
                            id="endDate"
                            selected={currentProject?.endDate || ""}
                            data-testid="startDate-input"
                            disabled={currentProject.startDate === ""}
                            placeholderText="mm/dd/yyyy"
                            minDate={
                              currentProject.startDate ? formatDateNoTime(new Date(currentProject?.startDate)) : ""
                            }
                          />
                          {currentProject.startDate && (
                            <span style={{ position: "absolute", right: "10px", marginTop: "-27px" }}>
                              <Icon style={{ fontSize: "20px", color: "black" }}>calendar_today</Icon>
                            </span>
                          )}
                        </div>
                      </fieldset>
                    </div>
                    <p className="text-muted h5 py-2">Budget</p>
                    <div className="input-group d-flex">
                      <div className="d-flex">
                        <select
                          className="form-select input-group-addon bg-light"
                          aria-label="Default select example"
                          onChange={changeCurrency}
                          value={currency || ""}
                          style={{ fontSize: "18px", width: "85px" }}
                        >
                          {allCurrencies.map((opts) => (
                            <option value={opts.id} key={generateKey()}>
                              {opts.code}
                            </option>
                          ))}
                        </select>
                        <input
                          className="form-control bg-white text-end"
                          style={{
                            width: "105px",
                            height: "48px",
                            fontSize: "20px",
                          }}
                          type="number"
                          onChange={changeBudget}
                          value={currentProject?.budget || ""}
                          data-testid="budget-input"
                          min="0"
                          placeholder="0"
                        />
                      </div>
                    </div>
                    {!project ? (
                      <CreateButton
                        disabled={
                          currentProject?.title?.replace(/\s/g, "").length === 0 ||
                          currentProject?.endDate === "" ||
                          currentProject?.endDate === "" ||
                          currentProject?.budget === ""
                        }
                        onSubmit={submitForm}
                      />
                    ) : null}
                    {project?.id ? (
                      <div className="row">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="card-subtitle text-muted mb-1">Team Members:</h5>
                          <select
                            data-testid="members-input"
                            className="form-select my-3"
                            style={{ width: "200px" }}
                            aria-label="Default select example"
                            onChange={onChange}
                          >
                            <option type="others">Select a member</option>
                            {users?.map((user) => (
                              <option
                                disabled={currentProject?.members.some((e) => e.username === user.username)}
                                key={generateKey()}
                                value={user.id}
                                id={user.username}
                              >
                                {user.username}
                              </option>
                            ))}
                          </select>
                        </div>
                        <Members
                          members={currentProject?.members}
                          isProject
                          handleDeleteUser={onDelete}
                          height="mt-3"
                        />
                      </div>
                    ) : null}
                  </div>
                  {project ? (
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xs-12">
                      <div>
                        <fieldset className="form-group">
                          <p className="h5 text-muted">Avatar</p>
                          <p className="text-muted h6 py-2">
                            Recomended thumbnail size 800x400 (px). Format .jpg, .png.
                          </p>
                          <span
                            className="d-flex justify-content-center align-items-center position-relative "
                            style={{
                              border: "2px dashed #ccc",
                              minHeight: "200px",
                            }}
                          >
                            <div>
                              <div id="preview" className="text-center">
                                <Icon
                                  style={{
                                    fontSize: "95px",
                                    color: "#6C757D",
                                  }}
                                  className="ms-3 me-1"
                                >
                                  cloud_upload
                                </Icon>
                                <p className="font-weight-bold text-muted h5">Drop images here or click to upload.</p>
                              </div>
                              <input
                                className="h-100 w-100 position-absolute opacity-0 top-0 left-0"
                                type="file"
                                onChange={(event) => dragNdrop(event)}
                                onDragOver={drag}
                                onDrop={drop}
                                name="upload"
                                id="uploadFile"
                              />{" "}
                            </div>
                          </span>
                        </fieldset>
                        {currentProject?.Attachments?.length > 0 ? (
                          <div className="files-list">
                            {currentProject?.Attachments?.map((file) => (
                              <div
                                className="rounded bg-white border border-light-subtle mt-3 p-2 d-flex align-items-center"
                                key={generateKey()}
                              >
                                <img
                                  src={
                                    ImageConfig[file?.name.split(".")[1]] ||
                                    ImageConfig[file?.name.split(".").slice(-1)[0]] ||
                                    ImageConfig?.default
                                  }
                                  alt=""
                                  width="50"
                                  height="50"
                                  style={{ marginRight: "12px", marginLeft: 0 }}
                                />
                                <p
                                  className="text-break pe-3"
                                  style={{
                                    marginBottom: 0,
                                    flex: 1,
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 2,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {file?.name}
                                </p>
                                <div className="actions pe-1" style={{ justifySelf: "flex-end" }}>
                                  <Icon
                                    onKeyDown={fileRemove}
                                    role="button"
                                    tabIndex="0"
                                    aria-label="Close"
                                    style={{
                                      fontSize: "30px",
                                      cursor: "pointer",
                                      fontWeight: 500,
                                      marginBottom: "-5px",
                                      color: "#6C757D",
                                    }}
                                    onClick={() => fileRemove(file)}
                                  >
                                    close
                                  </Icon>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  <div className="container">
                    {project ? (
                      <CreateButton
                        disabled={
                          disabledButton ||
                          currentProject?.title?.replace(/\s/g, "").length === 0 ||
                          currentProject?.budget === ""
                        }
                        onSubmit={submitForm}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}

export default memo(CreateProject);

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import agent from "../agent";
import { serializeError } from "../common/utils";

export const getArticle = createAsyncThunk("article/getArticle", agent.Articles.get, { serializeError });

export const createArticle = createAsyncThunk("article/createArticle", agent.Articles.create, { serializeError });

export const updateArticle = createAsyncThunk("article/updateArticle", agent.Articles.update, { serializeError });

export const uploadImage = createAsyncThunk("article/uploadImage", agent.Articles.uploadImage, { serializeError });

export const deleteImage = createAsyncThunk("article/deleteImage", agent.Articles.deleteImage, { serializeError });

const initialState = {
  article: undefined,
};

const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    articlePageUnloaded: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getArticle.fulfilled, (articleState, action) => {
      const state = articleState;
      state.article = action.payload.article;
    });

    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      () => {}
    );

    builder.addDefaultCase(() => {});
  },
});

export const { articlePageUnloaded } = articleSlice.actions;

export default articleSlice.reducer;

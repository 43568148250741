import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import React, { memo } from "react";
import { Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { Modal } from "../../common/utils";
import { deleteArticle } from "../../reducers/common";
import "../style.css";

/**
 * Show the actions to edit or delete an article
 *
 * @example
 * <ArticleActions />
 */
function ArticleActions() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * @type {React.MouseEventHandler}
   */
  const handleDeleteItem = () => {
    dispatch(deleteArticle(slug));
    navigate("/news-feed");
  };

  return (
    <span>
      <Link to={`${location.pathname}/edit-article/${slug}`} className="btn gray_button btn-sm">
        Edit Article
      </Link>

      <button
        id="deleteArticle"
        type="button"
        className="btn btn-lg btn-link mod-options ms-2"
        name="delete"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <Icon style={{ fontSize: "30px", marginBottom: "-6px" }} className="delete_icon">
          delete
        </Icon>
        <span className="sr-only">Delete project</span>
        <Modal handleDeleteItem={handleDeleteItem} id="exampleModal" fontSize={15} color="black" />{" "}
      </button>
    </span>
  );
}

export default memo(ArticleActions);

import PropTypes from "prop-types";
import React from "react";
import "../components/style.css";

/**
 * States of the slice
 * @readonly
 * @enum {string}
 */
export const Status = {
  /** The initial state */
  IDLE: "idle",
  /** The loading state */
  LOADING: "loading",
  /** The success state */
  SUCCESS: "success",
  /** The error state */
  FAILURE: "failure",
};

/**
 * Check if error is an ApiError
 *
 * @param {object} error
 * @returns {boolean} error is ApiError
 */
export function isApiError(error) {
  return typeof error === "object" && error !== null && "errors" in error;
}

export function serializeError(error) {
  if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack,
      code: error.code,
    };
  }

  if (typeof error === "object" && error !== null) {
    return error;
  }

  return { message: String(error) };
}

/**
 * Set state as loading
 *
 * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
 */
export function loadingReducer(statusState) {
  const state = statusState;
  state.status = Status.LOADING;
}

/**
 * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{errors: Record<string, string[]}>} action
 */
export function failureReducer(statusState, action) {
  const state = statusState;
  state.status = Status.FAILURE;
  state.errors = action.error.message;
}

/**
 * @param {string} date
 */
export function formatDate(date) {
  const year = date.getFullYear();
  let month = date.toLocaleString("en", { month: "long" }).toString();
  month = month.length > 1 ? month : `0 ${month}`;
  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return `${day} ${month} ${year} ${strTime}`;
}

export function formatDateString(date) {
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  return `${day} ${month}`;
}
/**
 * @param {string} date
 */
export function formatDateNoTime(date) {
  const year = date.getFullYear();
  const month = date.toLocaleString("en-US", { month: "2-digit" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  return `${year}-${month}-${day}`;
}

/**
 * @param {string} date
 */
export function formatDateProjectCard(date) {
  const year = date.getFullYear();
  const month = date.toLocaleString("en", { month: "2-digit" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  return `${day}/${month}/${year} `;
}

export function formatDateGitAccess(date) {
  const year = date.getFullYear();
  const month = date.toLocaleString("en", { month: "2-digit" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  return `${day}.${month}.${year} `;
}

export function formatDateNextTime(date) {
  const year = date.getFullYear();
  const month = date.toLocaleString("en", { month: "long" }).toString();
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  return `${day} ${month} ${year} `;
}

export function formatDateShortTime(date) {
  const year = date.getFullYear();
  const month = date.toLocaleString("en", { month: "short" }).toString();
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  return `${day} ${month} ${year} `;
}

export function formatDeadlineTime(date) {
  const month = date.toLocaleString("en", { month: "short" }).toString();
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  return `${day} ${month} `;
}

export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "year";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "month";
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = "day";
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "hour";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "minute";
          } else {
            interval = seconds;
            intervalType = "second";
          }
        }
      }
    }
  }
  if (interval > 1 || interval === 0) {
    intervalType += "s";
  }
  return `${interval} ${intervalType} ago`;
}

export function getFilteredData(array, filter) {
  return array?.filter((obj) => obj.status === filter);
}

export function generateKey() {
  return window?.crypto?.randomUUID();
}

export function Modal({ handleDeleteItem, id, fontSize, color }) {
  return (
    <div className="modal" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel" style={{ color }}>
              Delete
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body justify-content-start d-flex" style={{ fontSize, color }}>
            Are you sure you want to delete?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn blue_button"
              data-bs-dismiss="modal"
              onClick={handleDeleteItem}
              style={{ minWidth: "72.73px", background: "#13B7FF" }}
            >
              OK
            </button>
            <button type="button" className="btn gray_button" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  fontSize: PropTypes.number,
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
Modal.defaultProps = {
  fontSize: 0,
};

export function LogoutModal({ logoutUser, id, fontSize }) {
  return (
    <div className="modal" id={id} tabIndex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="logoutModalLabel">
              Log out
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body justify-content-start d-flex" style={{ fontSize }}>
            Do you want to log out?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn blue_button"
              data-bs-dismiss="modal"
              onClick={logoutUser}
              style={{ minWidth: "72.73px", background: "#13B7FF" }}
            >
              OK
            </button>
            <button type="button" className="btn gray_button" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

LogoutModal.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  fontSize: PropTypes.number,
  id: PropTypes.string.isRequired,
};
LogoutModal.defaultProps = {
  fontSize: 0,
};

export function Progress({ tasks }) {
  const currentNumberOfTasks = !tasks ? "0" : tasks?.length;
  const doneTasks = getFilteredData(tasks, "DONE")?.length;

  const progress = doneTasks !== 0 ? `${Math.round((doneTasks * "100") / currentNumberOfTasks)}%` : "0%";

  return (
    <div
      className="progress ms-3 mb-4 fw-bold"
      style={{
        width: "240px",
        fontSize: "7.5px",
        height: "10px",
      }}
    >
      <div
        className="progress-bar bg-info"
        role="progressbar"
        style={{ width: progress }}
        aria-label="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="70"
      />
      <div
        style={{ width: "-webkit-fill-available" }}
        className="d-flex justify-content-center text-black position-absolute"
      >
        {progress}
      </div>
    </div>
  );
}

Progress.propTypes = {
  tasks: PropTypes.instanceOf(Array),
};
Progress.defaultProps = {
  tasks: [],
};

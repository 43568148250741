import React, { useState, useEffect, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Editor } from "@tinymce/tinymce-react";
import Icon from "@mui/material/Icon";
import Toaster from "../../common/Toaster";
import {
  getArticle,
  createArticle,
  updateArticle,
  articlePageUnloaded,
  uploadImage,
  deleteImage,
} from "../../reducers/article";
import CreateButton from "../../utils/createButton";

/**
 * Create Article component
 * @param {import('react-router-dom').RouteComponentProps<{ slug?: string }>} props


 * <CreateArticle />
 */
function CreateArticle() {
  const dispatch = useDispatch();
  const { article } = useSelector((state) => state.article);
  const { slug } = useParams();
  const [image, setImage] = useState("");
  const [isSelectedImage, setIsSelectedImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [currentArticle, setCurrentArticle] = useState({});
  const [disabledButton, setDisabledButton] = useState(true);
  const editorRef = useRef(null);
  const [toasterMessage, setToasterMessage] = useState();
  const [previewImage, setPreviewImage] = useState(null);
  const [deleteAvatar, setDeleteAvatar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [responseMessage, setResponeMessage] = useState();

  useEffect(() => {
    setImage(article?.image_url);
  }, [article?.image_url]);

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeTitle = (event) => {
    const title = event.target.value;
    if (article ? article?.title?.trim() !== title?.trim() : title.replace(/\s/g, "").length !== 0)
      setDisabledButton(false);
    else setDisabledButton(true);

    setCurrentArticle({ ...currentArticle, title: title?.trimStart() });
  };

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeDescription = (event) => {
    const description = event.target.value;
    if (article ? article?.description?.trim() !== description?.trim() : description.replace(/\s/g, "").length !== 0)
      setDisabledButton(false);
    else setDisabledButton(true);
    setCurrentArticle({ ...currentArticle, description: description?.trimStart() });
  };

  /**
   * @type {React.ChangeEventHandler<HTMLAreaElement>}
   */
  const changeBody = (content) => {
    if (currentArticle.body !== content) {
      setDisabledButton(false);
    }
    const regex = /(<([^>]+)>)/gi;
    const trimedContent = content.replace(regex, " ").trim();
    const limit = 5001;

    if (trimedContent.length >= limit || trimedContent.length === 4999) {
      setToasterMessage("The field must contain no more than 500 characters.");
    }
    if (trimedContent.length < limit) {
      setCurrentArticle({ ...currentArticle, body: content });
    }
    if (trimedContent.length < limit - 3) {
      setToasterMessage(false);
    }
  };

  /**
   * Reset the form values
   */
  const defaultArticleValues = {
    title: "",
    description: "",
    body: "",
  };

  const reset = () => {
    if (slug && article) {
      setCurrentArticle({ ...article });
    } else {
      setCurrentArticle(defaultArticleValues);
    }
  };

  const fileRemove = async () => {
    setDeleteAvatar(true);
    const preview = document.getElementById("uploadImage");
    preview?.removeChild(previewImage);
    setPreviewImage(null);
    setIsSelectedImage(false);
    setImageData(null);
    setDisabledButton(false);
  };

  const submitForm = async (event) => {
    setSuccessMessage("");
    event.preventDefault();
    const isArticle = {
      ...currentArticle,
      image_url: deleteAvatar ? null : currentArticle?.image_url,
      title: currentArticle?.title?.trim(),
      description: currentArticle?.description?.trim(),
      slug,
    };
    await dispatch(slug ? updateArticle(isArticle) : createArticle(isArticle)).then((response) => {
      setResponeMessage(response);

      if (imageData) dispatch(uploadImage({ file: imageData, articleId: response?.payload?.article?.id }));
    });

    if (deleteAvatar)
      dispatch(deleteImage({ file: imageData, articleId: article.id })).then((response) => {
        setResponeMessage(response);
        setDeleteAvatar(false);
        setCurrentArticle({ ...currentArticle, image_url: null });
      });

    if (!slug) setCurrentArticle(defaultArticleValues);

    if (!slug) {
      const preview = document.getElementById("uploadImage");
      preview.removeChild(previewImage);
      setPreviewImage(null);
      setIsSelectedImage(false);
      setImage("");
    }
  };

  useEffect(() => {
    if (responseMessage?.type.includes("fulfilled")) {
      if (!slug) {
        setSuccessMessage("Article has been created");
      } else setSuccessMessage("Article has been successfully updated");
    }
  }, [responseMessage]);

  useEffect(() => {
    reset();
    if (slug) {
      dispatch(getArticle(slug));
    }
  }, [slug]);

  useEffect(reset, [article]);

  useEffect(() => () => dispatch(articlePageUnloaded()), []);

  function dragNdrop(event) {
    const filename = URL.createObjectURL(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    setImageData(formData);
    setIsSelectedImage(true);
    const preview = document.getElementById("uploadImage"); // the div where we will show image
    const previewImg = document.createElement("img"); // creating new image tag
    previewImg.setAttribute("src", filename); // setting attribute src of newly created "img" element to "filename"
    previewImg.setAttribute("height", 200);
    previewImg.setAttribute("width", 400);
    preview.innerHTML = "";
    preview.appendChild(previewImg); // finally append "img" element to parent element "preview"
    setPreviewImage(previewImg);

    setDisabledButton(false);
  }

  // 2. drag

  const drag = () => {
    // while dragging it will fire this function that will add the following className
    document.getElementById("uploadImage");
  };

  // 3. finally the drop()
  const drop = () => {
    document.getElementById("uploadImage");
  };

  // we are done...

  return (
    <div className="editor-page">
      <Toaster message={toasterMessage} type="warning" />
      <Toaster message={successMessage} type="success" />
      <div className="page">
        <div className="col-md-10 offset-md-1 col-xs-12">
          <form>
            <fieldset>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xs-12">
                    <div>
                      <fieldset className="form-group">
                        <p className="text-muted h5 pb-2">Title</p>
                        <input
                          data-testid="title-input"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Article Title"
                          value={currentArticle?.title || ""}
                          onChange={changeTitle}
                          maxLength={50}
                        />
                      </fieldset>
                      <fieldset className="form-group">
                        <p className="text-muted h5 pb-2">Tags</p>
                        <input
                          data-testid="description-input"
                          className="form-control"
                          type="text"
                          placeholder="What's this article about?"
                          value={currentArticle?.description || ""}
                          onChange={changeDescription}
                        />
                      </fieldset>
                      <fieldset className="form-group">
                        <p className="text-muted h5 py-2">Overview</p>
                        <Editor
                          onInit={(editor) => {
                            editorRef.current = editor;
                          }}
                          initialValue=""
                          value={currentArticle?.body || ""}
                          onEditorChange={changeBody}
                          data-testid="body-input"
                          apiKey="ohu4mzel9wdugza1y231epy17k8iloze7z78ssej5t96b9lt"
                          init={{
                            height: 200,
                            menubar: false,
                            branding: false,
                            elementpath: false,
                            selector: "textarea",
                            plugins: ["emoticons", "lists"],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist checklist outdent indent | " +
                              "removeformat emoticons",
                          }}
                        />
                      </fieldset>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xs-12">
                    <div>
                      <fieldset className="form-group">
                        <p className="h5 text-muted">Avatar</p>
                        <p className="text-muted h6 pb-2">Recomended thumbnail size 200x400 (px). Format .jpg, .png.</p>
                        <div
                          className="image d-flex align-items-center position-relative"
                          style={{ height: "200px", margin: 0 }}
                        >
                          {currentArticle?.image_url && !deleteAvatar ? (
                            <>
                              <img src={image} alt="" className="position-absolute" height="200" width="400" />
                              <div
                                className="position-absolute px-2 "
                                style={{
                                  zIndex: 2,
                                  marginLeft: "400px",
                                  marginTop: "-155px",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{ backgroundColor: "transparent", border: 0 }}
                                  onClick={fileRemove}
                                >
                                  <Icon style={{ fontSize: "50px" }} className="delete_icon">
                                    delete
                                  </Icon>
                                </button>
                              </div>
                            </>
                          ) : (
                            <div>
                              <span
                                className="d-flex justify-content-center align-items-center position-relative"
                                style={
                                  isSelectedImage
                                    ? {
                                        height: "200px",
                                        width: "400px",
                                      }
                                    : {
                                        border: "2px dashed #ccc",
                                        height: "200px",
                                        width: "400px",
                                      }
                                }
                              >
                                <div className="text-center text-wrap w-75">
                                  <Icon
                                    style={{
                                      fontSize: "62px",
                                      color: "#6C757D",
                                      zIndex: 1,
                                    }}
                                  >
                                    cloud_upload
                                  </Icon>
                                  <p className="font-weight-bold text-muted h5">Drop avatar here or click to upload.</p>
                                </div>
                                <div id="uploadImage" style={{ position: "absolute" }} />
                                <input
                                  className="h-100 w-100 position-absolute opacity-0 top-0 left-0"
                                  type="file"
                                  onChange={(event) => dragNdrop(event)}
                                  onDragOver={drag}
                                  onDrop={drop}
                                  name={article?.name}
                                  id="uploadImage"
                                />
                                {isSelectedImage && (
                                  <div
                                    className="position-absolute px-2 "
                                    style={{
                                      zIndex: 2,
                                      marginLeft: "470px",
                                      marginTop: "-155px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{ backgroundColor: "transparent", border: 0 }}
                                      onClick={fileRemove}
                                    >
                                      <Icon style={{ fontSize: "50px" }} className="delete_icon">
                                        delete
                                      </Icon>
                                    </button>
                                  </div>
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <CreateButton
                  disabled={
                    article
                      ? disabledButton ||
                        currentArticle?.title?.replace(/\s/g, "").length === 0 ||
                        currentArticle?.description?.replace(/\s/g, "").length === 0 ||
                        currentArticle?.body?.length === 0
                      : currentArticle?.title?.replace(/\s/g, "").length === 0 ||
                        currentArticle?.description?.replace(/\s/g, "").length === 0 ||
                        currentArticle?.body?.length === 0
                  }
                  onSubmit={submitForm}
                />
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}

export default memo(CreateArticle);

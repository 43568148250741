import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import agent from "../agent";
import { profilePageUnloaded } from "./profile";
import { serializeError } from "../common/utils";

export const getArticlesByAuthor = createAsyncThunk(
  "articleList/getArticlesByAuthor",
  ({ author } = {}) => agent.Articles.byAuthor(author),
  { serializeError }
);

export const getAllArticles = createAsyncThunk("articleList/getAll", agent.Articles.all, { serializeError });

export const getArticlesByTag = createAsyncThunk(
  "articleList/getArticlesByTag",
  ({ tag, page } = {}) => agent.Articles.byTag(tag, page),
  { serializeError }
);

export const getFavoriteArticles = createAsyncThunk(
  "articleList/getFavoriteArticles",
  ({ username, page } = {}) => agent.Articles.favoritedBy(username, page),
  { serializeError }
);

export const favoriteArticle = createAsyncThunk("articleList/favoriteArticle", agent.Articles.favorite, {
  serializeError,
});

export const unfavoriteArticle = createAsyncThunk("articleList/unfavoriteArticle", agent.Articles.unfavorite, {
  serializeError,
});

const initialState = {
  articles: [],
  articlesCount: 0,
  currentPage: 0,
  articlesPerPage: 10,
  tab: undefined,
  tag: undefined,
  author: undefined,
  favorited: undefined,
};

const articleListSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeArticleTab: (articleState, action) => {
      const state = articleState;
      state.tab = action.payload;
      delete state.tag;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(favoriteArticle.fulfilled, (articleState, action) => {
      const state = articleState;
      state.articles = state.articles?.map((article) =>
        article.slug === action.payload.article.slug
          ? {
              ...article,
              favorited: action.payload.article.favorited,
              favoritesCount: action.payload.article.favoritesCount,
            }
          : article
      );
    });

    builder.addCase(unfavoriteArticle.fulfilled, (articleState, action) => {
      const state = articleState;
      state.articles = state.articles?.map((article) =>
        article.slug === action.payload.article.slug
          ? {
              ...article,
              favorited: action.payload.article.favorited,
              favoritesCount: action.payload.article.favoritesCount,
            }
          : article
      );
    });

    builder.addCase(getAllArticles.fulfilled, (articlesState, action) => {
      const state = articlesState;
      state.articles = action.payload.article;
      state.articlesCount = action.payload.articlesCount;
      state.currentPage = action.meta.arg?.page ?? 0;
    });

    builder.addCase(getArticlesByTag.fulfilled, (action) => ({
      articles: action.payload.articles,
      articlesCount: action.payload.articlesCount,
      currentPage: action.meta.arg?.page ?? 0,
      tag: action.meta.arg?.tag,
      articlesPerPage: 10,
    }));

    builder.addCase(getArticlesByAuthor.fulfilled, (_, action) => ({
      authorArticles: action.payload.article,
      articlesCount: action.payload.articlesCount,
      currentPage: action.meta.arg?.page ?? 0,
      author: action.meta.arg?.author,
      articlesPerPage: 5,
    }));

    builder.addCase(getFavoriteArticles.fulfilled, (_, action) => ({
      favoriteArticles: action.payload.user.favorites,
      articlesCount: action.payload.articlesCount,
      currentPage: action.meta.arg?.page ?? 0,
      favorited: action.meta.arg?.username,
      articlesPerPage: 5,
    }));

    builder.addMatcher(
      (action) => [profilePageUnloaded.type].includes(action.type),
      () => initialState
    );
  },
});

export const changeArticleTab = (tab) => (dispatch) => {
  dispatch(articleListSlice.actions.changeArticleTab(tab));
  return dispatch(getAllArticles());
};

export const { homePageUnloaded } = articleListSlice.actions;

export default articleListSlice.reducer;

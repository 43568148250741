import React, { lazy, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import snarkdown from "snarkdown";
import xss from "xss";
import useWindowSize from "../../hooks/useWindowSize";
import { articlePageUnloaded, getArticle } from "../../reducers/article";
import ArticleMeta from "./ArticleMeta";

const CommentSection = lazy(() =>
  import(/* webpackChunkName: "CommentSection", webpackPrefetch: true  */ "../../features/comments/CommentSection")
);

/**
 * Show one article with its comments
 *
 * @param {import('react-router-dom').RouteComponentProps<{ slug: string }>} props
 * @example
 * <Article />
 */
function Article() {
  const dispatch = useDispatch();
  const article = useSelector((state) => state.article?.article);
  const { slug } = useParams();
  const { width } = useWindowSize();
  const renderMarkdown = () => ({ __html: xss(snarkdown(article?.body)) });

  useEffect(() => {
    dispatch(getArticle(slug));
  }, []);

  useEffect(() => () => dispatch(articlePageUnloaded()), []);

  return (
    <div className="article-page">
      <div className="container pt-2">
        <div className="article-preview pt-3 pb-0">
          {width >= 768 ? (
            <div className="col d-flex justify-content-start">
              {article?.image_url ? (
                <img
                  className="img center me-3"
                  alt={article?.title}
                  src={article?.image_url}
                  width="250"
                  height="145"
                />
              ) : (
                <span
                  className="d-flex justify-content-center align-items-center position-relative me-3"
                  style={{
                    border: "2px solid #ccc",
                    height: "145px",
                    width: "250px",
                  }}
                >
                  <div className="text-center">
                    <p className="font-weight-bold text-muted h5">No image</p>
                  </div>
                </span>
              )}
              <div className="col pt-2">
                <ArticleMeta />
                <h2 className="pt-1">{article?.title}</h2>
                <h5 className="text-muted">{article?.description}</h5>
              </div>
            </div>
          ) : (
            <div className="col justify-content-center">
              {article?.image_url ? (
                <img className="img center me-3 w-100" alt={article?.title} src={article?.image_url} height="180" />
              ) : (
                <span
                  className="d-flex justify-content-center align-items-center position-relative me-3 w-100"
                  style={{
                    border: "2px solid #ccc",
                    height: "180px",
                  }}
                >
                  <div className="text-center">
                    <p className="font-weight-bold text-muted h5">No image</p>
                  </div>
                </span>
              )}
              <div className="col pt-2">
                <ArticleMeta />
                <h2 className="pt-1">{article?.title}</h2>
                <h5 className="text-muted">{article?.description}</h5>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="container page mt-0">
        <div className="row article-content">
          <div className="col-xs-12 d-lg-flex justify-content-center">
            <div className="image" style={article?.image ? { maxWidth: "1000px" } : { height: "0px" }}>
              {article?.image && <img src={article?.image} alt="" className="rounded h-100 w-100" />}
            </div>
          </div>{" "}
          <div className="mt-4 mb-3" style={{ wordWrap: "break-word" }}>
            {/* eslint-disable-next-line react/no-danger */}
            {article && <article dangerouslySetInnerHTML={renderMarkdown()} />}
          </div>
        </div>

        <hr />

        <CommentSection />
      </div>
    </div>
  );
}

export default memo(Article);

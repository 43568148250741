import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Route from "route-parser";
import useWindowSize from "../hooks/useWindowSize";

const isFunction = (value) => typeof value === "function";

const getPathTokens = (pathname) => {
  const paths = ["/"];

  if (pathname === "/") return paths;

  pathname?.split("/").reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });

  return paths;
};

function getRouteMatch(routes, path) {
  return Object?.keys(routes)
    .map((key) => {
      const params = new Route(key).match(path);
      return {
        didMatch: params !== false,
        params,
        key,
      };
    })
    .filter((item) => item.didMatch)[0];
}

function getBreadcrumbs({ routes, location }) {
  const pathTokens = getPathTokens(location.pathname);
  return pathTokens.map((path) => {
    const routeMatch = getRouteMatch(routes, path);
    const routeValue = routes[routeMatch?.key];
    const name = isFunction(routeValue) ? routeValue(routeMatch.params) : routeValue;
    return { name, path };
  });
}

function Breadcrumbs() {
  const location = useLocation();
  const { width } = useWindowSize();
  const { project } = useSelector((state) => state.project);

  const routes = {
    "/": "Home",
    "/login": "Login",
    "/register": "Register",
    "/login/forgot-password": "Forgot Password",
    "/login/forgot-password/reset-password": "Reset Password",
    "/settings": "Settings",
    "/edit-profile/reset-password": "Reset Password",
    "/organization": "Organization",
    "/organization/edit-organization": "Edit Organization",

    "/news-feed": "News Feed",
    "/news-feed/create-article": "Create Article",
    "/news-feed/article/:slug/edit-article/:slug": "Edit Article",
    "/create-article": "Create Article",
    "/news-feed/article/:slug": "Article",

    "/project-detail/:slug": "Project Details",
    "/project-detail/:slug/edit-project/:slug": "Edit Project",
    "/project-detail/:slug/edit-project/:slug/user/:username": "User's Profile",
    "/create-project": "Create Project",

    "/project-detail/:slug/project-tasks/:slug/task-detail/:slug/edit-task/:slug": "Edit Task",
    "/project-detail/:slug/project-tasks/:slug/task-detail/:slug/create-task": "Create Subtask",
    "/project-detail/:slug/project-tasks/:slug/board/:slug/task-detail/:slug/create-task": "Create Subtask",
    "/project-detail/:slug/project-tasks/:slug/create-task": "Create Task",
    "/project-detail/:slug/project-tasks/:slug/board/:slug/task-detail/:slug": "Task Details",
    "/project-detail/:slug/project-tasks/:slug/board/:slug/create-task": "Create Task",
    "/project-detail/:slug/project-tasks/:slug/board/:slug/task-detail/:slug/edit-task/:slug": "Edit Task",
    "/project-detail/:slug/project-tasks/:slug": "Tasks",
    "/project-detail/:slug/project-tasks/:slug/board/:slug": "Board",
    "/project-detail/:slug/project-tasks/:slug/task-detail/:slug": "Task Details",

    "/project-tasks/:slug/task-detail/:slug/edit-task/:slug": "Edit Task",
    "/project-tasks/:slug/task-detail/:slug/create-task": "Create Subtask",
    "/project-tasks/:slug/board/:slug/task-detail/:slug/create-task": "Create Subtask",
    "/project-tasks/:slug/create-task": "Create Task",
    "/project-tasks/:slug/board/:slug/task-detail/:slug": "Task Details",
    "/project-tasks/:slug/board/:slug/create-task": "Create Task",
    "/project-tasks/:slug/board/:slug/task-detail/:slug/edit-task/:slug": "Edit Task",
    "/project-tasks/:slug": "Tasks",
    "/project-tasks/:slug/board/:slug": "Board",
    "/project-tasks/:slug/task-detail/:slug": "Task Details",

    "/project-tasks/:slug/user/:id/favorites": "Favorites",
    "/project-tasks/:slug/user/:id": "User's Profile",
    "/project-tasks/:slug/user/:id/edit-profile": "Edit Profile",
    "/project-tasks/:slug/user/:id/edit-profile/reset-password": "Reset Password",

    "/project-tasks/:slug/board/:slug/task-detail/:slug/user/:id/favorites": "Favorites",
    "/project-tasks/:slug/board/:slug/task-detail/:slug/user/:id": "User's Profile",
    "/project-tasks/:slug/board/:slug/task-detail/:slug/user/:id/edit-profile": "Edit Profile",
    "/project-tasks/:slug/board/:slug/task-detail/:slug/user/:id/edit-profile/reset-password": "Reset Password",

    "/project-detail/:slug/project-tasks/:slug/user/:id/favorites": "Favorites",
    "/project-detail/:slug/project-tasks/:slug/user/:id": "User's Profile",
    "/project-detail/:slug/project-tasks/:slug/user/:id/edit-profile": "Edit Profile",
    "/project-detail/:slug/project-tasks/:slug/user/:id/edit-profile/reset-password": "Reset Password",

    "/project-detail/:slug/project-tasks/:slug/task-detail/:slug/user/:id/favorites": "Favorites",
    "/project-detail/:slug/project-tasks/:slug/task-detail/:slug/user/:id": "User's Profile",
    "/project-detail/:slug/project-tasks/:slug/task-detail/:slug/user/:id/edit-profile": "Edit Profile",
    "/project-detail/:slug/project-tasks/:slug/task-detail/:slug/user/:id/edit-profile/reset-password":
      "Reset Password",

    "/project-detail/:slug/project-tasks/:slug/board/:slug/task-detail/:slug/user/:id/favorites": "Favorites",
    "/project-detail/:slug/project-tasks/:slug/board/:slug/task-detail/:slug/user/:id": "User's Profile",
    "/project-detail/:slug/project-tasks/:slug/board/:slug/task-detail/:slug/user/:id/edit-profile": "Edit Profile",
    "/project-detail/:slug/project-tasks/:slug/board/:slug/task-detail/:slug/user/:id/edit-profile/reset-password":
      "Reset Password",

    "/project-tasks/:slug/task-detail/:slug/user/:id/favorites": "Favorites",
    "/project-tasks/:slug/task-detail/:slug/user/:id": "User's Profile",
    "/project-tasks/:slug/task-detail/:slug/user/:id/edit-profile": "Edit Profile",
    "/project-tasks/:slug/task-detail/:slug/user/:id/edit-profile/reset-password": "Reset Password",

    "/user/:id/favorites": "Favorites",
    "/user/:id": "User's Profile",
    "/user/:id/edit-profile": "Edit Profile",
    "/user/:id/edit-profile/reset-password": "Reset Password",

    "/organization/user/:id/favorites": "Favorites",
    "/organization/user/:id": "User's Profile",
    "/organization/user/:id/edit-profile": "Edit Profile",
    "/organization/user/:id/edit-profile/reset-password": "Reset Password",

    "/users": "Users",
    "/users/user/:id/favorites": "Favorites",
    "/users/user/:id": "User's Profile",
    "/users/user/:id/edit-profile": "Edit Profile",
    "/users/user/:id/edit-profile/reset-password": "Reset Password",

    "/article/:slug/user/:id/favorites": "Favorites",
    "/article/:slug/user/:id": "User's Profile",
    "/article/:slug/user/:id/edit-profile": "Edit Profile",
    "/article/:slug/user/:id/edit-profile/reset-password": "Reset Password",

    "/project-detail/:slug/user/:id/favorites": "Favorites",
    "/project-detail/:slug/user/:id": "User's Profile",
    "/project-detail/:slug/user/:id/edit-profile": "Edit Profile",
    "/project-detail/:slug/user/:id/edit-profile/reset-password": "Reset Password",
  };
  const breadcrumbs = getBreadcrumbs({ routes, location });

  return (
    <div
      className="container"
      style={
        (location.pathname === "/users" ||
          location.pathname === `/project-tasks/${project?.id}` ||
          location.pathname === `/project-detail/${project?.id}/project-tasks/${project?.id}`) &&
        width <= 760
          ? { marginTop: "100px" }
          : { marginTop: "62px" }
      }
    >
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbs.map((breadcrumb) =>
            breadcrumb.name !== undefined ? (
              <li key={breadcrumb.path} className="breadcrumb-item p-0" style={{ marginTop: "15px" }}>
                <Link
                  to={breadcrumb.path}
                  style={
                    breadcrumb.path === location.pathname
                      ? { fontSize: "17px", textDecoration: "none", color: "black", cursor: "default" }
                      : { fontSize: "17px", textDecoration: "none", color: "blue" }
                  }
                >
                  {breadcrumb.name}
                </Link>
              </li>
            ) : null
          )}
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumbs;

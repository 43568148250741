import Isotope from "isotope-layout";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { formatDeadlineTime, getFilteredData, formatDateNoTime } from "../../common/utils";
import { getProject } from "../../reducers/project";
import { getAllTasks } from "../../reducers/taskList";
import { updateTask, taskPageUnloaded } from "../../reducers/task";
import "./boardScreen.css";

function TaskList({
  children,
  id,
  dataTestId,
  onDragOver,
  onDrop,
  toDoTasks,
  inProgressTasks,
  inTestingTasks,
  doneTasks,
  width,
  onDragEnter,
}) {
  let minHeight = "";
  if (toDoTasks?.length > 0 || inProgressTasks?.length > 0 || inTestingTasks?.length > 0 || doneTasks?.length > 0) {
    minHeight = "470px";
  } else {
    minHeight = "130px";
  }

  return (
    <div
      style={{
        flexBasis: "100%",
        flexGrow: 1,
        minHeight: width >= 576 ? minHeight : "130px",
        padding: "8px 8px 0 8px",
      }}
      onDragOver={(e) => onDragOver(e)}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      id={id}
      className="dropzone droppable"
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
}

TaskList.propTypes = {
  children: PropTypes.instanceOf(Array),
  id: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  toDoTasks: PropTypes.instanceOf(Array),
  inProgressTasks: PropTypes.instanceOf(Array),
  inTestingTasks: PropTypes.instanceOf(Array),
  doneTasks: PropTypes.instanceOf(Array),
  width: PropTypes.number,
};

TaskList.defaultProps = {
  children: [],
  toDoTasks: [],
  inProgressTasks: [],
  inTestingTasks: [],
  doneTasks: [],
  width: 0,
};

function BoardScreen() {
  const { slug } = useParams();
  const { project } = useSelector((state) => state?.project);
  const { taskList } = useSelector((state) => state);
  const [tasks, setTasks] = useState(project?.Tasks);
  const [toDoTasks, setToDoTasks] = useState();
  const [inProgressTasks, setInProgressTasks] = useState();
  const [inTestingTasks, setInTestingTasks] = useState();
  const [doneTasks, setDoneTasks] = useState();
  const [taskId, setTaskId] = useState();
  const [startIndex, setStartIndex] = useState();
  const [response, setResponse] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const boxRef = useRef();
  let status;
  let endIndex;

  useEffect(() => {
    setToDoTasks(getFilteredData(tasks, "TO DO"));
    setInProgressTasks(getFilteredData(tasks, "IN PROGRESS"));
    setInTestingTasks(getFilteredData(tasks, "IN TESTING"));
    setDoneTasks(getFilteredData(tasks, "DONE"));
  }, [tasks]);
  useEffect(() => {
    dispatch(getProject(slug));
  }, []);

  useEffect(() => {
    dispatch(getAllTasks());
  }, [response]);

  useEffect(() => {
    setTasks(taskList?.tasks?.filter((obj) => obj.projectId === +slug));
  }, [taskList]);

  const onDragStart = (e, task) => {
    e.dataTransfer.setData("text", task);
    setTaskId(task);

    if (!Number.isNaN(Number(e.currentTarget.id))) setStartIndex(Number(e.currentTarget.id));
  };

  const onDragEnter = (e) => {
    if (Number.isNaN(Number(e.target.id)) && e.target.id !== "") {
      status = e.target.id;
    }

    if (!Number.isNaN(Number(e.currentTarget.id))) endIndex = Number(e.currentTarget.id);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const reorderTasks = () => {
    let prevTaskId;
    let nextTaskId;
    switch (status) {
      case "TO DO":
        if (startIndex > endIndex) {
          prevTaskId = endIndex === 0 ? null : toDoTasks[endIndex - 1]?.id;
          nextTaskId = endIndex === toDoTasks.length - 1 ? null : toDoTasks[endIndex]?.id;
        } else {
          prevTaskId = endIndex === 0 ? null : toDoTasks[endIndex]?.id;
          nextTaskId = endIndex === toDoTasks.length - 1 ? null : toDoTasks[endIndex + 1]?.id;
        }
        break;
      case "IN PROGRESS":
        if (startIndex > endIndex) {
          prevTaskId = endIndex === 0 ? null : inProgressTasks[endIndex - 1]?.id;
          nextTaskId = endIndex === inProgressTasks.length - 1 ? null : inProgressTasks[endIndex]?.id;
        } else {
          prevTaskId = endIndex === 0 ? null : inProgressTasks[endIndex]?.id;
          nextTaskId = endIndex === inProgressTasks.length - 1 ? null : inProgressTasks[endIndex + 1]?.id;
        }

        break;
      case "IN TESTING":
        if (startIndex > endIndex) {
          prevTaskId = endIndex === 0 ? null : inTestingTasks[endIndex - 1]?.id;
          nextTaskId = endIndex === inTestingTasks.length - 1 ? null : inTestingTasks[endIndex]?.id;
        } else {
          prevTaskId = endIndex === 0 ? null : inTestingTasks[endIndex]?.id;
          nextTaskId = endIndex === inTestingTasks.length - 1 ? null : inTestingTasks[endIndex + 1]?.id;
        }

        break;
      case "DONE":
        if (startIndex > endIndex) {
          prevTaskId = endIndex === 0 ? null : doneTasks[endIndex - 1]?.id;
          nextTaskId = endIndex === doneTasks.length - 1 ? null : doneTasks[endIndex]?.id;
        } else {
          prevTaskId = endIndex === 0 ? null : doneTasks[endIndex]?.id;
          nextTaskId = endIndex === doneTasks.length - 1 ? null : doneTasks[endIndex + 1]?.id;
        }
        break;
      default:
    }

    return [nextTaskId, prevTaskId];
  };

  const onDrop = async (e) => {
    const [nextTaskId, prevTaskId] = reorderTasks();

    const payload = {
      slug: taskId,
      status,
      nextTaskId,
      prevTaskId,
    };
    const result = await dispatch(updateTask(payload));
    setResponse(result);
    e.dataTransfer.clearData();
  };

  useEffect(() => {
    const grid = new Isotope(".box", {
      itemSelector: ".box-item",
      masonry: {
        columnWidth: 80.5,
        horizontalOrder: true,
      },
    });

    return () => {
      grid?.destroy();
    };
  }, [project, onDrop]);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
  }
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(document?.getElementById("container")?.offsetWidth);
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }
  const width = useWindowDimensions();
  const clearTaskStore = () => {
    dispatch(taskPageUnloaded());
  };

  return (
    <div className="editor-page" id="container">
      <div className="container page">
        <h4 className="text-muted">Board</h4>
        <div className="mt-2 box ms-md-4 ms-lg-0 ms-2" ref={boxRef}>
          <div className="border mt-1 rounded bg-light box-item" style={{ width: "316px" }}>
            <div
              className="rounded-top align-items-center d-flex justify-content-between"
              style={{
                height: "50px",
                width: "316px",
                backgroundColor: "#F1F3FA",
                borderBottom: "5px solid #6C757D",
              }}
            >
              <h6 className="text-muted pt-1 fw-bold board-status">TO DO</h6>
              <div className="d-flex" style={{ width: "77px" }}>
                <div className="d-flex align-items-center ms-1" style={{ color: "#B2B2B2" }}>
                  <Icon style={{ fontSize: "23px", color: "#B2B2B2", marginTop: "2px" }}>assignment</Icon>
                  <span>{toDoTasks?.length}</span>
                </div>
                <Link
                  to={`${location.pathname}/create-task`}
                  state={{ id: project?.id }}
                  style={{ textDecoration: "none" }}
                  onClick={clearTaskStore}
                >
                  <div className=" d-flex flex-column align-items-center mx-2">
                    <Icon style={{ fontSize: "26px", color: "#6C757D" }}>add</Icon>
                  </div>
                </Link>
              </div>
            </div>
            <div style={toDoTasks?.length > 4 ? { overflowY: "scroll", height: "470px" } : null}>
              <TaskList
                id="TO DO"
                dataTestId="to-do"
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDrop={onDrop}
                toDoTasks={toDoTasks}
                inProgressTasks={inProgressTasks}
                inTestingTasks={inTestingTasks}
                doneTasks={doneTasks}
                width={width}
              >
                {toDoTasks?.map((task, index) => (
                  <div
                    onDragStart={(e) => {
                      onDragStart(e, task?.id);
                    }}
                    onDragEnter={onDragEnter}
                    onDragOver={onDragOver}
                    style={{ cursor: "grab" }}
                    key={task?.id}
                    className="mx-2 mt-2 mb-4 bg-white d-flex flex-wrap p-2 rounded"
                    draggable
                    id={index}
                    data-testid="draggable"
                  >
                    <div className="d-flex">
                      <div className="d-flex justify-content-between" style={{ width: "265px" }}>
                        <div className="text-break" style={{ paddingBottom: "8px" }}>
                          <Link to={`${location.pathname}/task-detail/${task?.id}`} style={{ textDecoration: "none" }}>
                            <h6 className="text-muted fw-bold text-wrap" style={{ color: "#6C757D", fontSize: "15px" }}>
                              {task?.title}
                            </h6>
                          </Link>
                        </div>

                        <div
                          className="d-flex mx-2 border-0 mt-1 d-flex align-items-center justify-content-center"
                          style={
                            (task?.priority === "High" && {
                              background: "#dc3545",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority === "Medium" && {
                              background: "#ffc107",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority === "Low" && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority !== ("High", "Medium", "Low") && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            })
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-flex align-items-center mt-2 justify-content-between "
                        style={{ width: "265px" }}
                      >
                        <div className="d-flex">
                          <Icon style={{ fontSize: "22px", color: "#6C757D", marginTop: "4px", marginLeft: "11px" }}>
                            assignment
                          </Icon>
                          <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#6C757D" }}>
                            #{task?.id}
                          </p>

                          {formatDateNoTime(new Date()) > formatDateNoTime(new Date(task?.deadline)) ? (
                            <>
                              <Icon
                                style={{ fontSize: "22px", color: "#DC3545", marginTop: "4px", marginLeft: "15px" }}
                              >
                                date_range
                              </Icon>
                              <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#DC3545", width: "80px" }}>
                                {formatDeadlineTime(new Date(task?.deadline))}
                              </p>
                            </>
                          ) : (
                            <>
                              <Icon
                                style={{ fontSize: "22px", color: "#6C757D", marginTop: "4px", marginLeft: "15px" }}
                              >
                                date_range
                              </Icon>
                              <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#6C757D", width: "80px" }}>
                                {formatDeadlineTime(new Date(task?.deadline))}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="dropdown">
                          <a
                            className="nav-link dropdown"
                            href="# "
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <Icon style={{ fontSize: "18px", color: "#6C757D", marginTop: "8px" }}>more_vert</Icon>
                          </a>
                          <ul className="dropdown-menu position-absolute" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                              <Link
                                to={`${location.pathname}/task-detail/${task?.id}`}
                                type="button"
                                className="dropdown-item"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                Task Detail
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </TaskList>
              {toDoTasks?.length > 4 ? (
                <p className="text-secondary" style={{ fontSize: "12px", color: "#DC3545", textAlign: "center" }}>
                  Showing all issues
                </p>
              ) : null}
            </div>
          </div>
          <div className="border mt-1 rounded bg-light box-item" style={{ width: "316px" }}>
            <div
              className="rounded-top align-items-center d-flex justify-content-between"
              style={{
                height: "50px",
                width: "316px",
                backgroundColor: "#F1F3FA",
                borderBottom: "5px solid #106DF6",
              }}
            >
              <h6 className="text-muted pt-1 fw-bold board-status">IN PROGRESS</h6>
              <div className="d-flex align-items-start ms-1" style={{ color: "#B2B2B2", width: "77px" }}>
                <Icon style={{ fontSize: "23px", color: "#B2B2B2", marginTop: "2px" }}>assignment</Icon>
                <span>{inProgressTasks?.length}</span>
              </div>
            </div>
            <div style={inProgressTasks?.length > 4 ? { overflowY: "scroll", height: "470px" } : null}>
              <TaskList
                id="IN PROGRESS"
                dataTestId="in-progress"
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDrop={onDrop}
                toDoTasks={toDoTasks}
                inProgressTasks={inProgressTasks}
                inTestingTasks={inTestingTasks}
                doneTasks={doneTasks}
                width={width}
              >
                {inProgressTasks?.map((task, index) => (
                  <div
                    style={{ cursor: "grab" }}
                    key={task?.id}
                    className="mx-2 mt-2 mb-4 bg-white d-flex flex-wrap p-2 rounded"
                    draggable
                    id={index}
                    data-testid="draggable"
                    onDragStart={(e) => {
                      onDragStart(e, task?.id);
                    }}
                    onDragOver={onDragOver}
                    onDragEnter={onDragEnter}
                  >
                    <div className="d-flex">
                      <div className="d-flex justify-content-between" style={{ width: "265px" }}>
                        <div className="text-break" style={{ paddingBottom: "8px" }}>
                          <Link to={`${location.pathname}/task-detail/${task?.id}`} style={{ textDecoration: "none" }}>
                            <h6 className="text-muted fw-bold text-wrap" style={{ color: "#6C757D", fontSize: "15px" }}>
                              {task?.title}
                            </h6>
                          </Link>
                        </div>

                        <div
                          className="d-flex mx-2 border-0 mt-1 d-flex align-items-center justify-content-center"
                          style={
                            (task?.priority === "High" && {
                              background: "#dc3545",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority === "Medium" && {
                              background: "#ffc107",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority === "Low" && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority !== ("High", "Medium", "Low") && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            })
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-flex align-items-center mt-2 justify-content-between "
                        style={{ width: "265px" }}
                      >
                        <div className="d-flex">
                          <Icon style={{ fontSize: "22px", color: "#6C757D", marginTop: "4px", marginLeft: "11px" }}>
                            assignment
                          </Icon>
                          <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#6C757D" }}>
                            #{task?.id}
                          </p>

                          {formatDateNoTime(new Date()) > formatDateNoTime(new Date(task?.deadline)) ? (
                            <>
                              <Icon
                                style={{ fontSize: "22px", color: "#DC3545", marginTop: "4px", marginLeft: "15px" }}
                              >
                                date_range
                              </Icon>
                              <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#DC3545", width: "80px" }}>
                                {formatDeadlineTime(new Date(task?.deadline))}
                              </p>
                            </>
                          ) : (
                            <>
                              <Icon
                                style={{ fontSize: "22px", color: "#6C757D", marginTop: "4px", marginLeft: "15px" }}
                              >
                                date_range
                              </Icon>
                              <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#6C757D", width: "80px" }}>
                                {formatDeadlineTime(new Date(task?.deadline))}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="dropdown">
                          <a
                            className="nav-link dropdown"
                            href="# "
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <Icon style={{ fontSize: "18px", color: "#6C757D", marginTop: "8px" }}>more_vert</Icon>
                          </a>
                          <ul className="dropdown-menu position-absolute" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                              <Link
                                to={`${location.pathname}/task-detail/${task?.id}`}
                                type="button"
                                className="dropdown-item"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                Task Detail
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </TaskList>
              {inProgressTasks?.length > 4 ? (
                <p className="text-secondary" style={{ fontSize: "12px", color: "#DC3545", textAlign: "center" }}>
                  Showing all issues
                </p>
              ) : null}
            </div>
          </div>
          <div className="border mt-1 rounded bg-light box-item" style={{ width: "316px" }}>
            <div
              className="rounded-top align-items-center d-flex justify-content-between"
              style={{
                height: "50px",
                width: "316px",
                backgroundColor: "#F1F3FA",
                borderBottom: "5px solid #DC3545",
              }}
            >
              <h6 className="text-muted pt-1 fw-bold board-status">IN TESTING</h6>
              <div className="d-flex align-items-start ms-1" style={{ color: "#B2B2B2", width: "77px" }}>
                <Icon style={{ fontSize: "23px", color: "#B2B2B2", marginTop: "2px" }}>assignment</Icon>
                <span>{inTestingTasks?.length}</span>
              </div>
            </div>
            <div style={inTestingTasks?.length > 4 ? { overflowY: "scroll", height: "470px" } : null}>
              <TaskList
                id="IN TESTING"
                dataTestId="intesting"
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDrop={onDrop}
                toDoTasks={toDoTasks}
                inProgressTasks={inProgressTasks}
                inTestingTasks={inTestingTasks}
                doneTasks={doneTasks}
                width={width}
              >
                {inTestingTasks?.map((task, index) => (
                  <div
                    style={{ cursor: "grab" }}
                    key={task?.id}
                    className="mx-2 mt-2 mb-4 bg-white d-flex flex-wrap p-2 rounded"
                    draggable
                    id={index}
                    data-testid="draggable"
                    onDragStart={(e) => {
                      onDragStart(e, task?.id);
                    }}
                    onDragOver={onDragOver}
                    onDragEnter={onDragEnter}
                  >
                    <div className="d-flex">
                      <div className="d-flex justify-content-between" style={{ width: "265px" }}>
                        <div className="text-break" style={{ paddingBottom: "8px" }}>
                          <Link to={`${location.pathname}/task-detail/${task?.id}`} style={{ textDecoration: "none" }}>
                            <h6 className="text-muted fw-bold text-wrap" style={{ color: "#6C757D", fontSize: "15px" }}>
                              {task?.title}
                            </h6>
                          </Link>
                        </div>
                        <div
                          className="d-flex mx-2 border-0 mt-1 d-flex align-items-center justify-content-center"
                          style={
                            (task?.priority === "High" && {
                              background: "#dc3545",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority === "Medium" && {
                              background: "#ffc107",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority === "Low" && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority !== ("High", "Medium", "Low") && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            })
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-flex align-items-center mt-2 justify-content-between "
                        style={{ width: "265px" }}
                      >
                        <div className="d-flex">
                          <Icon style={{ fontSize: "22px", color: "#6C757D", marginTop: "4px", marginLeft: "11px" }}>
                            assignment
                          </Icon>
                          <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#6C757D" }}>
                            #{task?.id}
                          </p>

                          {formatDateNoTime(new Date()) > formatDateNoTime(new Date(task?.deadline)) ? (
                            <>
                              <Icon
                                style={{ fontSize: "22px", color: "#DC3545", marginTop: "4px", marginLeft: "15px" }}
                              >
                                date_range
                              </Icon>
                              <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#DC3545", width: "80px" }}>
                                {formatDeadlineTime(new Date(task?.deadline))}
                              </p>
                            </>
                          ) : (
                            <>
                              <Icon
                                style={{ fontSize: "22px", color: "#6C757D", marginTop: "4px", marginLeft: "15px" }}
                              >
                                date_range
                              </Icon>
                              <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#6C757D", width: "80px" }}>
                                {formatDeadlineTime(new Date(task?.deadline))}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="dropdown">
                          <a
                            className="nav-link dropdown"
                            href="# "
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <Icon style={{ fontSize: "18px", color: "#6C757D", marginTop: "8px" }}>more_vert</Icon>
                          </a>
                          <ul className="dropdown-menu position-absolute" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                              <Link
                                to={`${location.pathname}/task-detail/${task?.id}`}
                                type="button"
                                className="dropdown-item"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                Task Detail
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </TaskList>
              {inTestingTasks?.length > 4 ? (
                <p className="text-secondary" style={{ fontSize: "12px", color: "#DC3545", textAlign: "center" }}>
                  Showing all issues
                </p>
              ) : null}
            </div>
          </div>
          <div className="border mt-1 rounded bg-light box-item" style={{ width: "316px" }}>
            <div
              className="rounded-top align-items-center d-flex justify-content-between"
              style={{
                height: "50px",
                width: "316px",
                backgroundColor: "#F1F3FA",
                borderBottom: "5px solid #198754",
              }}
            >
              <h6 className="text-muted pt-1 fw-bold board-status">DONE</h6>
              <div className="d-flex align-items-start ms-1" style={{ color: "#B2B2B2", width: "77px" }}>
                <Icon style={{ fontSize: "23px", color: "#B2B2B2", marginTop: "2px" }}>assignment</Icon>
                <span>{doneTasks?.length}</span>
              </div>
            </div>
            <div style={doneTasks?.length > 4 ? { overflowY: "scroll", height: "470px" } : null}>
              <TaskList
                id="DONE"
                dataTestId="done"
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDrop={onDrop}
                toDoTasks={toDoTasks}
                inProgressTasks={inProgressTasks}
                inTestingTasks={inTestingTasks}
                doneTasks={doneTasks}
                width={width}
              >
                {doneTasks?.map((task, index) => (
                  <div
                    style={{ cursor: "grab" }}
                    key={task?.id}
                    className="mx-2 mt-2 mb-4 bg-white d-flex flex-wrap p-2 rounded"
                    draggable
                    id={index}
                    data-testid="draggable"
                    onDragStart={(e) => {
                      onDragStart(e, task?.id);
                    }}
                    onDragOver={onDragOver}
                    onDragEnter={onDragEnter}
                  >
                    <div className="d-flex">
                      <div className="d-flex justify-content-between" style={{ width: "265px" }}>
                        <div className="text-break" style={{ paddingBottom: "8px" }}>
                          <Link to={`${location.pathname}/task-detail/${task?.id}`} style={{ textDecoration: "none" }}>
                            <h6 className="text-muted fw-bold text-wrap" style={{ color: "#6C757D", fontSize: "15px" }}>
                              {task?.title}
                            </h6>
                          </Link>
                        </div>
                        <div
                          className="d-flex mx-2 border-0 mt-1 d-flex align-items-center justify-content-center"
                          style={
                            (task?.priority === "High" && {
                              background: "#dc3545",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority === "Medium" && {
                              background: "#ffc107",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority === "Low" && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (task?.priority !== ("High", "Medium", "Low") && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            })
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-flex align-items-center mt-2 justify-content-between "
                        style={{ width: "265px" }}
                      >
                        <div className="d-flex">
                          <Icon style={{ fontSize: "22px", color: "#6C757D", marginTop: "4px", marginLeft: "11px" }}>
                            assignment
                          </Icon>
                          <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#6C757D" }}>
                            #{task?.id}
                          </p>

                          {formatDateNoTime(new Date()) > formatDateNoTime(new Date(task?.deadline)) ? (
                            <>
                              <Icon
                                style={{ fontSize: "22px", color: "#DC3545", marginTop: "4px", marginLeft: "15px" }}
                              >
                                date_range
                              </Icon>
                              <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#DC3545", width: "80px" }}>
                                {formatDeadlineTime(new Date(task?.deadline))}
                              </p>
                            </>
                          ) : (
                            <>
                              <Icon
                                style={{ fontSize: "22px", color: "#6C757D", marginTop: "4px", marginLeft: "15px" }}
                              >
                                date_range
                              </Icon>
                              <p className="mt-1 mb-0" style={{ fontSize: "12px", color: "#6C757D", width: "80px" }}>
                                {formatDeadlineTime(new Date(task?.deadline))}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="dropdown">
                          <a
                            className="nav-link dropdown"
                            href="# "
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <Icon style={{ fontSize: "18px", color: "#6C757D", marginTop: "8px" }}>more_vert</Icon>
                          </a>
                          <ul className="dropdown-menu position-absolute" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                              <Link
                                to={`${location.pathname}/task-detail/${task?.id}`}
                                type="button"
                                className="dropdown-item"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                Task Detail
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </TaskList>
              {doneTasks?.length > 4 ? (
                <p className="text-secondary" style={{ fontSize: "12px", color: "#DC3545", textAlign: "center" }}>
                  Showing all issues
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(BoardScreen);

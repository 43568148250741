import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { Modal, formatDateNextTime, generateKey, timeSince } from "../../common/utils";
import Members from "../../common/Members";
import { selectIsAuthenticated } from "../../features/auth/authSlice";
import CommentSection from "../../features/comments/CommentSection";
import { deleteTask } from "../../reducers/common";
import { getProject } from "../../reducers/project";
import { assignUser, getTask, taskPageUnloaded, unAssignUser, updateTask } from "../../reducers/task";
import { getAllUsers } from "../../reducers/users";
import DropFileInput from "../DropFileInput";
import "./task.css";
import { getGitCommits } from "../../reducers/gitCommits";

/**
 * TaskDetail component
 * @param {import('react-router-dom').RouteComponentProps<{ slug?: string }>} props
 * @example
 * <TaskDetail />
 */
function TaskDetail() {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state?.project);
  const { task } = useSelector((state) => state.task);
  const isAuthenticaded = useSelector(selectIsAuthenticated);
  const [tasks, setTasks] = useState();
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [priority, setPriority] = useState();
  const [members, setMembers] = useState();
  const [successResponse, setSuccessResponse] = useState(false);
  const [users, setUsers] = useState();
  const { slug } = useParams();
  const { gitCommits } = useSelector((state) => state.gitCommits);
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location.pathname.match(/[0-9]+/)[0];

  const taskPriority = {
    high: "High",
    medium: "Medium",
    low: "Low",
  };

  useEffect(() => {
    dispatch(getTask(slug));
  }, [slug, successResponse]);

  useEffect(() => {
    if (task) dispatch(getGitCommits({ order: "ASC", reference: task?.reference }));
  }, [task]);

  useEffect(() => {
    if (task?.relatedTo === null) dispatch(getProject(projectId));
  }, [task]);

  useEffect(() => {
    setMembers(task?.assignees);
    setPriority(task?.priority);
  }, [task]);

  useEffect(() => {
    setIsTaskCompleted(task?.status === "DONE");
  }, [task]);

  useEffect(() => {
    setTasks(project?.Tasks);
  }, [project]);

  useEffect(() => {
    const getTaskById = async () => {
      const result = await dispatch(getAllUsers());
      setUsers(result.payload.users);
    };
    getTaskById();
  }, []);

  useEffect(() => () => dispatch(taskPageUnloaded()), []);

  const onChange = (e) => {
    const payload = {
      userId: e.target.value,
      taskId: task.id,
    };
    dispatch(assignUser(payload));
    const member = users.find((user) => user.id === +e.target.value);
    setMembers([...members, member]);
  };

  const handleDeleteUser = (id) => {
    const payload = {
      userId: id,
      taskId: task.id,
    };
    dispatch(unAssignUser(payload));
    const filteredMembers = members.filter((member) => member.id !== +id);
    setMembers(filteredMembers);
  };

  const handleDeleteItem = () => {
    dispatch(deleteTask(task.id));
    const updateTasks = tasks.filter((taskId) => taskId.id !== task.id);
    setTasks(updateTasks);
    navigate(-1);
  };
  const handleComplete = () => {
    const payload = {
      slug: task.id,
      status: isTaskCompleted ? "TO DO" : "DONE",
    };
    setIsTaskCompleted(!isTaskCompleted === true);

    dispatch(updateTask(payload));
    dispatch(getTask(slug));
  };

  const changePriority = async (e) => {
    const payload = {
      slug: task.id,
      priority: e.target.id,
    };
    if (e.target.id === "Low" || e.target.id === "Medium" || e.target.id === "High") {
      setPriority(e.target.id);
      const result = await dispatch(updateTask(payload));
      if (result.type.includes("fulfilled")) setSuccessResponse(result);
    }
  };

  return (
    <div className="taskDetail-page">
      <div className="container page">
        <div className="row">
          <div className="col-md-8">
            <div className="card border-0 mb-4">
              <div className="card-body bg-light">
                <h5 className="card-title pt-2 pb-3 fw-bolder text-muted">{task?.title}</h5>
                <div className="text-left mt-4 mb-5">
                  <div className="row">
                    <h6 className="card-subtitle text-muted mb-3">Overview</h6>
                    <p>{task?.description}</p>
                  </div>
                </div>
                {task?.relatedTo !== null ? (
                  <div className="d-flex flex-wrap align-items-center my-2">
                    <h6 className="card-subtitle text-muted mb-3 mt-3">Related to: </h6>
                    {tasks
                      ?.filter((istask) => istask.id === task?.relatedTo)
                      .map((istask) => (
                        <Link
                          to={`${location.pathname.substr(0, location.pathname.lastIndexOf("/"))}/${task?.relatedTo}`}
                          style={{
                            color: "black",
                            textDecoration: "none",
                          }}
                        >
                          <p className="card-subtitle mb-3 mt-3 ps-2">{istask?.title}</p>
                        </Link>
                      ))}
                  </div>
                ) : null}
                <div className="d-flex justify-content-between flex-wrap align-items-center my-2">
                  <h6 className="card-subtitle text-muted mb-3 mt-3">Sub-tasks</h6>
                  <Link
                    to={`${location.pathname}/create-task`}
                    state={{ id: task?.id }}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="d-flex flex-column align-items-end">
                      <button type="button" className="btn green_button" style={{ margin: 0 }}>
                        Add Sub-task
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="form-check p-0">
                  {tasks
                    ?.filter((subtask) => subtask.relatedTo === +slug)
                    .map((subTask) => (
                      <div className="card border-0 m-0 mb-1 ">
                        <div className="form-check me-2 my-1 ps-2" key={generateKey()}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label mt-1"
                              htmlFor="exampleCheck1"
                              style={{
                                color: "#8c8c8c",
                              }}
                            >
                              <Link
                                to={`${location.pathname.substr(0, location.pathname.lastIndexOf("/"))}/${subTask?.id}`}
                                state={{ id: project?.id, projectMembers: project?.members }}
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                {subTask.title}
                              </Link>
                            </label>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex">
                                {subTask?.assignees.map((user) => (
                                  <div className="image ms-2">
                                    <a href="# " className="username" data-title={user?.username}>
                                      <div className="image me-1" key={generateKey()}>
                                        {user?.image ? (
                                          <img
                                            src={user?.image}
                                            alt=""
                                            width="24"
                                            height="24"
                                            className="rounded-circle mb-2 mx-1"
                                            style={{ marginTop: "6px" }}
                                          />
                                        ) : (
                                          <Icon className="example" style={{ fontSize: "28px", marginTop: "4px" }}>
                                            account_circle
                                          </Icon>
                                        )}
                                      </div>
                                    </a>
                                  </div>
                                ))}
                              </div>

                              <div
                                className={
                                  (subTask?.status === "TO DO" &&
                                    " bg-secondary btn-sm  px-1 text-white fw-bold text-center mx-4") ||
                                  (subTask?.status === "IN PROGRESS" &&
                                    "bg-warning btn-sm  px-1 text-white fw-bold text-center mx-1") ||
                                  (subTask?.status === "IN TESTING" &&
                                    "bg-danger btn-sm  px-1 text-white fw-bold text-center mx-2") ||
                                  (subTask?.status === "DONE" &&
                                    "bg-success btn-sm  px-1 text-white fw-bold text-center mx-4")
                                }
                                style={{
                                  display: "flex",
                                  width: "auto",
                                  height: "20px",
                                  fontSize: "12px",
                                  padding: "initial",
                                }}
                              >
                                {subTask?.status}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="card border-0">
              <DropFileInput />
            </div>
            <div className="card border-0">
              <div className="card-body bg-light">
                <h6 className="card-subtitle text-muted mb-4">Activity</h6>
                <ul className="timeline">
                  {task?.reference?.length !== 0 &&
                    gitCommits?.results?.map((data) => (
                      <li className="timeline-item mb-3">
                        <div className="d-flex align-items-center flex-wrap">
                          <h6 className="text-muted fw-bold">{data?.committerUsername}</h6>
                          <h6 className="text-muted fw-normal">&nbsp;mentioned in commit</h6>&nbsp;
                          <h6 className="text-muted fw-normal">
                            <a
                              href={data.commitUrl}
                              className="message"
                              data-title={data?.message}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              {data.sha}
                            </a>
                          </h6>
                          &nbsp;
                          <h6 className="text-muted fw-normal">
                            <a
                              href="# "
                              className="timeSince"
                              data-title={new Date(data?.committedDate)}
                              style={{ textDecoration: "none", color: "#6c757d" }}
                            >
                              {timeSince(new Date(data?.committedDate))}
                            </a>
                          </h6>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="card border-0">
              <div className="card-body bg-light">
                <CommentSection />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card border-0">
              <div className="card-body bg-light">
                <div className="d-flex justify-content-between">
                  <div className="form-check pt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="defaultCheck1"
                      checked={isTaskCompleted}
                      onChange={handleComplete}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck1"
                      style={{
                        color: "#8c8c8c",
                      }}
                    >
                      Mark as completed
                      <input type="text" hidden />
                    </label>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    {isAuthenticaded && (
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-lg btn-link mod-options py-0 px-1"
                          name="delete"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <Icon style={{ fontSize: "22px", marginBottom: "-4px" }} className="delete_icon">
                            delete
                          </Icon>
                          <span className="sr-only">Delete task</span>
                        </button>
                        <Modal handleDeleteItem={handleDeleteItem} id="exampleModal" fontSize={15} />
                      </div>
                    )}
                    &nbsp;&nbsp;&nbsp;
                    <Link
                      to={`${location.pathname}/edit-task/${slug}`}
                      style={{ textDecoration: "none" }}
                      state={{ isEditScreen: true }}
                    >
                      <Icon style={{ fontSize: "20px", marginBottom: "-4px" }} className="edit_icon">
                        edit
                      </Icon>
                    </Link>
                  </div>
                </div>
                <h5 className="card-title pt-2 pb-3 m-0 fw-bolder text-muted">{task?.title}</h5>
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                  <h6 className="card-subtitle text-muted mt-3 mb-3">Priority</h6>
                  <div className="dropdown d-flex">
                    <button
                      className="nav-link dropdown-toggle d-flex pb-0 pe-0 d-flex align-items-center border bg-white justify-content-between px-2"
                      style={{ borderRadius: "5px", width: "160px", height: "32px" }}
                      href="# "
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      type="button"
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="d-flex me-1 border-0 d-flex align-items-center justify-content-center"
                          style={
                            (priority === taskPriority?.high && {
                              background: "#dc3545",
                              height: "17px",
                              width: "17px",
                              borderRadius: "0.2rem",
                            }) ||
                            (priority === taskPriority?.medium && {
                              background: "#ffc107",
                              height: "17px",
                              width: "17px",
                              borderRadius: "0.2rem",
                            }) ||
                            (priority === taskPriority?.low && {
                              background: "#198754",
                              height: "17px",
                              width: "17px",
                              borderRadius: "0.2rem",
                            }) ||
                            (priority !== (taskPriority?.high, taskPriority?.medium, taskPriority?.low) && {
                              display: "none",
                            })
                          }
                        />
                        {!priority ? "Select priority" : priority}
                      </div>
                      <Icon style={{ fontSize: "21px", paddingRight: "30px" }}>keyboard_arrow_down</Icon>
                    </button>

                    <ul
                      className="dropdown-menu position-absolute"
                      style={{ minWidth: 0, width: "150px" }}
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a
                          className={
                            task?.priority === taskPriority?.low
                              ? `
                          dropdown-item align-items-center d-flex px-2 disabled`
                              : `dropdown-item align-items-center d-flex px-2`
                          }
                          href="# "
                          key={generateKey()}
                          id={taskPriority?.low}
                          onClick={changePriority}
                        >
                          <div
                            className="d-flex me-1 border-0 d-flex align-items-center justify-content-center"
                            style={{
                              background: "#198754",
                              height: "17px",
                              width: "17px",
                              borderRadius: "0.2rem",
                            }}
                          />
                          {taskPriority?.low}
                        </a>
                      </li>
                      <li>
                        <a
                          className={
                            task?.priority === taskPriority?.medium
                              ? `
                      dropdown-item align-items-center d-flex px-2 disabled`
                              : `dropdown-item align-items-center d-flex px-2`
                          }
                          href="# "
                          key={generateKey()}
                          id={taskPriority?.medium}
                          onClick={changePriority}
                        >
                          <div
                            className="d-flex me-1 border-0 d-flex align-items-center justify-content-center"
                            style={{
                              background: "#ffc107",
                              height: "17px",
                              width: "17px",
                              borderRadius: "0.2rem",
                            }}
                          />
                          {taskPriority?.medium}
                        </a>
                      </li>
                      <li>
                        <a
                          className={
                            task?.priority === taskPriority?.high
                              ? `
                      dropdown-item align-items-center d-flex px-2 disabled`
                              : `dropdown-item align-items-center d-flex px-2`
                          }
                          href="# "
                          key={generateKey()}
                          id={taskPriority?.high}
                          onClick={changePriority}
                        >
                          <div
                            className="d-flex me-1 border-0 d-flex align-items-center justify-content-center"
                            style={{
                              background: "#dc3545",
                              height: "17px",
                              width: "17px",
                              borderRadius: "0.2rem",
                            }}
                          />
                          {taskPriority?.high}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                  <h6 className="card-subtitle text-muted mt-3 mb-3">Team Members</h6>
                  <select
                    className="form-select form-select-sm"
                    style={{ width: "160px", height: "30px" }}
                    aria-label="Default select example"
                    onChange={onChange}
                  >
                    <option type="others">Select a member</option>
                    {project?.members.map((user) => (
                      <option
                        disabled={members?.some((e) => e.username === user.username)}
                        key={generateKey()}
                        value={user.id}
                        id={user.username}
                      >
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
                <h6 className="card-subtitle text-muted mt-3 mb-3">
                  {members?.length !== 0 ? "Assigned to" : "Unassigned"}
                </h6>
                <Members members={members} isTaskDetail handleDeleteUser={handleDeleteUser} height="mt-3" />
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                  <h6 className="card-subtitle text-muted mt-3 mb-3">Story Points Estimate</h6>
                  {task?.estimation === 0 || task?.estimation === null ? (
                    <p className="mt-2 mb-2">None</p>
                  ) : (
                    <p className="mt-2 mb-2">{task?.estimation}</p>
                  )}
                </div>
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                  <h6 className="card-subtitle text-muted mt-3 mb-3">Deadline</h6>
                  <div className="d-flex">
                    <p className="card-text">{task?.deadline ? formatDateNextTime(new Date(task?.deadline)) : null}</p>
                  </div>
                </div>
                {task?.closedAt !== null ? (
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <h6 className="card-subtitle text-muted mt-3 mb-3">Closed date</h6>
                    <div className="d-flex">
                      <p className="card-text">{formatDateNextTime(new Date(task?.closedAt))}</p>
                    </div>
                  </div>
                ) : null}
                {task?.cost !== null ? (
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <h6 className="card-subtitle text-muted mt-3 mb-3">Cost</h6>
                    <p className="card-text">{task?.cost}$</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TaskDetail);

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import "../components/style.css";

/**
 * Shows a banner for new users
 *
 * @example
 * <Banner />
 */
function EditButton({ link }) {
  return (
    <div>
      <Link to={link} className="btn btn-sm gray_button float-end" style={{ minWidth: "72.3px" }}>
        <Icon style={{ fontSize: "16px", marginBottom: "-3px", marginRight: "2px" }}>settings</Icon>
        Edit
      </Link>
    </div>
  );
}
EditButton.propTypes = {
  link: PropTypes.string,
};

EditButton.defaultProps = {
  link: "false",
};

export default EditButton;

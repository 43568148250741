import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mui/material/Icon";
import { generateKey } from "../common/utils";
import { deleteUpload } from "../reducers/common";
import { getProject, uploadFile } from "../reducers/project";
import { getTask, upload } from "../reducers/task";

function DropFileInput({ project }) {
  const { task } = useSelector((state) => state.task);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const inputRef = useRef();

  useEffect(() => {
    if (task) setFiles(task?.Attachments);
    else setFiles(project?.Attachments.filter((attachment) => attachment.isCover !== true));
  }, [task, project]);

  const drag = () => inputRef.current.classList.add("dragover");

  const drop = () => inputRef.current.classList.remove("drop");

  const fileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    if (task) {
      await dispatch(upload({ file: formData, taskId: task.id }));
      dispatch(getTask(task?.id));
    } else {
      await dispatch(uploadFile({ file: formData, projectId: project.id }));
      dispatch(getProject(project?.id));
    }
  };

  const fileRemove = (id) => {
    dispatch(deleteUpload(id));
    const updatedFiles = files.filter((file) => file.id !== id);
    setFiles(updatedFiles);
  };

  const openFile = (file) => {
    window.open(`http://docs.google.com/gview?url=${file}&embedded=true`, "_blank");
  };

  const ImageConfig = {
    default:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcTzsiKgJMt1ybLmqDpC2ynJWW-9nkk_chxrjQggfTUlhUeUdA&s",
    jpg: "https://cdn-icons-png.flaticon.com/256/337/337940.png",
    jpeg: "https://cdn-icons-png.flaticon.com/256/337/337940.png",
    png: "https://cdn-icons-png.flaticon.com/256/7271/7271048.png",
    mp4: "https://cdn-icons-png.flaticon.com/256/440/440845.png",
    mp3: "https://cdn-icons-png.flaticon.com/256/337/337944.png",
    pdf: "https://cdn-icons-png.flaticon.com/256/337/337946.png",
    ppt: "https://cdn-icons-png.flaticon.com/256/337/337949.png",
    pptx: "https://cdn-icons-png.flaticon.com/256/337/337949.png",
    docx: "https://cdn-icons-png.flaticon.com/256/716/716935.png",
    doc: "https://cdn-icons-png.flaticon.com/256/716/716935.png",
    xlsx: "https://cdn-icons-png.flaticon.com/256/337/337958.png",
    xls: "https://cdn-icons-png.flaticon.com/256/337/337958.png",
  };

  const checkExtension = (fileName) => {
    switch (fileName?.split(".")[1] || fileName?.split(".").slice(-1)[0]) {
      case "mp4":
        return false;
      case "mp3":
        return false;
      case "pdf":
        return false;
      case "ppt":
        return false;
      case "pptx":
        return false;
      case "doc":
        return false;
      case "docx":
        return false;
      case "xls":
        return false;
      case "xlsx":
        return false;
      default:
        return true;
    }
  };

  const openImage = (fileUrl) => {
    const image = window.open("", "_blank");
    image.document.write(`
        <html>
          <head>
          <style>
          body{
            background-color:black;
          }
          img {
            max-width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          </style>
          </head>
          <body>
            <img src=${fileUrl} alt="Example" >
          </body>
        </html>
  `);
  };

  return (
    <div className="card-body bg-light">
      <div>
        <h6 className="card-subtitle text-muted">Files</h6>
        <span
          className="d-flex align-items-center position-relative mt-4 justify-content-between"
          style={{
            border: "2px dashed #ccc",
            minHeight: "65px",
          }}
        >
          {" "}
          <Icon
            style={{
              fontSize: "62px",
              color: "#6C757D",
            }}
            className="ms-3 me-1"
          >
            cloud_upload
          </Icon>
          <div className="d-flex align-items-center">
            <p className="font-weight-bold text-muted h5 mb-0" style={{ fontSize: "18px" }}>
              Drop file here or click to upload, less than 10MB.
            </p>
          </div>
          <input
            className="h-100 w-100 position-absolute opacity-0 top-0 left-0"
            type="file"
            multiple
            onChange={fileUpload}
            onDragOver={drag}
            onDrop={drop}
            id="uploadFile"
            ref={inputRef}
          />
          <div className="ms-lg-4" />
        </span>
      </div>

      {files?.length > 0 ? (
        <div className="files-list d-flex flex-wrap" style={{ gap: "10px" }}>
          {files?.map((file) => (
            <div
              className="rounded bg-white mt-3 p-2 d-flex align-items-center"
              key={generateKey()}
              style={{ display: "flex", flexDirection: "column", width: "125px" }}
            >
              <div
                className="dropdown"
                style={{
                  height: "25px",
                  marginLeft: "100px",
                }}
              >
                <Icon
                  onKeyDown={() => fileRemove(file.id)}
                  role="button"
                  tabIndex="0"
                  aria-label="Close"
                  style={{
                    fontSize: "25px",
                    cursor: "pointer",
                    fontWeight: 500,
                    marginBottom: "500px",
                    color: "#6C757D",
                  }}
                  onClick={() => fileRemove(file.id)}
                >
                  close
                </Icon>
              </div>
              <button
                type="button"
                className="border-0"
                style={{ backgroundColor: "transparent" }}
                onClick={() =>
                  checkExtension(file?.name) === true ? openImage(file?.image_url) : openFile(file?.image_url)
                }
              >
                <div className="files d-flex">
                  <img
                    src={
                      ImageConfig[file?.name.split(".")[1]] ||
                      ImageConfig[file?.name.split(".").slice(-1)[0]] ||
                      ImageConfig.default
                    }
                    alt=""
                    width="50"
                    height="50"
                  />
                </div>
                <p
                  className="text-break pe-3"
                  style={{
                    marginBottom: 0,
                    flex: 1,
                    width: "80px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginLeft: "8px",
                    fontSize: "14px",
                  }}
                >
                  {file?.name}
                </p>
              </button>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

DropFileInput.propTypes = {
  project: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool, PropTypes.array])
  ),
};
DropFileInput.defaultProps = {
  project: null,
};

export default memo(DropFileInput);

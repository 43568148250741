import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type.includes("/pending"),
        (stateLoading = 0) => {
          const state = stateLoading;
          state.loading += 1;
        }
      )
      .addMatcher(
        (action) => action.type.includes("/fulfilled"),
        (stateLoading = 0) => {
          const state = stateLoading;
          state.loading -= 1;
        }
      )
      .addMatcher(
        (action) => action.type.includes("/rejected"),
        (stateLoading = 0) => {
          const state = stateLoading;
          state.loading -= 1;
        }
      );
  },
  reducers: {},
});

export default loadingSlice.reducer;

import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { changeArticleTab } from "../../reducers/articleList";

/**
 * ArticleTabs's navigation
 *
 * @param {Object}  props
 * @param {String}  props.username
 * @param {Boolean} props.isFavorites
 * @example
 * <ArticleTabs username="warren_boyd" isFavorites />
 */
function ArticleTabs({ setArticles }) {
  const currentUser = useSelector(selectUser);
  const { articles } = useSelector((state) => state.articles);
  const [isSelected, setIsSelected] = useState("All");
  const dispatch = useDispatch();
  const location = useLocation();

  const findByAuthor = () => {
    const filteredByAuthor = articles?.filter((obj) => obj.authorId === currentUser.id);
    setArticles(filteredByAuthor);
  };

  const changeActiveLink = (name) => {
    setIsSelected(name);
  };

  const dispatchChangeTab = () => {
    dispatch(changeArticleTab("all"));
  };

  return (
    <div className="articles-toggle">
      {currentUser?.role?.name === "manager" && (
        <nav>
          <ul className="pagination pagination-md">
            <li
              className={isSelected === "All" ? "page-item active" : "page-item text-dark"}
              style={{ cursor: "pointer" }}
            >
              <button
                type="submit"
                className={isSelected === "All" ? "page-link" : "page-link text-dark"}
                onClick={() => {
                  dispatchChangeTab();
                  changeActiveLink("All");
                }}
                onKeyUp={() => {
                  dispatchChangeTab();
                  changeActiveLink("All");
                }}
              >
                All
              </button>
            </li>
            <li
              className={isSelected === "My Articles" ? "page-item active" : "page-item text-dark"}
              style={{ cursor: "pointer" }}
            >
              <button
                type="submit"
                className={isSelected === "My Articles" ? "page-link" : "page-link text-dark"}
                onClick={(e) => {
                  findByAuthor(e);
                  changeActiveLink("My Articles");
                }}
              >
                My Articles
              </button>
            </li>
            <li
              className={isSelected === "New Post" ? "page-item active" : "page-item text-dark"}
              style={{ cursor: "pointer" }}
            >
              <Link to={`${location.pathname}/create-article`}>
                <button
                  type="submit"
                  className={isSelected === "New Post" ? "page-link" : "page-link text-dark"}
                  onClick={() => {
                    changeActiveLink("New Post");
                  }}
                >
                  New Post
                </button>
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

ArticleTabs.propTypes = {
  setArticles: PropTypes.func.isRequired,
};

export default memo(ArticleTabs);

import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../reducers/projectList";

/**
 * Show a list with the available pages
 *
 * @example
 * <ProjectListPagination />
 */
function ProjectListPagination() {
  const dispatch = useDispatch();
  const projectsCount = useSelector((state) => state.projects.projectsCount);
  const currentPage = useSelector((state) => state.projects.currentPage);
  const projectsPerPage = useSelector((state) => state.projects.projectsPerPage);

  if (projectsCount <= projectsPerPage) {
    return null;
  }

  const pages = Array.from({ length: Math.ceil(projectsCount / projectsPerPage) }, (_, number) => number);

  const handleClickPage = (page) => () => {
    dispatch(getAllProjects({ page }));
  };

  return (
    <nav>
      <ul className="pagination">
        {pages.map((page) => {
          const isActivePage = page === currentPage;
          return (
            <li
              className={isActivePage ? "page-item active" : "page-item"}
              onClick={handleClickPage(page)}
              key={page.toString()}
              aria-hidden="true"
            >
              <button type="button" className="page-link">
                {page + 1}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default memo(ProjectListPagination);

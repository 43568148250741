import PropTypes from "prop-types";
import React, { memo } from "react";
import ProjectCard from "./ProjectCard";
import ProjectListPagination from "./ProjectListPagination";

/**
 * List all projects and show pagination
 *
 * @example
 * <ProjectList />
 */
function ProjectList({ projects, query }) {
  return (
    <div className="container p-0">
      <div className="d-flex flex-wrap justify-content-center justify-content-sm-center justify-content-md-between justify-content-lg-between justify-content-xl-start justify-content-xxl-start p-0">
        {projects
          ?.filter((project) => project.title.toLowerCase().includes(query.toLowerCase()))
          .map((project) => (
            <ProjectCard project={project} key={project.id} number={projects.length} />
          ))}
      </div>
      <ProjectListPagination />
    </div>
  );
}

ProjectList.propTypes = {
  projects: PropTypes.instanceOf(Array),
  query: PropTypes.string,
};
ProjectList.defaultProps = {
  projects: [],
  query: "",
};
export default memo(ProjectList);

import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import Protected from "../common/Protected";
import ForgotPassword from "../features/auth/ForgotPassword";
import ResetPassword from "../features/auth/ResetPassword";
import Article from "../components/Article/index";
import TaskDetail from "../components/Task/TaskDetail";
import UsersScreen from "../components/Users/UsersScreen";
import TasksScreen from "../components/Task/TasksScreen";
import OrganizationScreen from "../components/Organization/OrganizationScreen";
import BoardScreen from "../components/Board/BoardScreen";
import CreateProject from "../components/Project/CreateProject";
import CreateArticle from "../components/Article/CreateArticle";
import CreateTask from "../components/Task/CreateTask";
import ProjectDetail from "../components/Project/ProjectDetail";
import Profile from "../components/Profile";
import EditOrganizationScreen from "../components/Organization/EditOrganizationScreen";
import AuthScreen from "../features/auth/AuthScreen";
import EditProfileScreen from "../features/auth/EditProfileScreen";
import Home from "../components/Home";
import NewsFeed from "../components/Article/NewsFeed";
import Spinner from "../utils/spinner";
import SettingsScreen from "../features/auth/SettingsScreen";

function RoutesComponent({ query }) {
  return (
    <Routes>
      <Route path="/user/:id">
        <Route index element={<Profile />} />
        <Route path="favorites" element={<Profile isFavoritePage />} />
        <Route path="edit-profile">
          <Route index element={<EditProfileScreen />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
      </Route>
      <Route path="/login">
        <Route index element={<AuthScreen />} />
        <Route path="forgot-password">
          <Route index element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
      </Route>
      <Route path="/register" element={<AuthScreen isRegisterScreen />} />
      <Route element={<Protected />}>
        <Route exact path="/" element={<Home query={query} />} />
        <Route path="/organization">
          <Route index element={<OrganizationScreen />} />
          <Route path="edit-organization">
            <Route index element={<EditOrganizationScreen />} />
          </Route>
          <Route path="user/:id">
            <Route index element={<Profile />} />
            <Route path="favorites" element={<Profile isFavoritePage />} />
            <Route path="edit-profile">
              <Route index element={<EditProfileScreen />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>
        </Route>
        <Route path="project-tasks/:slug">
          <Route index element={<TasksScreen query={query} />} />
          <Route path="user/:id">
            <Route index element={<Profile />} />
            <Route path="favorites" element={<Profile isFavoritePage />} />
            <Route path="edit-profile">
              <Route index element={<EditProfileScreen />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>
          <Route path="create-task" element={<CreateTask />} />
          <Route path="board/:slug">
            <Route index element={<BoardScreen />} />
            <Route path="create-task" element={<CreateTask />} />
            <Route path="task-detail/:slug">
              <Route index element={<TaskDetail />} />
              <Route path="user/:id">
                <Route index element={<Profile />} />
                <Route path="favorites" element={<Profile isFavoritePage />} />
                <Route path="edit-profile">
                  <Route index element={<EditProfileScreen />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                </Route>
              </Route>
              <Route path="edit-task/:slug" element={<CreateTask />} />
              <Route path="create-task" element={<CreateTask />} />
            </Route>
          </Route>
          <Route path="task-detail/:slug">
            <Route index element={<TaskDetail />} />
            <Route path="user/:id">
              <Route index element={<Profile />} />
              <Route path="favorites" element={<Profile isFavoritePage />} />
              <Route path="edit-profile">
                <Route index element={<EditProfileScreen />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
            </Route>
            <Route path="edit-task/:slug" element={<CreateTask />} />
            <Route path="create-task" element={<CreateTask />} />
          </Route>
        </Route>
        <Route path="/users">
          <Route index element={<UsersScreen query={query} />} />
          <Route path="user/:id">
            <Route index element={<Profile />} />
            <Route path="favorites" element={<Profile isFavoritePage />} />
            <Route path="edit-profile">
              <Route index element={<EditProfileScreen />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>
        </Route>
        <Route path="/project-detail/:slug">
          <Route index element={<ProjectDetail />} />
          <Route path="user/:id">
            <Route index element={<Profile />} />
            <Route path="favorites" element={<Profile isFavoritePage />} />
            <Route path="edit-profile">
              <Route index element={<EditProfileScreen />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>
          <Route path="edit-project/:slug" element={<CreateProject />} />

          <Route path="edit-project/:slug">
            <Route index element={<CreateProject />} />
            <Route path="user/:id">
              <Route index element={<Profile />} />
              <Route path="favorites" element={<Profile isFavoritePage />} />
              <Route path="edit-profile">
                <Route index element={<EditProfileScreen />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
            </Route>
          </Route>

          <Route path="edit-project/:slug">
            <Route index element={<CreateProject />} />
            <Route path="user/:id">
              <Route index element={<Profile />} />
              <Route path="favorites" element={<Profile isFavoritePage />} />
              <Route path="edit-profile">
                <Route index element={<EditProfileScreen />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
            </Route>
          </Route>

          <Route path="project-tasks/:slug">
            <Route index element={<TasksScreen query={query} />} />
            <Route path="user/:id">
              <Route index element={<Profile />} />
              <Route path="favorites" element={<Profile isFavoritePage />} />
              <Route path="edit-profile">
                <Route index element={<EditProfileScreen />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
            </Route>
            <Route path="create-task" element={<CreateTask />} />
            <Route path="board/:slug">
              <Route index element={<BoardScreen />} />
              <Route path="create-task" element={<CreateTask />} />
              <Route path="task-detail/:slug">
                <Route index element={<TaskDetail />} />
                <Route path="user/:id">
                  <Route index element={<Profile />} />
                  <Route path="favorites" element={<Profile isFavoritePage />} />
                  <Route path="edit-profile">
                    <Route index element={<EditProfileScreen />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                  </Route>
                </Route>
                <Route path="edit-task/:slug" element={<CreateTask />} />
                <Route path="create-task" element={<CreateTask />} />
              </Route>
            </Route>
            <Route path="task-detail/:slug">
              <Route index element={<TaskDetail />} />
              <Route path="user/:id">
                <Route index element={<Profile />} />
                <Route path="favorites" element={<Profile isFavoritePage />} />
                <Route path="edit-profile">
                  <Route index element={<EditProfileScreen />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                </Route>
              </Route>
              <Route path="edit-task/:slug" element={<CreateTask />} />
              <Route path="create-task" element={<CreateTask />} />
            </Route>
          </Route>
        </Route>
        <Route path="/create-project" element={<CreateProject />} />

        <Route path="/news-feed">
          <Route index element={<NewsFeed />} />
          <Route path="user/:id">
            <Route index element={<Profile />} />
            <Route path="favorites" element={<Profile isFavoritePage />} />
            <Route path="edit-profile">
              <Route index element={<EditProfileScreen />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>
          <Route path="create-article" element={<CreateArticle />} />
          <Route path="article/:slug">
            <Route
              index
              element={
                <Suspense
                  fallback={
                    <p className="d-flex justify-content-center w-100 position-absolute flex-column align-items-center top-50">
                      <Spinner />
                    </p>
                  }
                >
                  <Article />
                </Suspense>
              }
            />
            <Route path="edit-article/:slug" element={<CreateArticle />} />
            <Route path="user/:id">
              <Route index element={<Profile />} />
              <Route path="favorites" element={<Profile isFavoritePage />} />
              <Route path="edit-profile">
                <Route index element={<EditProfileScreen />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="/create-article" element={<CreateArticle />} />
        <Route path="/settings">
          <Route index element={<SettingsScreen />} />
          <Route path="reset-password">
            <Route index element={<ResetPassword />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}
RoutesComponent.propTypes = {
  query: PropTypes.string,
};
RoutesComponent.defaultProps = {
  query: "",
};

export default RoutesComponent;

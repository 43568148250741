import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Toaster from "../../common/Toaster";
import { formatDateShortTime, generateKey } from "../../common/utils";
import { getUser, updateUser } from "../../features/auth/authSlice";
import { deleteUser } from "../../reducers/common";
import { getAllCurrencies } from "../../reducers/currencies";
import { inviteUser } from "../../reducers/user";
import { getAllUsers } from "../../reducers/users";
import "../style.css";
import "./usersScreen.css";
import CreateButton from "../../utils/createButton";
import { getAllRoles } from "../../reducers/roles";
import useWindowSize from "../../hooks/useWindowSize";

function UsersScreen({ query }) {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const { deletedUser } = useSelector((state) => state.common);
  const [userEmail, setUserEmail] = useState("");
  const [showSelectorEdit, setShowSelectorEdit] = useState(false);
  const [showRateEdit, setShowRateEdit] = useState(false);
  const [userId, setUserId] = useState();
  const [role, setRole] = useState();
  const [rate, setRate] = useState();
  const [updatedUsers, setUpdatedUsers] = useState(users);
  const [inviteUserResult, setInviteUserResult] = useState();
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [currency, setCurrency] = useState();
  const { width } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    const getCurrencies = async () => {
      const result = await dispatch(getAllCurrencies());
      setAllCurrencies(result.payload.currencies);
    };
    getCurrencies();
  }, []);

  useEffect(() => {
    const getRoles = async () => {
      const result = await dispatch(getAllRoles());
      setAllRoles(result.payload.roles);
    };
    getRoles();
  }, []);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    setUpdatedUsers(users);
  }, [users]);

  const editUser = async () => {
    const user = {
      roleId: role,
      rate,
      currencyId: currency,
      id: userId,
    };
    await dispatch(updateUser(user));
    dispatch(getUser());
    dispatch(getAllUsers());
    setShowSelectorEdit(false);
    setShowRateEdit(false);
  };

  const changeRole = (event) => {
    setRole(event.target.value);
  };

  const changeEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const changeHourlyRate = (event) => {
    setRate(event.target.value);
  };

  const changeCurrency = (e) => {
    setCurrency(+e.target.value);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    const body = {
      roleId: role,
      email: userEmail,
    };
    const result = await dispatch(inviteUser(body));
    setInviteUserResult(result?.type.includes("fulfilled"));

    setUserEmail("");
    setRole("");
  };

  const removeUser = (id) => {
    dispatch(deleteUser(id));
    const updatedUsersList = updatedUsers.filter((user) => user.id !== id);
    setUpdatedUsers(updatedUsersList);
  };

  return (
    <div className="container" style={{ backgroundColor: "#f7f7f7" }}>
      <Toaster message={inviteUserResult ? "Email sent succesfully." : null} type="success" />
      <Toaster message={deletedUser ? "The user has been successfully deleted" : null} type="success" />

      <div className="d-flex mx-0 mx-md-5 mx-lg-0 d-flex  align-items-center">
        <ul className="nav nav-pills outline-active w-100 ">
          <button type="button" className="nav-link active" style={{ padding: "20px 0" }}>
            All <span className="badge badge-secondary bg-secondary rounded-circle">{updatedUsers?.length}</span>
          </button>
        </ul>
        <button
          className="btn blue_button ms-3 mt-sm-3 mt-md-3 mt-3 mt-lg-0"
          type="submit"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          style={{ backgroundColor: "#13B7FF" }}
        >
          Invite user
        </button>
        <div className="modal" id="myModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Invite user</h4>
              </div>
              <div className="mb-3 modal-body">
                <label htmlFor="email-text" className="col-form-label w-100">
                  Enter user email:
                  <input className="form-control" id="email-text" value={userEmail} onChange={changeEmail} />
                </label>

                <label className="col-form-label w-100" htmlFor="role">
                  Enter user role:
                  <select
                    id="role"
                    className="form-select"
                    onChange={(e) => {
                      changeRole(e);
                    }}
                    value={role}
                  >
                    <option type="others">Select role</option>
                    {allRoles.map((opts) => (
                      <option value={opts.id} key={generateKey()}>
                        {opts.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="modal-footer d-flex">
                <CreateButton
                  onSubmit={submitForm}
                  disabled={userEmail?.replace(/\s/g, "").length === 0 || role?.replace(/\s/g, "").length === undefined}
                  modal
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          {width > 991 ? (
            <ul className="nav nav-pills mt-2 w-100 border-bottom row" style={{ marginLeft: "1px" }}>
              <li className="nav-item col-3 p-0 ms-3 mb-3 mt-2">Account</li>
              <li className="nav-item col-2 p-0 ms-3 mb-3 mt-2">Invited By</li>
              <li className="nav-item col-2 p-0 ms-2 mb-3 mt-2">Role</li>
              <li className="nav-item col-2 p-0 mb-3 mt-2 ms-0">Hourly rate</li>
              <li className="nav-item col-1 p-0 ms-0 mb-3 mt-2">Activity</li>
            </ul>
          ) : null}
          {updatedUsers &&
            [...updatedUsers]
              ?.sort((a, b) => {
                if (a.username < b.username) return -1;
                if (a.username > b.username) return 1;
                return 0;
              })
              ?.filter((user) => user.username.toLowerCase().includes(query.toLowerCase()))
              .map((user) => (
                <div className="d-flex" key={user.id}>
                  {width <= 991 ? (
                    <ul className="nav nav-pills mt-3 d-block border-end h-75">
                      <li className="p-0 mt-2 pe-2">Account</li>
                      <li className="p-0 mt-5 pt-1">Invited By</li>
                      <li className="p-0 mt-4">Role</li>
                      <li className="p-0 mt-4 pe-2">Hourly rate</li>
                      <li className="p-0 mt-4">Activity</li>
                    </ul>
                  ) : null}
                  <div className="d-sm-block d-md-block d-lg-flex py-4 border-bottom align-items-center w-100">
                    <div className="d-flex col-sm-12 col-md-12 col-lg-3 col-xs-10">
                      <Link to={`${location.pathname}/user/${user.id}`} className="nav-link">
                        {user?.image ? (
                          <img src={user.image} width="50px" height="50px" className="rounded-circle mt-1" alt="" />
                        ) : (
                          <Icon style={{ fontSize: "55px", color: "#6C757D", marginTop: "4px" }}>account_circle</Icon>
                        )}
                      </Link>
                      {width <= 360 ? (
                        <div className="mx-3 mt-1" style={{ width: "170px", height: "68px" }}>
                          <Link to={`${location.pathname}/user/${user.id}`} className="nav-link">
                            <h6
                              className="unlimitedText"
                              style={{
                                textOverflow: "ellipsis",
                              }}
                            >
                              {user.username}
                            </h6>
                          </Link>
                          <p
                            className="text-secondary unlimitedText"
                            style={{
                              textOverflow: "ellipsis",
                            }}
                          >
                            {user.email}
                          </p>
                        </div>
                      ) : (
                        <div className="mx-3 mt-1" style={{ width: "200px", height: "68px" }}>
                          <Link to={`${location.pathname}/user/${user.id}`} className="nav-link">
                            <h6
                              className="unlimitedtext"
                              style={{
                                textOverflow: "ellipsis",
                              }}
                            >
                              {user.username}
                            </h6>
                          </Link>
                          <p
                            className="text-secondary unlimitedtext"
                            style={{
                              textOverflow: "ellipsis",
                            }}
                          >
                            {user.email}
                          </p>
                        </div>
                      )}
                    </div>
                    {user.createdBy === null ? (
                      <p className="col-sm-12 col-md-12 col-lg-2 col-xs-12 ms-lg-2 ms-lg-2 mb-lg-4 ps-lg-4 pb-4 pt-1" />
                    ) : (
                      <p className="col-sm-12 col-md-12 col-lg-2 col-xs-12 ms-lg-2 ms-lg-2 mb-lg-4 ps-lg-4 pt-1">
                        {user.createdBy}
                      </p>
                    )}
                    <div className="col-sm-11 col-md-11 col-lg-2 col-xs-11 ms-xs-3 ms-sm-3 ms-md-3 ms-3 ms-lg-0 p-0 mt-sm-5 mt-md-3 mt-5 mt-lg-0">
                      {showSelectorEdit && userId === user.id ? (
                        <div className="d-flex">
                          <select
                            className="form-select "
                            aria-label="Default select example"
                            style={{ width: "125px" }}
                            onChange={(e) => {
                              changeRole(e, user.id);
                            }}
                            value={role}
                          >
                            {allRoles.map((opts) => (
                              <option value={opts.id} key={generateKey()}>
                                {opts.name}
                              </option>
                            ))}
                          </select>
                          <button
                            className="btn rounded-circle text-success position-absolute"
                            style={{ marginLeft: "120px" }}
                            type="submit"
                            onClick={() => editUser()}
                          >
                            <Icon style={{ fontSize: "18px", marginBottom: "-3px" }}>check_circle</Icon>
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex ps-xs-3">
                          <select
                            className="form-select "
                            aria-label="Default select example"
                            style={{ width: "125px" }}
                            onClick={() => {
                              setRole(user?.role?.id);
                              setShowSelectorEdit(true);
                              setUserId(user.id);
                            }}
                            value={user?.role?.id || ""}
                            readOnly
                          >
                            {allRoles.map((opts) => (
                              <option value={opts.id} key={generateKey()}>
                                {opts.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                    <div className="p-0 mt-sm-3 mt-md-3 mt-3 mt-lg-0 ms-xs-3 ms-sm-3 ms-md-3 ms-lg-0 ms-3 col-sm-12 col-md-12 col-lg-2 col-xs-12">
                      <div className="input-group d-flex">
                        {showRateEdit && userId === user.id ? (
                          <div className="d-flex">
                            <select
                              className="form-select input-group-addon bg-light"
                              aria-label="Default select example"
                              style={{ width: "82px" }}
                              onChange={(e) => {
                                changeCurrency(e, user.id);
                                setShowRateEdit(true);
                              }}
                              value={currency}
                            >
                              {allCurrencies.map((opts) => (
                                <option value={opts.id} key={generateKey()}>
                                  {opts.code}
                                </option>
                              ))}
                            </select>
                            <input
                              className="form-control bg-white text-end"
                              style={{ width: "105px" }}
                              type="number"
                              data-testid="rate-input"
                              placeholder="0"
                              min="0"
                              onChange={(e) => {
                                changeHourlyRate(e, user.id);
                              }}
                              value={rate}
                            />
                            <button
                              className="btn rounded-circle text-success position-absolute"
                              style={{ marginLeft: "180px" }}
                              type="submit"
                              onClick={() => editUser()}
                            >
                              <Icon style={{ fontSize: "18px", marginBottom: "-3px" }}>check_circle</Icon>
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex">
                            <select
                              className="form-select input-group-addon bg-light"
                              aria-label="Default select example"
                              style={{ width: "82px" }}
                              onClick={() => {
                                setCurrency(user?.currency?.id);
                                setShowRateEdit(true);
                                setRate(user.rate);
                                setUserId(user.id);
                              }}
                              value={user?.currency?.id}
                              readOnly
                            >
                              {allCurrencies.map((opts) => (
                                <option value={opts.id} key={generateKey()}>
                                  {opts.code}
                                </option>
                              ))}
                            </select>
                            <input
                              className="form-control bg-white text-end"
                              style={{ width: "105px" }}
                              type="number"
                              data-testid="rate-input"
                              placeholder="0"
                              min="0"
                              onClick={() => {
                                setRate(user.rate);
                                setCurrency(user?.currency?.id);
                                setShowRateEdit(true);
                                setUserId(user.id);
                              }}
                              value={user.rate}
                              readOnly
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex col-sm-12 col-md-12 col-lg-3 col-xs-12 align-items-center">
                      <div
                        className="ms-xs-0 ms-sm-0 ms-xs-0 ms-md-0 ms-lg-3 mt-sm-4 mt-md-4 mt-4 mt-lg-0 pt-sm-2 pt-md-2 pt-2 pt-lg-0"
                        style={width <= 400 ? { width: "230px" } : { width: "250px" }}
                      >
                        {user.createdAt === null ? (
                          <h6>User created:</h6>
                        ) : (
                          <h6>
                            User created:
                            <span className="text-secondary fw-normal ps-1">
                              {formatDateShortTime(new Date(user.createdAt))}
                            </span>
                          </h6>
                        )}
                        {user.grantedDate === null ? (
                          <h6>Access granted:</h6>
                        ) : (
                          <h6>
                            Access granted:
                            <span className="text-secondary fw-normal ps-1">
                              {formatDateShortTime(new Date(user.grantedDate))}
                            </span>
                          </h6>
                        )}
                        <h6>Last activity:</h6>
                      </div>

                      <div className="dropdown">
                        <button
                          type="button"
                          className="nav-link dropdown border-0"
                          id="navbarNotification"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ background: "#f7f7f7" }}
                        >
                          <Icon
                            style={{
                              fontSize: "18px",
                              color: "#6C757D",
                            }}
                          >
                            more_vert
                          </Icon>
                        </button>
                        <ul className="dropdown-menu position-absolute" aria-labelledby="navbarDropdownMenuLink">
                          <li style={{ cursor: "pointer" }}>
                            <button type="submit" className="dropdown-item" onClick={() => removeUser(user.id)}>
                              Remove
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

UsersScreen.propTypes = {
  query: PropTypes.string,
};
UsersScreen.defaultProps = {
  query: "",
};

export default UsersScreen;

const dev = {
  apiUrl: "https://dev.api.pm.northweb.solutions",
  traceApiUrl: "https://dev.api.trace.northweb.solutions",
};

const staging = {
  apiUrl: "https://api.pm.northweb.solutions",
  traceApiUrl: "https://staging.api.trace.northweb.solutions",
};

const eden = {
  apiUrl: "https://eden.api.pm.northweb.solutions",
  traceApiUrl: "https://eden.api.trace.northweb.solutions",
};

const ember = {
  apiUrl: "https://ember.api.pm.northweb.solutions",
  traceApiUrl: "https://ember.api.trace.northweb.solutions",
};

const halo = {
  apiUrl: "https://halo.api.pm.northweb.solutions",
  traceApiUrl: "https://halo.api.trace.northweb.solutions",
};

const nexus = {
  apiUrl: "https://nexus.api.pm.northweb.solutions",
  traceApiUrl: "https://nexus.api.trace.northweb.solutions",
};

const cross = {
  apiUrl: "https://cross.api.pm.northweb.solutions",
  traceApiUrl: "https://cross.api.trace.northweb.solutions",
};

const fresh = {
  apiUrl: "https://fresh.api.pm.northweb.solutions",
  traceApiUrl: "https://fresh.api.trace.northweb.solutions",
};

const config = {
  dev,
  staging,
  eden,
  ember,
  halo,
  nexus,
  cross,
  fresh,
};

const appStage = window.location.host.split(".")[0];
const configAppStage = config[appStage] || dev;

export default {
  ...configAppStage,
};

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  errors: false,
};

const errorsSlice = createSlice({
  name: "errors",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.includes("/rejected"),
      (stateErrors, action) => {
        const state = stateErrors;
        state.errors = action.error.message;
      }
    );
  },
  reducers: { clearErrors: () => initialState },
});
export const { clearErrors } = errorsSlice.actions;
export default errorsSlice.reducer;

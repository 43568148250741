import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { Progress, formatDateProjectCard } from "../../common/utils";
import Members from "../../common/Members";
import { deleteUpload } from "../../reducers/common";
import { uploadFile } from "../../reducers/project";
import { getAllProjects } from "../../reducers/projectList";
import { selectUser } from "../../features/auth/authSlice";
import "../style.css";

function ProjectCard({ project, number }) {
  const [tasks, setTasks] = useState();
  const [image, setImage] = useState([]);
  const [isSelectedImage, setIsSelectedImage] = useState(false);
  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    setTasks(project?.Tasks);
  }, [project]);

  useEffect(() => {
    const isImage = project?.Attachments.filter((attachment) => attachment.isCover === true);
    setImage(isImage);
  }, []);

  const isTasks = tasks?.filter((task) => task.relatedTo === null);

  async function dragNdrop(event) {
    const filename = URL.createObjectURL(event.currentTarget.files[0]);
    const formData = new FormData();
    formData.append("image", event.currentTarget.files[0]);
    formData.append("isCover", true);
    const result = await dispatch(uploadFile({ file: formData, projectId: project.id }));
    if (result.meta.requestStatus === "fulfilled" && project?.Attachments.length >= 1) {
      dispatch(deleteUpload(project?.Attachments[0].id));
    }
    dispatch(getAllProjects());
    const preview = document.getElementById(project.id);
    const previewImg = document.createElement("img");
    previewImg.setAttribute("src", filename);
    previewImg.setAttribute("height", 145);
    previewImg.setAttribute("width", 273.4);
    previewImg.setAttribute("style", "border-radius:5px 5px 0px 0px");
    preview.innerHTML = "";
    preview.appendChild(previewImg);
    setIsSelectedImage(true);
  }

  // 2. drag

  const drag = () => {
    // while dragging it will fire this function that will add the following className
    document.getElementById(project.id);
  };

  // 3. finally the drop()
  const drop = () => {
    document.getElementById(project.id);
  };

  // we are done...

  return (
    <div className="mb-3 d-flex flex-column position-relative">
      {" "}
      <div
        role="presentation"
        className={`card p-0 mb-0 mx-md-4 mx-xxl-4 mx-xl-${number < 4 ? 4 : 0} me-xl-${number < 4 ? 0 : 1}`}
        style={{
          width: "275px",
          height: "480px",
        }}
      >
        <div
          className={
            (project.status === "Finished" &&
              " bg-success btn-sm position-absolute px-2 mt-3 mx-2 fw-bold text-center") ||
            (project.status === "Ongoing" &&
              " bg-secondary btn-sm position-absolute px-2 mt-3 mx-2 text-white fw-bold text-center") ||
            (project.status === "Planned" &&
              " bg-warning btn-sm position-absolute px-2 mt-3 mx-2 text-white fw-bold text-center")
          }
          style={{
            height: "18px",
            fontSize: "12px",
            padding: "initial",
            zIndex: 2,
          }}
        >
          {project.status}
        </div>
        <div className="image" style={{ height: "145px", margin: 0 }}>
          {image?.length > 0 ? (
            image?.map((file) => (
              <Link to={`/project-detail/${project.id}`} className="nav-link">
                <img src={file.image_url} alt="" className="rounded-top w-100 position-absolute" height="145" />
              </Link>
            ))
          ) : (
            <div>
              <span
                className="d-flex justify-content-center align-items-center position-relative rounded-top"
                style={
                  isSelectedImage
                    ? {
                        minHeight: "145px",
                      }
                    : { border: "2px dashed #ccc", minHeight: "145px" }
                }
              >
                <div id={project?.id} className="text-center">
                  <Icon
                    style={{
                      fontSize: "62px",
                      color: "#6C757D",
                    }}
                  >
                    cloud_upload
                  </Icon>

                  <p className="font-weight-bold text-muted h5">Drop images here or click to upload.</p>
                </div>
                {currentUser?.role?.name !== "manager" ? (
                  <Link to={`/project-detail/${project.id}`} className="nav-link h-100 w-100 position-absolute" />
                ) : (
                  <input
                    className="h-100 w-100 position-absolute rounded-top opacity-0 top-0 left-0"
                    type="file"
                    onChange={(event) => dragNdrop(event)}
                    onDragOver={drag}
                    onDrop={drop}
                    name={project.title}
                    id={project.id}
                  />
                )}
              </span>
            </div>
          )}
        </div>

        <div className="card-body px-3" style={{ zIndex: 1 }}>
          <Link to={`/project-detail/${project.id}`} className="nav-link">
            <h5 className="card-title fw-bold m-0">{project?.title}</h5>
            <p
              dangerouslySetInnerHTML={{ __html: project?.description }}
              className="card-text mt-2 mb-2"
              style={{
                fontSize: "13px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4,
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "76px",
                marginBottom: 0,
              }}
            />{" "}
          </Link>
          <div className="d-flex align-items-center fw-bold">
            <Icon style={{ fontSize: "20px", color: "black", marginRight: "8px" }}>menu</Icon>
            <span>
              <Link
                to={`/project-tasks/${project.id}`}
                style={{ textDecoration: "none", color: "black", marginRight: "4px", fontSize: "16px" }}
              >
                {!isTasks?.length ? "0 Tasks" : `${isTasks?.length} Tasks`}
              </Link>
            </span>
          </div>
          <div className="d-flex align-items-center fw-bold mt-2">
            <Icon style={{ fontSize: "20px", color: "black", marginRight: "8px" }}>date_range</Icon>
            <p className="m-0" style={{ fontSize: "16px" }}>
              Deadline {formatDateProjectCard(new Date(project?.endDate))}
            </p>
          </div>

          <div>
            <div className="images align-items-center mt-2 d-flex">
              <Icon style={{ fontSize: "20px", color: "black", marginRight: "8px" }}>people</Icon>
              <Link to={`/project-detail/${project.id}`} className="nav-link">
                <Members members={project?.members} height="mt-0" />
              </Link>
            </div>
          </div>
          <div className="d-flex align-items-center fw-bold mt-1">
            <Icon style={{ fontSize: "20px", color: "black", marginRight: "8px" }}>question_answer</Icon>
            <p className="m-0" style={{ fontSize: "16px" }}>
              {!project?.comments?.length ? "0 Comments" : `${project?.comments?.length} Comments`}
            </p>
          </div>
        </div>

        <div className="position-absolute" style={{ bottom: 0 }}>
          <Progress tasks={project?.Tasks} />
        </div>
      </div>
    </div>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool, PropTypes.array])
  ).isRequired,
  number: PropTypes.number.isRequired,
};

export default ProjectCard;

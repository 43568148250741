import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ProjectList from "../Project/ProjectList";
import { changeProjectTab, getAllProjects } from "../../reducers/projectList";
import { selectUser } from "../../features/auth/authSlice";
import TagsSidebar from "../../features/tags/TagsSidebar";
import "./mainView.css";

/**
 * @example
 * <MainView />
 */
function MainView({ query }) {
  const { projects: initialProjects } = useSelector((state) => state.projects);
  const [projects, setProjects] = useState([]);
  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    setProjects(initialProjects);
  }, [initialProjects]);

  useEffect(() => {
    dispatch(getAllProjects());
  }, []);

  const dispatchChangeTab = () => {
    dispatch(changeProjectTab("all"));
  };

  return (
    <div>
      <div className="feed-toggle" id="feed-toggle">
        {currentUser?.role?.name === "manager" && initialProjects?.length === 0 && projects?.length === 0 ? (
          <div
            className="d-flex justify-content-center flex-column align-items-center"
            style={{
              marginTop: "150px",
            }}
          >
            <div
              style={{
                color: "#2E475D",
                border: "1px solid #0000004d",
                borderRadius: "10px",
                padding: "30px",
                textAlign: "center",
              }}
            >
              <h1>Let&apos;s start by creating first project</h1>
              <div className="col d-flex align-items-center justify-content-center pt-2">
                <Link to="/create-project" className="nav-link">
                  <button type="button" className="btn green_button my-2 mx-3" style={{ margin: 0 }}>
                    Create project
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <ul
            className="nav nav-pills outline-active mb-3 w-100 "
            style={{
              alignItems: "center",
            }}
          >
            <li className="nav-item">
              <button
                type="button"
                className="nav-link active"
                onClick={dispatchChangeTab}
                style={{ padding: "8px 0", marginRight: "16px" }}
              >
                Projects
              </button>
            </li>
            <div className="col d-flex justify-content-end">
              <TagsSidebar setProjects={setProjects} projects={projects} />
            </div>
          </ul>
        )}
      </div>
      <ProjectList projects={projects} query={query} />
    </div>
  );
}

MainView.propTypes = {
  query: PropTypes.string,
};

MainView.defaultProps = {
  query: "",
};

export default memo(MainView);

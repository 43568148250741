import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { Modal, formatDateNextTime, generateKey } from "../../common/utils";
import Members from "../../common/Members";
import useWindowSize from "../../hooks/useWindowSize";
import { selectIsAuthenticated } from "../../features/auth/authSlice";
import { deleteTask } from "../../reducers/common";
import { getProject } from "../../reducers/project";
import { getTask, updateTask, taskPageUnloaded } from "../../reducers/task";
import useTimeout from "../../hooks/useTimeout";
import "./task.css";

function TasksScreen({ query }) {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state?.project);
  const location = useLocation();
  const { task } = useSelector((state) => state.task);
  const [tasks, setTasks] = useState();
  const [isClicked, setIsClicked] = useState();
  const [isCheckboxClicked, setIsCheckboxClicked] = useState();
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const { slug } = useParams();
  const isAuthenticaded = useSelector(selectIsAuthenticated);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [isPopUpBlocked, setIsPopUpBlocked] = useState(false);
  const [response, setResponse] = useState(false);
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const [timeout] = useTimeout(() => {
    if (response) {
      dispatch(getProject(slug));
      setResponse(false);
    } else setIsPopUpBlocked(false);
  }, 50);

  timeout();

  useEffect(() => {
    dispatch(getProject(slug));
  }, []);

  useEffect(() => {
    if (isPopUpBlocked) alert(`Please accept the pop ups to be able to open more tabs!`);
  }, [isPopUpBlocked]);

  useEffect(() => {
    setTasks(project?.Tasks);
  }, [project]);

  const handleClick = (e, istask) => {
    setIsClicked(e.type);
    setIsTaskCompleted(istask?.status === "DONE");
    dispatch(getTask(istask?.id));
  };

  const clearTaskStore = () => {
    dispatch(taskPageUnloaded());
  };

  const openLink = () => {
    let newWin = "";
    if (selectedTasks.length === 1) {
      navigate(`${location.pathname}/task-detail/${task.id}`);
    } else
      selectedTasks.forEach((url) => {
        newWin = window.open(url, "_blank");
      });
    if (!newWin || newWin.closed || typeof newWin.closed === "undefined") {
      setIsPopUpBlocked(true);
    }
  };

  function handleCheckBoxClick(e) {
    if (e.target.checked) {
      setIsCheckboxClicked(true);
      const updatedList = [...selectedTasks];
      updatedList.push(`${location.pathname}/task-detail/${e.target.id}`);
      setSelectedTasks(updatedList);
    } else {
      setIsCheckboxClicked(false);
      const updatedList = selectedTasks.filter((a) => !a.includes(e.target.id));
      setSelectedTasks(updatedList);
    }
  }

  const handleDeleteItem = () => {
    dispatch(deleteTask(task.id));
    const updateTasks = tasks.filter((taskId) => taskId.id !== task.id);
    setTasks(updateTasks);
    setIsClicked();
  };

  const handleComplete = () => {
    const payload = {
      slug: task.id,
      status: isTaskCompleted ? "TO DO" : "DONE",
    };
    setIsTaskCompleted(!isTaskCompleted === true);

    dispatch(updateTask(payload)).then((responsePayload) => setResponse(responsePayload));
  };

  return (
    <div className="editor-page">
      <div className="container page">
        <div className="row pe-0" style={{ display: tasks === undefined ? "none" : "block" }}>
          {tasks?.length > 0 ? (
            <div className="d-flex mt-3 pt-3 justify-content-between flex-wrap align-items-center">
              <div className="d-flex col-lg-6 col-md-5 col-6 justify-content-sm-start justify-content-lg-start justify-content-between px-0">
                <h4 className="text-secondary me-2">Tasks</h4>
                {tasks?.length > 0 ? (
                  <div className="mx-0 mx-md-5 mx-lg-5 d-flex flex-column align-items-center">
                    <Link
                      to={`${location.pathname}/create-task`}
                      state={{ id: project?.id }}
                      style={{ textDecoration: "none" }}
                      onClick={clearTaskStore}
                    >
                      <button type="button" className="btn green_button" style={{ margin: 0 }}>
                        Add Task
                      </button>
                    </Link>
                  </div>
                ) : null}
                {(isCheckboxClicked || selectedTasks.length > 0) && (
                  <div>
                    <button
                      type="button"
                      className="btn blue_button mx-2"
                      onClick={openLink}
                      style={{ minWidth: "88.6px" }}
                    >
                      Open
                    </button>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center mt-md-0 mt-lg-0 col-lg-6 col-md-6 justify-content-sm-end justify-content-between justify-content-lg-end px-0">
                <Link to={`${location.pathname}/board/${slug}`} style={{ textDecoration: "none", color: "black" }}>
                  <Icon style={{ fontSize: "35px", color: "#6C757D", marginTop: "4px" }}>assignment</Icon>
                </Link>
              </div>
            </div>
          ) : (
            <div
              className="col-md-6 offset-md-3 col-xs-10 offset-1 rounded border"
              style={{
                marginTop: "95px",
              }}
            >
              <fieldset className="form-group m-0">
                <h1 className="text-xs-center mt-3 pt-3" style={{ fontSize: "30px" }}>
                  Let&apos;s start by creating first task
                </h1>
                <div className="col d-flex align-items-center justify-content-center my-3 pb-3">
                  <Link
                    to={`${location.pathname}/create-task`}
                    state={{ id: project?.id }}
                    style={{ textDecoration: "none" }}
                    onClick={clearTaskStore}
                  >
                    <button type="button" className="btn green_button my-2 mx-3" style={{ margin: 0 }}>
                      Add Task
                    </button>
                  </Link>
                </div>
              </fieldset>
            </div>
          )}

          <div className={isClicked === "click" ? "d-flex mt-5 pe-0 row" : "mt-5 pe-0 row"} id="box">
            <div className={isClicked === "click" ? "col-sm-12 col-md-7 col-lg-7 col-xs-12 pe-0" : "w-100 pe-0"}>
              {tasks
                ?.filter(
                  (isTask) => isTask.title.toLowerCase().includes(query.toLowerCase()) && isTask.relatedTo == null
                )
                .map((isTask) => (
                  <div className="mt-2" style={{ height: "60px" }} key={isTask.id}>
                    <a
                      className="btn btn-white border align-items-center d-flex justify-content-between h-100 taskPreview"
                      data-toggle="collapse"
                      href="# "
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      onClick={(e) => {
                        handleClick(e, isTask);
                      }}
                      data-testid="task-screen"
                      onBlur={(e) => {
                        if (!e.relatedTarget) {
                          e.target.focus();
                        }
                      }}
                    >
                      <div className="d-flex w-50">
                        <div className="form-check">
                          <input
                            className="form-check-input mb-1"
                            type="checkbox"
                            id={isTask.id}
                            onChange={handleCheckBoxClick}
                          />
                        </div>
                        <p
                          className="mb-1 "
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {isTask?.title}
                        </p>
                      </div>

                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                          <div
                            className="members ms-2"
                            data-title={`${isTask.assignees.length} ${
                              isTask.assignees.length === 1 ? "Member" : "Members"
                            }`}
                          >
                            <Icon className="black" style={{ fontSize: "30px", marginBottom: "-6px" }}>
                              account_circle
                            </Icon>
                          </div>
                        </div>
                        <div
                          className={
                            (isTask?.status === "TO DO" &&
                              " bg-secondary btn-sm  px-1 text-white fw-bold text-center mx-2") ||
                            (isTask?.status === "IN PROGRESS" &&
                              "bg-warning btn-sm  px-1 text-white fw-bold text-center mx-2") ||
                            (isTask?.status === "IN TESTING" &&
                              "bg-danger btn-sm  px-1 text-white fw-bold text-center mx-2") ||
                            (isTask?.status === "DONE" && "bg-success btn-sm  px-1 text-white fw-bold text-center mx-2")
                          }
                          style={{
                            display: "flex",
                            width: "auto",
                            height: "20px",
                            fontSize: "12px",
                            padding: "initial",
                            paddingTop: "1px",
                          }}
                        >
                          {isTask?.status}
                        </div>

                        <div
                          className="d-flex mx-2 border-0 d-flex align-items-center justify-content-center"
                          style={
                            (isTask?.priority === "High" && {
                              background: "#dc3545",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (isTask?.priority === "Medium" && {
                              background: "#ffc107",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (isTask?.priority === "Low" && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            }) ||
                            (isTask?.priority !== ("High", "Medium", "Low") && {
                              background: "#198754",
                              height: "20px",
                              width: "20px",
                              borderRadius: "0.2rem",
                            })
                          }
                        />
                      </div>
                    </a>
                  </div>
                ))}
            </div>
            <div
              className={
                isClicked === "click" ? "collapse.show col-sm-12 col-md-5 col-lg-5 col-xs-12 mt-2 pe-0" : "collapse"
              }
            >
              <div className="card card-body">
                <div className="d-flex justify-content-between">
                  <div className="form-check border-bottom mt-2">
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Mark as completed
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        style={{ opacity: 1 }}
                        checked={isTaskCompleted}
                        onChange={handleComplete}
                      />
                    </label>
                  </div>
                  {isAuthenticaded && (
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn btn-lg btn-link mod-options py-0 px-0"
                        name="delete"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <Icon style={{ fontSize: "22px", marginBottom: "-4px" }} className="delete_icon">
                          delete
                        </Icon>
                        <span className="sr-only">Delete task</span>
                      </button>
                      <Modal handleDeleteItem={handleDeleteItem} id="exampleModal" fontSize={15} />
                    </div>
                  )}
                </div>
                <h5 className="my-3 pb-2">{task?.title}</h5>
                <h6 className="card-subtitle text-muted mb-2">
                  {task?.assignees.length !== 0 ? "Assigned to" : "Unassigned"}
                </h6>
                <div className="d-flex mb-2">
                  <Members isTask members={task?.assignees} height="mt-0" width={width} />
                </div>
                <div className="d-flex justify-content-between">
                  <h6 className="card-subtitle text-muted mb-3 mt-2">Story point estimate</h6>
                  <p className="card-text mb-3 mt-2">{!task?.estimation ? "None" : task?.estimation}</p>
                </div>
                <div>
                  {" "}
                  <div className="d-flex justify-content-between">
                    <h6 className="card-subtitle text-muted mb-2 mt-2">Sub-tasks</h6>
                    <p className="card-text mb-2 mt-2">{task?.subTasks?.length === 0 && "None"}</p>
                  </div>
                  {tasks
                    ?.filter((subtask) => subtask.relatedTo === task?.id)
                    .map((subTask) => (
                      <div className="card border-0 m-0 mb-1 ">
                        <div className="form-check me-2 my-1 ps-2" key={generateKey()}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label mt-1"
                              htmlFor="exampleCheck1"
                              style={{
                                color: "#8c8c8c",
                              }}
                            >
                              <Link
                                to={`${location.pathname}/task-detail/${subTask?.id}`}
                                state={{ id: project?.id, projectMembers: project?.members }}
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                {subTask.title}
                              </Link>
                            </label>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex">
                                {subTask?.assignees.map((user) => (
                                  <div className="image ms-2">
                                    <a href="# " className="username" data-title={user?.username}>
                                      <div className="image me-1" key={generateKey()}>
                                        {user?.image ? (
                                          <img
                                            src={user?.image}
                                            alt=""
                                            width="24"
                                            height="24"
                                            className="rounded-circle mb-2 mx-1"
                                            style={{ marginTop: "6px" }}
                                          />
                                        ) : (
                                          <Icon className="example" style={{ fontSize: "28px", marginTop: "4px" }}>
                                            account_circle
                                          </Icon>
                                        )}
                                      </div>
                                    </a>
                                  </div>
                                ))}
                              </div>

                              <div
                                className={
                                  (subTask?.status === "TO DO" &&
                                    " bg-secondary btn-sm  px-1 text-white fw-bold text-center mx-4") ||
                                  (subTask?.status === "IN PROGRESS" &&
                                    "bg-warning btn-sm  px-1 text-white fw-bold text-center mx-1") ||
                                  (subTask?.status === "IN TESTING" &&
                                    "bg-danger btn-sm  px-1 text-white fw-bold text-center mx-2") ||
                                  (subTask?.status === "DONE" &&
                                    "bg-success btn-sm  px-1 text-white fw-bold text-center mx-4")
                                }
                                style={{
                                  display: "flex",
                                  width: "auto",
                                  height: "20px",
                                  fontSize: "12px",
                                  padding: "initial",
                                }}
                              >
                                {subTask?.status}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="mt-3 d-flex align-items-center justify-content-between ">
                    <h6 className="card-subtitle text-muted">Deadline</h6>
                    <p className="card-text">{task?.deadline ? formatDateNextTime(new Date(task?.deadline)) : null}</p>
                  </div>{" "}
                  {task?.closedAt !== null ? (
                    <div>
                      <div className="mt-4 d-flex align-items-center justify-content-between">
                        <h6 className="card-subtitle text-muted ">Closed date</h6>
                        <p className="card-text">{formatDateNextTime(new Date(task?.closedAt))}</p>
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-4 d-flex align-items-center justify-content-between">
                    {" "}
                    <h6 className="card-subtitle text-muted ">Cost</h6>
                    <p className="card-text">{task?.cost}$</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TasksScreen.propTypes = {
  query: PropTypes.string,
};
TasksScreen.defaultProps = {
  query: "",
};

export default memo(TasksScreen);

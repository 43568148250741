import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllArticles } from "../../reducers/articleList";
import ArticleList from "./ArticleList";
import { selectIsAuthenticated } from "../../features/auth/authSlice";
import ArticleTabs from "./ArticleTabs";

/**
 * Your feed tab
 *
 * @example
 * <NewsFeed />
 */

function NewsFeed() {
  const { articles: initialArticles } = useSelector((state) => state.articles);
  const [articles, setArticles] = useState([]);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllArticles());
  }, []);

  useEffect(() => {
    setArticles(initialArticles);
  }, [initialArticles]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="container page my-0" style={{ backgroundColor: "#f7f7f7" }}>
      <div className="feed-toggle" id="feed-toggle">
        <ul
          className="nav nav-pills outline-active mb-3 w-100 "
          style={{
            alignItems: "center",
          }}
        >
          <li className="nav-item">
            <button type="button" className="nav-link active" style={{ padding: "8px 0" }}>
              News Feed
            </button>
          </li>
          <div className="col d-flex justify-content-end">
            <ArticleTabs setArticles={setArticles} />
          </div>
        </ul>
      </div>
      <ArticleList articles={articles} />
    </div>
  );
}

export default memo(NewsFeed);

import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@mui/material";
import { selectUser } from "../../features/auth/authSlice";
import { getAllUsers } from "../../reducers/users";
import ArticleActions from "./ArticleActions";

/**
 * Show information about the current article
 *
 * @example
 * <ArticleMeta />
 */
function ArticleMeta() {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const currentUser = useSelector(selectUser);
  const location = useLocation();
  const article = useSelector((state) => state.article.article);
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  const author = users?.find((user) => user.id === article?.authorId);
  const isAuthor = currentUser?.username === author?.username;

  if (!article) return null;

  return (
    <div className="article-meta">
      <div className="col d-flex justify-content-start">
        <Link to={`${location.pathname}/user/${author?.id}`} style={{ textDecoration: "none" }}>
          {author?.image ? (
            <img src={author?.image} alt={author?.username} />
          ) : (
            <Icon style={{ fontSize: "35px", color: "#6C757D", marginBottom: "-13px" }}>account_circle</Icon>
          )}
        </Link>

        <div className="info">
          <Link to={`${location.pathname}/user/${author?.id}`} className="author" style={{ textDecoration: "none" }}>
            {author?.username}
          </Link>

          <time className="date" dateTime={article?.createdAt}>
            {new Date(article.createdAt).toDateString()}
          </time>
        </div>
        <div className="col d-flex justify-content-end">{isAuthor ? <ArticleActions /> : null}</div>
      </div>
    </div>
  );
}

export default memo(ArticleMeta);

import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { Modal, formatDate, generateKey } from "../../common/utils";
import { getAllUsers } from "../../reducers/users";
import { selectIsAuthenticated, selectUser } from "../auth/authSlice";
import { deleteComment } from "./commentsSlice";

/**
 * Delete a comment
 *
 * @param {object}  props
 * @param {number}  props.commentId
 * @example
 * <DeleteCommentButton commentId={1} />
 */
function DeleteCommentButton({ commentId, comments, setComments }) {
  const dispatch = useDispatch();

  /**
   * @type {React.MouseEventHandler<HTMLButtonElement>}
   */

  const handleDeleteComment = () => {
    dispatch(deleteComment(commentId));

    const updateComments = comments.filter((comment) => comment.id !== commentId);
    setComments(updateComments);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-link mod-options p-0"
        name="delete"
        data-bs-toggle="modal"
        data-bs-target={`#a${commentId}`}
      >
        <Icon style={{ fontSize: "16px", marginBottom: "-1px" }} className="delete_icon">
          delete
        </Icon>
        <span className="sr-only">Delete comment</span>
      </button>
      <Modal handleDeleteItem={handleDeleteComment} id={`a${commentId}`} fontSize={15} />
    </div>
  );
}

DeleteCommentButton.propTypes = {
  setComments: PropTypes.func.isRequired,
  comments: PropTypes.instanceOf(Array).isRequired,
  commentId: PropTypes.number.isRequired,
};

function EditCommentButton({ setComment, setIsEdit, comment }) {
  /**
   * @type {React.MouseEventHandler<HTMLButtonElement>}
   */
  const isEditButton = () => {
    setComment(comment);
    setIsEdit(true);
  };
  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-link mod-options p-0 mx-1 text-dark"
        name="delete"
        onClick={isEditButton}
      >
        <Icon style={{ fontSize: "20px", marginBottom: "-2px" }} className="edit_icon">
          edit_note
        </Icon>
      </button>
    </div>
  );
}

EditCommentButton.propTypes = {
  setIsEdit: PropTypes.func.isRequired,
  setComment: PropTypes.func.isRequired,
  comment: PropTypes.instanceOf(Object).isRequired,
};

/**
 *
 * @param {object} props
 * @param {import('../../agent').Comment} props.comment
 * @example
 * <Comment />
 */

function Comment({ comment, user, comments, setComments, setComment, setIsEdit }) {
  const isAuthenticaded = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectUser);
  const isAuthor = comment?.authorId === currentUser?.id;

  return (
    <div className="footer-comment mb-4">
      <div className="row align-items-center">
        <div className="col d-flex justify-content-start">
          <Link to={`/user/${user?.id}`} className="comment-author">
            {user?.image ? (
              <img
                className="rounded-circle mx-1 comment-author-img center mt-1"
                alt={user?.username}
                src={user?.image}
                width="40"
                height="40"
              />
            ) : (
              <Icon style={{ fontSize: "45px", color: "#6C757D" }}>account_circle</Icon>
            )}
          </Link>

          <div className="card-body p-0 ps-3">
            <h6 className="card-title">
              <Link
                to={`/user/${user?.id}`}
                className="comment-author text-decoration-none text-muted"
                style={{ color: "black" }}
              >
                {user?.username}
              </Link>
            </h6>
            <p
              className="card-text text-break"
              style={{ color: "#8c8c8c" }}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: comment?.body }}
            />
            <div
              className="card-text text-end d-flex justify-content-end align-items-center"
              style={{ fontSize: "0.65rem" }}
            >
              <small className="text-muted">
                <time className="date-posted" dateTime={comment?.createdAt}>
                  {formatDate(new Date(comment?.createdAt))}
                </time>
              </small>

              {isAuthenticaded && isAuthor && comment?.id && (
                <div className="d-flex">
                  {" "}
                  <EditCommentButton setComment={setComment} comment={comment} setIsEdit={setIsEdit} />
                  <DeleteCommentButton commentId={comment.id} comments={comments} setComments={setComments} />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Comment.propTypes = {
  setComments: PropTypes.func.isRequired,
  comments: PropTypes.instanceOf(Array).isRequired,
  comment: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  setIsEdit: PropTypes.func.isRequired,
  setComment: PropTypes.func.isRequired,
};

/**
 * List all comments of a
 *
 * @example
 * <CommentList />
 */
function CommentList({ comments, setComments, setIsEdit, setComment }) {
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [noOfElement, setNoOfElement] = useState(2);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const items = comments ? [...comments] : [];
  const recentComments = items.sort((a, b) => b.id - a.id);

  const loadMore = () => {
    setNoOfElement(noOfElement + 5);
  };
  const slice = recentComments.slice(0, noOfElement);

  return (
    <div>
      {slice.map((comment) => {
        const user = users?.find((isUser) => isUser?.id === comment?.authorId);
        return (
          <Comment
            key={generateKey()}
            comment={comment}
            user={user}
            comments={comments}
            setComments={setComments}
            setComment={setComment}
            setIsEdit={setIsEdit}
          />
        );
      })}
      {recentComments.length > 2 && slice.length !== recentComments.length ? (
        <div className="text-left">
          <button
            type="button"
            className="load-more text-decoration-none border-0 bg-light"
            style={{ color: "#106DF6" }}
            onClick={() => loadMore()}
          >
            Load more
          </button>
        </div>
      ) : null}
    </div>
  );
}

CommentList.propTypes = {
  setComments: PropTypes.func.isRequired,
  comments: PropTypes.instanceOf(Array),
  setIsEdit: PropTypes.func.isRequired,
  setComment: PropTypes.func.isRequired,
};
CommentList.defaultProps = {
  comments: [],
};
export default memo(CommentList);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import agent from "../agent";
import { profilePageUnloaded } from "./profile";
import { serializeError } from "../common/utils";

export const getTasksByAuthor = createAsyncThunk(
  "taskList/getTasksByAuthor",
  ({ author, page } = {}) => agent.Tasks.byAuthor(author, page),
  { serializeError }
);

export const getAllTasks = createAsyncThunk("taskList/getAll", agent.Tasks.all, { serializeError });

const initialState = {
  tasks: [],
  tasksCount: 0,
  currentPage: 0,
  tasksPerPage: 10,
  tab: undefined,
  author: undefined,
};

const taskListSlice = createSlice({
  name: "taskList",
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.tab = action.payload;
      // eslint-disable-next-line no-param-reassign
      delete state.tag;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTasks.fulfilled, (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.tasks = action.payload.tasks;
      // eslint-disable-next-line no-param-reassign
      state.tasksCount = action.payload.tasksCount;
      // eslint-disable-next-line no-param-reassign
      state.currentPage = action.meta.arg?.page ?? 0;
    });

    builder.addCase(getTasksByAuthor.fulfilled, (_, action) => ({
      tasks: action.payload.tasks,
      tasksCount: action.payload.tasksCount,
      currentPage: action.meta.arg?.page ?? 0,
      author: action.meta.arg?.author,
      tasksPerPage: 5,
    }));

    builder.addMatcher(
      (action) => [profilePageUnloaded.type].includes(action.type),
      () => initialState
    );
  },
});

export const { homePageUnloaded } = taskListSlice.actions;

export const changeTab = (tab) => (dispatch) => {
  dispatch(taskListSlice.actions.changeTab(tab));
  return dispatch(getAllTasks());
};

export default taskListSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import agent from "../agent";
import { serializeError } from "../common/utils";

export const getTrace = createAsyncThunk("trace/getTrace", agent.Trace.get, { serializeError });

export const createGitAccess = createAsyncThunk("trace/createGitAccess", agent.Trace.create, { serializeError });

export const connectProject = createAsyncThunk("trace/connectProject", agent.Trace.connectProject, { serializeError });

const initialState = {
  trace: undefined,
};

const traceSlice = createSlice({
  name: "trace",
  initialState,
  reducers: {
    tracePageUnloaded: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getTrace.fulfilled, (traceState, action) => {
      const state = traceState;
      state.trace = action.payload;
    });

    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      () => {}
    );

    builder.addDefaultCase(() => {});
  },
});

export const { tracePageUnloaded } = traceSlice.actions;

export default traceSlice.reducer;

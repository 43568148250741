import { PropTypes } from "prop-types";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { generateKey } from "./utils";
import "./members.css";

function Members({ members, isTask, handleDeleteUser, isOrganization, height, isTaskDetail, isProject, isCard }) {
  const location = useLocation();
  const [loadMore, setLoadMore] = useState(false);

  const showMore = () => {
    if (!isCard) return setLoadMore(true);
    return setLoadMore(false);
  };

  const loadCount = () => {
    if (loadMore) return members.length;
    if (isTask || isProject) return 6;
    if (isTaskDetail) return 4;
    if (isOrganization) return 15;
    return 3;
  };

  return (
    <div className="d-flex justify-content-between">
      {members?.length > loadCount() ? (
        <div className="d-flex flex-wrap p-0 m-0">
          {members?.slice(0, loadCount()).map((member) => (
            <div key={generateKey()}>
              {" "}
              {(isTaskDetail || isProject) && (
                <div
                  className="dropdown pe-1"
                  style={{
                    marginLeft: "28px",
                    marginBottom: "-17px",
                  }}
                >
                  <button
                    className="nav-link dropdown border-0 bg-transparent"
                    href="#"
                    id="navbarNotification"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    type="button"
                  >
                    <Icon className="delete_icon">
                      delete
                      <span
                        className="badge rounded-circle bg-danger position-absolute p-1"
                        style={{ marginLeft: "-5px" }}
                      />
                    </Icon>
                  </button>
                  <ul className="dropdown-menu position-absolute" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                      <button className="dropdown-item" type="button" onClick={() => handleDeleteUser(member.id)}>
                        Unassign
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        Cancel
                      </button>
                    </li>
                  </ul>
                </div>
              )}
              <Link
                to={location.pathname === "/" ? `/user/${member.id}` : `${location.pathname}/user/${member.id}`}
                className="username"
                data-title={member?.username}
              >
                <div className="image me-1" key={generateKey()}>
                  {member?.image ? (
                    <img src={member?.image} alt="" width="41" height="41" className="rounded-circle mt-1 mb-2 mx-1" />
                  ) : (
                    <Icon className="black" style={{ fontSize: "48px" }}>
                      account_circle
                    </Icon>
                  )}
                </div>
              </Link>{" "}
            </div>
          ))}

          {loadCount() !== members.length ? (
            <a href="# " className="load-more text-decoration-none" onClick={() => showMore()}>
              <div
                className={`mx-1 bg-secondary rounded-circle ${height}`}
                style={{
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                }}
                key={generateKey()}
              >
                <p
                  className="mb-0 mt-1 pt-1 text-white"
                  style={{
                    width: "40px",
                    height: "40px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontWeightL: "500",
                  }}
                >
                  +{Math.floor(members?.length) - loadCount()}
                </p>
              </div>
            </a>
          ) : null}
        </div>
      ) : (
        <div className="d-flex flex-wrap">
          {members?.map((member) => (
            <div key={generateKey()}>
              {(isTaskDetail || isProject) && (
                <div
                  className="dropdown pe-1"
                  style={{
                    marginLeft: "28px",
                  }}
                >
                  <button
                    className="nav-link dropdown border-0 bg-transparent"
                    href="#"
                    id="navbarNotification"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    type="button"
                  >
                    <Icon className="delete_icon">
                      delete
                      <span
                        className="badge rounded-circle bg-danger position-absolute p-1"
                        style={{ marginLeft: "-5px" }}
                      />
                    </Icon>
                  </button>
                  <ul className="dropdown-menu position-absolute" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                      <button className="dropdown-item" type="button" onClick={() => handleDeleteUser(member.id)}>
                        Unassign
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        Cancel
                      </button>
                    </li>
                  </ul>
                </div>
              )}
              <Link
                to={location.pathname === "/" ? `/user/${member.id}` : `${location.pathname}/user/${member.id}`}
                className="username"
                data-title={member?.username}
              >
                <div className="image mx-1" key={generateKey()}>
                  {member?.image ? (
                    <img src={member?.image} alt="" width="41" height="41" className="rounded-circle mt-1 mb-2 mx-1" />
                  ) : (
                    <Icon className="black" style={{ fontSize: "48px" }}>
                      account_circle
                    </Icon>
                  )}
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
Members.propTypes = {
  members: PropTypes.instanceOf(Array),
  isTaskDetail: PropTypes.bool,
  isOrganization: PropTypes.bool,
  isTask: PropTypes.bool,
  isProject: PropTypes.bool,
  isCard: PropTypes.bool,
  handleDeleteUser: PropTypes.func,
  height: PropTypes.string,
};
Members.defaultProps = {
  members: [],
  isTaskDetail: false,
  isOrganization: false,
  isTask: false,
  isProject: false,
  isCard: false,
  handleDeleteUser: () => {},
  height: "",
};

export default Members;

import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

import agent from "../../agent";
import { isApiError, loadingReducer, Status } from "../../common/utils";
import { selectIsAuthenticated, selectUser } from "../auth/authSlice";

/**
 * @typedef  {object}   CommentsState
 * @property {Status}   status
 * @property {number[]} ids
 * @property {Record<string, import('../../agent').Comment>} entities

 */

const commentAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

/**
 * Send a create request
 *
 * @param {object} argument
 * @param {string} argument.articleSlug
 * @param {object} argument.comment
 * @param {string} argument.comment.body
 */

/**
 * Get comments state
 *
 * @param {object} state
 * @returns {CommentsState}
 */
const selectCommentsSlice = (state) => state.comments;

const commentSelectors = commentAdapter.getSelectors(selectCommentsSlice);

/**
 * Get all comments
 *
 * @param {object} state
 * @returns {import('../../agent').Comment[]}
 */
export const selectAllComments = commentSelectors.selectAll;

/**
 * Get one comment
 *
 * @param {number} commentId
 * @returns {import('@reduxjs/toolkit').Selector<object, import('../../agent').Comment>}
 */
const selectCommentById = (commentId) => (state) => commentSelectors.selectById(state, commentId);

/**
 * Get is the comment's author
 *
 * @param {number} commentId
 * @returns {import('@reduxjs/toolkit').Selector<object, boolean>}
 */
export const selectIsAuthor = (commentId) =>
  createSelector(
    selectCommentById(commentId),
    selectUser,
    (comment, currentUser) => currentUser?.username === comment?.author.username
  );

export const createArticleComment = createAsyncThunk(
  "comments/createArticleComment",
  async ({ articleSlug, comment: newComment }, thunkApi) => {
    try {
      const { comment } = await agent.Comments.create(articleSlug, newComment);

      return comment;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  },
  {
    condition: (_, { getState }) => selectIsAuthenticated(getState()),
    getPendingMeta: (_, { getState }) => ({ author: selectUser(getState()) }),
  }
);

export const editArticleComment = createAsyncThunk(
  "articleComments/editArticleComment",
  async ({ commentId, comment: newComment }, thunkApi) => {
    try {
      const { comment } = await agent.Comments.edit(commentId, newComment);

      return comment;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  },
  {
    condition: (_, { getState }) => selectIsAuthenticated(getState()),
    getPendingMeta: (_, { getState }) => ({ author: selectUser(getState()) }),
  }
);

export const createProjectComment = createAsyncThunk(
  "projectComments/createProjectComment",
  async ({ projectSlug, comment: newComment }, thunkApi) => {
    try {
      const { comment } = await agent.ProjectComments.create(projectSlug, newComment);

      return comment;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  },
  {
    condition: (_, { getState }) => selectIsAuthenticated(getState()),
    getPendingMeta: (_, { getState }) => ({ author: selectUser(getState()) }),
  }
);

export const editProjectComment = createAsyncThunk(
  "projectComments/editProjectComment",
  async ({ commentId, comment: newComment }, thunkApi) => {
    try {
      const { comment } = await agent.ProjectComments.edit(commentId, newComment);

      return comment;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  },
  {
    condition: (_, { getState }) => selectIsAuthenticated(getState()),
    getPendingMeta: (_, { getState }) => ({ author: selectUser(getState()) }),
  }
);

export const createTaskComment = createAsyncThunk(
  "taskComments/createTaskComment",
  async ({ taskSlug, comment: newComment }, thunkApi) => {
    try {
      const { comment } = await agent.TaskComments.create(taskSlug, newComment);

      return comment;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  },
  {
    condition: (_, { getState }) => selectIsAuthenticated(getState()),
    getPendingMeta: (_, { getState }) => ({ author: selectUser(getState()) }),
  }
);
export const editTaskComment = createAsyncThunk(
  "taskComments/editTaskComment",
  async ({ commentId, comment: newComment }, thunkApi) => {
    try {
      const { comment } = await agent.TaskComments.edit(commentId, newComment);

      return comment;
    } catch (error) {
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  },
  {
    condition: (_, { getState }) => selectIsAuthenticated(getState()),
    getPendingMeta: (_, { getState }) => ({ author: selectUser(getState()) }),
  }
);
/**
 * Send a get all request
 *
 * @param {string} articleSlug
 */
export const getCommentsForArticle = createAsyncThunk("comments/getCommentsForArticle", async (articleSlug) => {
  const { comments } = await agent.Comments.forArticle(articleSlug);

  return comments;
});

/**
 * Send a remove request
 *
 * @param {object} argument
 * @param {string} argument.articleSlug
 * @param {number} argument.commentId
 */
export const removeComment = createAsyncThunk(
  "comments/removeComment",
  async ({ articleSlug, commentId }) => {
    await agent.Comments.delete(articleSlug, commentId);
  },
  {
    condition: ({ commentId }, { getState }) =>
      selectIsAuthenticated(getState()) && selectCommentsSlice(getState()).ids.includes(commentId),
  }
);

export const deleteComment = createAsyncThunk("common/deleteComment", agent.ProjectComments.delete);

/**
 * @type {CommentsState}
 */
const initialState = commentAdapter.getInitialState({
  status: Status.IDLE,
});

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createArticleComment.pending, (statusState, action) => {
        const state = statusState;
        state.status = Status.LOADING;

        if (action.meta.arg.comment.body) {
          commentAdapter.addOne(state, {
            ...action.meta.arg.comment,
            author: action.meta.author,
            id: action.meta.requestId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      })
      .addCase(createArticleComment.fulfilled, (statusState, action) => {
        const state = statusState;
        state.status = Status.SUCCESS;
        commentAdapter.updateOne(state, {
          id: action.meta.requestId,
          changes: action.payload,
        });
      })
      .addCase(createArticleComment.rejected, (statusState, action) => {
        const state = statusState;
        state.status = Status.FAILURE;

        commentAdapter.removeOne(state, action.meta.requestId);
      });

    builder
      .addCase(createProjectComment.pending, (statusState, action) => {
        const state = statusState;
        state.status = Status.LOADING;

        if (action.meta.arg.comment.body) {
          commentAdapter.addOne(state, {
            ...action.meta.arg.comment,
            author: action.meta.author,
            id: action.meta.requestId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      })
      .addCase(createProjectComment.fulfilled, (statusState, action) => {
        const state = statusState;
        state.status = Status.SUCCESS;
        commentAdapter.updateOne(state, {
          id: action.meta.requestId,
          changes: action.payload,
        });
      })
      .addCase(createProjectComment.rejected, (statusState, action) => {
        const state = statusState;
        state.status = Status.FAILURE;

        commentAdapter.removeOne(state, action.meta.requestId);
      });

    builder
      .addCase(editProjectComment.pending, (statusState, action) => {
        const state = statusState;
        state.status = Status.LOADING;

        if (action.meta.arg.comment.body) {
          commentAdapter.addOne(state, {
            ...action.meta.arg.comment,
            author: action.meta.author,
            id: action.meta.requestId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      })
      .addCase(editProjectComment.fulfilled, (statusState, action) => {
        const state = statusState;
        state.status = Status.SUCCESS;
        commentAdapter.updateOne(state, {
          id: action.meta.requestId,
          changes: action.payload,
        });
      })
      .addCase(editProjectComment.rejected, (statusState, action) => {
        const state = statusState;
        state.status = Status.FAILURE;

        commentAdapter.removeOne(state, action.meta.requestId);
      });

    builder
      .addCase(createTaskComment.pending, (statusState, action) => {
        const state = statusState;
        state.status = Status.LOADING;

        if (action.meta.arg.comment.body) {
          commentAdapter.addOne(state, {
            ...action.meta.arg.comment,
            author: action.meta.author,
            id: action.meta.requestId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      })
      .addCase(createTaskComment.fulfilled, (statusState, action) => {
        const state = statusState;
        state.status = Status.SUCCESS;
        commentAdapter.updateOne(state, {
          id: action.meta.requestId,
          changes: action.payload,
        });
      })
      .addCase(createTaskComment.rejected, (statusState, action) => {
        const state = statusState;
        state.status = Status.FAILURE;

        commentAdapter.removeOne(state, action.meta.requestId);
      });

    builder
      .addCase(editTaskComment.pending, (statusState, action) => {
        const state = statusState;
        state.status = Status.LOADING;

        if (action.meta.arg.comment.body) {
          commentAdapter.addOne(state, {
            ...action.meta.arg.comment,
            author: action.meta.author,
            id: action.meta.requestId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      })
      .addCase(editTaskComment.fulfilled, (statusState, action) => {
        const state = statusState;
        state.status = Status.SUCCESS;
        commentAdapter.updateOne(state, {
          id: action.meta.requestId,
          changes: action.payload,
        });
      })
      .addCase(editTaskComment.rejected, (statusState, action) => {
        const state = statusState;
        state.status = Status.FAILURE;

        commentAdapter.removeOne(state, action.meta.requestId);
      });

    builder.addCase(getCommentsForArticle.fulfilled, (statusState, action) => {
      const state = statusState;
      state.status = Status.SUCCESS;
      commentAdapter.setAll(state, action.payload);
    });

    builder.addCase(removeComment.fulfilled, (statusState, action) => {
      const state = statusState;
      state.status = Status.SUCCESS;
      commentAdapter.removeOne(state, action.meta.arg.commentId);
    });

    builder.addCase(deleteComment.fulfilled, (redirectState) => {
      const state = redirectState;
      state.redirectTo = "/";
    });

    builder.addMatcher((action) => /comments\/.*\/pending/.test(action.type), loadingReducer);
  },
});

export default commentsSlice.reducer;

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { Progress, Modal, formatDateNextTime, generateKey } from "../../common/utils";
import { selectUser } from "../../features/auth/authSlice";
import CommentSection from "../../features/comments/CommentSection";
import { assignUser, getOnlineUsers, getProject, projectPageUnloaded } from "../../reducers/project";
import { getAllUsers } from "../../reducers/users";
import { deleteProject } from "../../reducers/common";
import socket from "../../socket";
import DropFileInput from "../DropFileInput";
import OnlineStatus from "../OnlineStatus";
import "./project.css";
import "../style.css";

/**
 * ProjectDetail component
 * @param {import('react-router-dom').RouteComponentProps<{ slug?: string }>} props
 * @exampleimport 

 * <ProjectDetail />
 */
function ProjectDetail() {
  const location = useLocation();
  const { slug } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProject(slug));
  }, []);
  const currentUser = useSelector(selectUser);
  const { project } = useSelector((state) => state.project);
  const { onlineUsers } = useSelector((state) => state.project);
  const [users, setUsers] = useState();
  const [members, setMembers] = useState();
  const [tasks, setTasks] = useState();
  const [loadMore, setLoadMore] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    socket.on("user_connected", () => {
      dispatch(getOnlineUsers(slug));
    });

    return () => {
      socket.off("user_connected", () => {});
    };
  }, []);

  function equal(a, b) {
    return a?.some((e1) => e1.email === b.email);
  }

  useEffect(() => {
    dispatch(getOnlineUsers(slug));
  }, [project]);

  useEffect(() => {
    setTasks(project?.Tasks);
  }, [project]);

  useEffect(() => {
    setMembers(project?.members);
  }, [project]);

  useEffect(() => {
    const getProjectById = async () => {
      const result = await dispatch(getAllUsers());
      setUsers(result.payload.users);
    };

    getProjectById();
  }, []);

  useEffect(() => () => dispatch(projectPageUnloaded()), []);

  const onChange = (e) => {
    const payload = {
      userId: e.target.value,
      projectId: project.id,
    };

    dispatch(assignUser(payload));
    const member = users.find((user) => user.id === +e.target.value);
    setMembers([...members, member]);
  };

  const isTasks = tasks?.filter((task) => task.relatedTo === null);

  const showMore = () => {
    setLoadMore(true);
  };
  const loadCount = loadMore ? members?.length : 4;

  const handleDeleteItem = () => {
    dispatch(deleteProject(project.id));

    navigate("/");
  };

  return (
    <div className="projectDetail-page" role="presentation">
      <div className="container page">
        <div className="row">
          <div className="col-md-8">
            <div className="card border-0">
              <div className="card-body bg-light">
                <div className="row mb-4">
                  <div className="col pt-0  d-flex align-items-start">
                    <h4 className="card-title pt-0 fw-bolder text-muted mb-0">{project?.title}</h4>
                  </div>
                  <div className="col pt-0  d-flex justify-content-end" style={{ gap: "10px" }}>
                    <Link to={`/project-detail/${slug}/project-tasks/${slug}`} style={{ textDecoration: "none" }}>
                      <div className="col d-flex  align-items-start mb-0 pt-0 ">
                        <p className="card-text" style={{ color: "#a6a6a6" }} />
                        <button type="button" className="btn green_button" style={{ margin: 0 }}>
                          Tasks &nbsp;&nbsp;{isTasks?.length}
                        </button>
                      </div>
                    </Link>
                    {currentUser?.role.name === "manager" && (
                      <Link to={`/project-detail/${slug}/edit-project/${slug}`} className="nav-link">
                        <button type="button" className="btn gray_button" style={{ minWidth: "83.6px" }}>
                          {" "}
                          <Icon style={{ fontSize: "18px", color: "white", marginBottom: "-4px" }}>edit</Icon>
                          &nbsp; Edit
                        </button>
                      </Link>
                    )}
                    {currentUser?.role.name === "manager" && (
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-lg btn-link mod-options py-0 px-1"
                          name="delete"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <Icon style={{ fontSize: "30px", marginBottom: "-6px" }} className="delete_icon">
                            delete
                          </Icon>
                          <span className="sr-only">Delete project</span>
                        </button>
                        <Modal handleDeleteItem={handleDeleteItem} id="exampleModal" fontSize={15} />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    (project?.status === "Finished" &&
                      " bg-success btn-sm position-absolute px-2 fw-bold text-center") ||
                    (project?.status === "Ongoing" &&
                      "bg-secondary btn-sm position-absolute px-2 text-white fw-bold text-center") ||
                    (project?.status === "Planned" &&
                      "bg-warning btn-sm position-absolute px-2 text-white fw-bold text-center") ||
                    ""
                  }
                  style={{
                    height: "18px",
                    fontSize: "12px",
                    padding: "initial",
                  }}
                >
                  {project?.status}
                </div>
                <div className="d-flex align-items-left">
                  <div>
                    <h6 className="card-subtitle mb-2 mt-5 text-muted">Project Overview:</h6>
                    <p className="card-text me-1 overview" dangerouslySetInnerHTML={{ __html: project?.description }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-0">
              <DropFileInput project={project} />
            </div>
            <div className="card border-0 mt-2">
              <div className="card-body bg-light">
                <CommentSection />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card border-0">
              <div className="card-body bg-light">
                <div className="card border-0">
                  <div
                    className="card-body bg-light"
                    style={{ display: "flex", padding: "0px", justifyContent: "space-between" }}
                  >
                    <h6 className="card-subtitle text-muted">Progress</h6>
                    <Progress tasks={project?.Tasks} />
                  </div>
                </div>
                <h4 className="card-title mb-4 fw-bolder text-muted">{project?.title}</h4>
                <div className="text-left">
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <h6 className="card-subtitle text-muted mt-3 mb-3">Team Members</h6>
                    {currentUser?.role.name === "manager" && (
                      <select
                        className="form-select form-select-sm"
                        style={{ width: "160px", height: "30px" }}
                        aria-label="Default select example"
                        onChange={onChange}
                      >
                        <option type="others">Select a member</option>
                        {users?.map((user) => (
                          <option
                            disabled={members?.some((e) => e.username === user.username)}
                            key={generateKey()}
                            value={user.id}
                            id={user.username}
                          >
                            {user.username}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  <h6 className="card-subtitle text-muted mt-3 mb-3">
                    {members?.length !== 0 ? "Assigned to" : "Unassigned"}
                  </h6>

                  {members?.length > 4 ? (
                    <div className="images mb-4 d-flex flex-wrap" style={{ gap: "10px" }}>
                      {members?.slice(0, loadCount).map((member) => (
                        <Link
                          to={`${location.pathname}/user/${member.id}`}
                          className="username"
                          data-title={member?.username}
                        >
                          <div
                            className="image mx-1 mt-2 mb-1"
                            style={{
                              width: "50px",
                              height: "50px",
                              position: "relative",
                            }}
                            key={generateKey()}
                          >
                            {member?.image ? (
                              <img className="rounded-circle mt-1" alt="" src={member.image} width="42" height="42" />
                            ) : (
                              <Icon style={{ fontSize: "50px", color: "#6C757D" }}>account_circle</Icon>
                            )}
                            <OnlineStatus isOnline={equal(onlineUsers, member)} />
                          </div>
                        </Link>
                      ))}
                      {loadCount !== members?.length ? (
                        <a href="# " className="load-more text-decoration-none" onClick={() => showMore()}>
                          <div
                            className="mx-2 bg-secondary rounded-circle"
                            style={{
                              width: "40px",
                              height: "40px",
                              textAlign: "center",
                            }}
                            key={generateKey()}
                          >
                            <p
                              className="mb-0 text-white pt-1"
                              style={{
                                width: "40px",
                                height: "40px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                fontWeightL: "500",
                                marginTop: "10px",
                              }}
                            >
                              +{Math.floor(members?.length) - loadCount}
                            </p>
                          </div>
                        </a>
                      ) : null}
                    </div>
                  ) : (
                    <div className="images mt-4  mb-4 d-flex flex-wrap" style={{ gap: "10px" }}>
                      {members?.slice(0, loadCount).map((member) => (
                        <Link
                          to={`${location.pathname}/user/${member.id}`}
                          className="username"
                          data-title={member?.username}
                        >
                          <div
                            className="image mx-1 mb-1"
                            style={{
                              width: "50px",
                              height: "50px",
                              position: "relative",
                              alignContent: "flex-start",
                            }}
                            key={generateKey()}
                          >
                            {member?.image ? (
                              <img
                                className="rounded-circle mt-1 mx-1"
                                alt=""
                                src={member.image}
                                width="42"
                                height="42"
                              />
                            ) : (
                              <Icon className="black" style={{ fontSize: "50px" }}>
                                account_circle
                              </Icon>
                            )}
                            <OnlineStatus isOnline={equal(onlineUsers, member)} />
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                  <h6 className="card-subtitle text-muted mb-3 mt-3">Start Date</h6>
                  <p className="card-text">{formatDateNextTime(new Date(project?.startDate))}</p>
                </div>
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                  <h6 className="card-subtitle text-muted mb-3 mt-3">Due Date</h6>
                  <p className="card-text">{formatDateNextTime(new Date(project?.endDate))}</p>
                </div>
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                  <h6 className="card-subtitle text-muted mb-3 mt-3">Budget</h6>
                  <p className="card-text">
                    {project?.currency?.code} {project?.budget}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ProjectDetail);

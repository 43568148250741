import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import agent from "../agent";
import { serializeError } from "../common/utils";

export const getProject = createAsyncThunk("project/getProject", agent.Projects.get, { serializeError });

export const getOnlineUsers = createAsyncThunk("online/getOnlineUsers", agent.Projects.getOnlineUsers, {
  serializeError,
});

export const createProject = createAsyncThunk("project/createProject", agent.Projects.create, { serializeError });

export const updateProject = createAsyncThunk("project/updateProject", agent.Projects.update, { serializeError });

export const assignUser = createAsyncThunk("project/assignUser", agent.Projects.assignUser, { serializeError });

export const unAssignUser = createAsyncThunk("project/unAssignUser", agent.Projects.unAssignUser, { serializeError });
export const uploadFile = createAsyncThunk("project/uploadFile", agent.Projects.uploadFile, { serializeError });

const initialState = {
  project: undefined,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    projectPageUnloaded: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getProject.fulfilled, (projectState, action) => {
      const state = projectState;
      state.project = action.payload.project;
    });

    builder.addCase(getOnlineUsers.fulfilled, (projectState, action) => {
      const state = projectState;
      state.onlineUsers = action.payload;
    });

    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      () => {}
    );

    builder.addDefaultCase(() => {});
  },
});

export const { projectPageUnloaded } = projectSlice.actions;

export default projectSlice.reducer;

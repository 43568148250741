import { io } from "socket.io-client";
import config from "./config";

const URL = config.apiUrl;
const token = window.localStorage.getItem("jwt");
const socket = io(URL, {
  query: {
    token: `Bearer ${token}`,
  },
});

export default socket;

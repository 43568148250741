import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import agent from "../agent";
import { serializeError } from "../common/utils";

export const createGitAccess = createAsyncThunk("trace/createGitAccess", agent.Trace.create, { serializeError });

export const getGitAccess = createAsyncThunk("trace/getGitAccess", agent.Trace.getGitAccess, { serializeError });

const initialState = {
  gitAccess: undefined,
};

const gitAccessSlice = createSlice({
  name: "gitAccess",
  initialState,
  reducers: {
    gitAccessPageUnloaded: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getGitAccess.fulfilled, (gitAccessState, action) => {
      const state = gitAccessState;
      state.gitAccess = action.payload;
    });

    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      () => {}
    );

    builder.addDefaultCase(() => {});
  },
});

export const { gitAccessPageUnloaded } = gitAccessSlice.actions;

export default gitAccessSlice.reducer;

import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import useTimeout from "../hooks/useTimeout";

function Toaster({ message, type, time = 4000 }) {
  const [isShowToaster, setIsShowToaster] = useState();

  useEffect(() => {
    if (message) {
      setIsShowToaster(true);
    } else setIsShowToaster(false);
  }, [message]);

  const [timeout] = useTimeout(() => {
    setIsShowToaster(false);
  }, time);

  timeout();

  return (
    <div className="toast-container" style={{ position: "fixed", top: 30, right: 20 }}>
      {isShowToaster && message !== "User not found" && message !== "Wrong password" && (
        <div
          className={`toast show align-items-center border-0 bg-${type}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">{message}</div>
            <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            />
          </div>
        </div>
      )}
    </div>
  );
}
Toaster.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string.isRequired,
  time: PropTypes.number,
};
Toaster.defaultProps = {
  message: "",
  time: 4000,
};

export default Toaster;

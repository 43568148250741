import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../agent";
import { profilePageUnloaded } from "./profile";
import { serializeError } from "../common/utils";

export const getAllUsers = createAsyncThunk("projectList/getAll", agent.Projects.allUsers, { serializeError });

const initialState = {
  users: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (usersState, action) => {
      const state = usersState;
      state.tab = action.payload;
      delete state.tag;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (usersState, action) => {
      const state = usersState;
      state.users = action.payload.users;
    });

    builder.addMatcher(
      (action) => [profilePageUnloaded.type].includes(action.type),
      () => initialState
    );
  },
});

export const changeTab = (tab) => (dispatch) => {
  dispatch(usersSlice.actions.changeTab(tab));
  return dispatch(getAllUsers());
};

export const { homePageUnloaded } = usersSlice.actions;

export default usersSlice.reducer;

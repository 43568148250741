import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mui/material/Icon";
import Toaster from "../../common/Toaster";
import { deleteLogo, getOrganization, updateOrganization, uploadLogo } from "../../reducers/organization";
import CreateButton from "../../utils/createButton";
import "./organization.css";

function EditOrganizationScreen() {
  const { organization } = useSelector((state) => state.organization);
  const [editOrganization, setEditOrganization] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [image, setImage] = useState();
  const [isSelectedImage, setIsSelectedImage] = useState(false);
  const [disabledbutton, setDisabledButton] = useState(true);
  const [responseMessage, setResponeMessage] = useState();
  const [imageData, setImageData] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [deleteImage, setDeleteImage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setEditOrganization(organization);
  }, [organization]);

  useEffect(() => {
    setImage(organization?.logo);
  }, [organization?.logo]);

  const changeName = (event) => {
    if (organization?.name?.trim() !== event.target.value?.trim() && event.target.value.replace(/\s/g, "").length !== 0)
      setDisabledButton(false);
    else setDisabledButton(true);
    setEditOrganization({ ...editOrganization, name: event.target.value?.trimStart() });
  };

  const fileRemove = async () => {
    setDeleteImage(true);
    const preview = document.getElementById(organization?.id);
    preview?.removeChild(previewImage);
    setPreviewImage(null);
    setIsSelectedImage(false);
    setImageData(null);
    setDisabledButton(false);
  };

  const saveSettings = async (event) => {
    setSuccessMessage("");
    event.preventDefault();

    const organizationBody = {
      logo: deleteImage ? null : editOrganization?.logo,
      name: editOrganization?.name?.trim(),
    };

    if (editOrganization !== organization)
      dispatch(updateOrganization(organizationBody)).then((response) => setResponeMessage(response));
    if (imageData) dispatch(uploadLogo(imageData)).then((response) => setResponeMessage(response));
    if (deleteImage)
      dispatch(deleteLogo()).then((response) => {
        setResponeMessage(response);
        setDeleteImage(false);
        setEditOrganization({ ...editOrganization, logo: null });
      });
  };

  useEffect(() => {
    if (responseMessage?.type.includes("fulfilled")) {
      setSuccessMessage("Organization has been successfully updated");
      dispatch(getOrganization());
    }
  }, [responseMessage]);

  async function dragNdrop(event) {
    const filename = URL.createObjectURL(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    setImageData(formData);

    const preview = document.getElementById(organization?.id); // the div where we will show image
    const previewImg = document.createElement("img"); // creating new image tag
    previewImg.setAttribute("src", filename); // setting attribute src of newly created "img" element to "filename"
    previewImg.setAttribute("height", 296);
    previewImg.setAttribute("width", 296);
    preview.innerHTML = "";
    preview.appendChild(previewImg); // finally append "img" element to parent element "preview"
    setPreviewImage(previewImg);
    setIsSelectedImage(true);
    setDisabledButton(false);
  }

  // 2. drag
  const drag = () => {
    // while dragging it will fire this function that will add the following className
    document.getElementById(organization?.id);
  };

  // 3. finally the drop()
  const drop = () => {
    document.getElementById(organization?.id);
  };

  return (
    <div className="editProfile-page">
      <Toaster type="success" message={successMessage} />
      <div className="container page">
        <div className="row">
          <div className="col-md-6 offset-md-3 col-xs-12">
            <form>
              <fieldset>
                <fieldset className="form-group">
                  <p className="text-muted h5 py-2">Name</p>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Organization name"
                    name="name"
                    value={editOrganization?.name || ""}
                    onChange={changeName}
                    maxLength={50}
                  />
                </fieldset>{" "}
                <fieldset className="form-group">
                  <p className="text-muted h5 py-2">Email</p>
                  <input
                    className="form-control form-control-lg organization_input"
                    autoComplete="current-email"
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={editOrganization?.email || ""}
                    disabled
                    id="email"
                  />
                </fieldset>
                <div>
                  <fieldset className="form-group">
                    <p className="h5 text-muted">Logo</p>
                    <p className="text-muted h6 pb-2">Recomended logo size 200x200 (px). Format .jpg, .png.</p>

                    <div
                      className="image d-flex justify-content-center align-items-center position-relative"
                      style={{ height: "300px", margin: 0 }}
                    >
                      {editOrganization?.logo && !deleteImage ? (
                        <>
                          <img src={image} alt="" className="position-absolute" height="296" width="296" />
                          <div
                            className="position-absolute px-2 "
                            style={{
                              zIndex: 2,
                              marginLeft: "360px",
                              marginTop: "-250px",
                            }}
                          >
                            <button
                              type="button"
                              style={{ backgroundColor: "transparent", border: 0 }}
                              onClick={fileRemove}
                            >
                              <Icon style={{ fontSize: "50px" }} className="delete_icon">
                                delete
                              </Icon>
                            </button>
                          </div>
                        </>
                      ) : (
                        <div>
                          <span
                            className="d-flex justify-content-center align-items-center position-relative"
                            style={
                              isSelectedImage
                                ? {
                                    height: "296px",
                                    width: "296px",
                                  }
                                : {
                                    border: "2px dashed #ccc",
                                    height: "296px",
                                    width: "296px",
                                  }
                            }
                          >
                            <div className="text-center text-wrap w-75">
                              <Icon
                                style={{
                                  fontSize: "62px",
                                  color: "#6C757D",
                                  zIndex: 1,
                                }}
                              >
                                cloud_upload
                              </Icon>
                              <p className="font-weight-bold text-muted h5">Drop logo here or click to upload.</p>
                            </div>
                            <div id={organization?.id} style={{ position: "absolute" }} />
                            <input
                              className="h-100 w-100 position-absolute opacity-0 top-0 left-0"
                              type="file"
                              onChange={(event) => dragNdrop(event)}
                              onDragOver={drag}
                              onDrop={drop}
                              name={organization?.name}
                              id={organization?.id}
                            />
                            {isSelectedImage && (
                              <div
                                className="position-absolute px-2 "
                                style={{
                                  zIndex: 2,
                                  marginLeft: "360px",
                                  marginTop: "-250px",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{ backgroundColor: "transparent", border: 0 }}
                                  onClick={fileRemove}
                                >
                                  <Icon style={{ fontSize: "50px" }} className="delete_icon">
                                    delete
                                  </Icon>
                                </button>
                              </div>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  </fieldset>
                </div>
                <fieldset className="form-group ps-3">
                  <CreateButton
                    disabled={organization ? disabledbutton : editOrganization?.name?.replace(/\s/g, "").length === 0}
                    onSubmit={saveSettings}
                  />
                </fieldset>
              </fieldset>
            </form>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(EditOrganizationScreen);

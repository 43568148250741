import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import agent from "../agent";
import { serializeError } from "../common/utils";

export const inviteUser = createAsyncThunk("user/inviteUser", agent.User.inviteUser, { serializeError });

const initialState = {
  user: undefined,
};

const inviteUserSlice = createSlice({
  name: "inviteUser",
  initialState,
  reducers: {
    inviteUserPageUnloaded: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      () => {}
    );

    builder.addDefaultCase(() => {});
  },
});

export const { inviteUserPageUnloaded } = inviteUserSlice.actions;

export default inviteUserSlice.reducer;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { getTrace } from "../../reducers/trace";
import { formatDateString, formatDateNoTime } from "../../common/utils";
import "./timeSheet.css";
import "react-datepicker/dist/react-datepicker.css";

function TimeSheet({ projects }) {
  const dispatch = useDispatch();
  const { trace } = useSelector((state) => state.trace);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const todayDate = new Date();
  const [startDate, setStartDate] = useState(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
  const [endDate, setEndDate] = useState(todayDate);
  const [showDescriptionEdit, setShowDescriptionEdit] = useState(false);
  const [description, setDescription] = useState();
  const [descriptionId, setDescriptionId] = useState();
  const [page, setPage] = useState(1);
  const [isSelectedPage, setIsSelectedPage] = useState(1);
  const pageNumber = [];
  const maxPages = trace?.meta?.pageCount;

  for (let i = 1; i <= maxPages; i += 1) pageNumber.push(i);

  useEffect(() => {
    dispatch(
      getTrace({
        limit: 10,
        page,
        projectId: selectedProjectId,
        startDate: formatDateNoTime(new Date(startDate)),
        endDate: formatDateNoTime(new Date(endDate)),
      })
    );
  }, [page, selectedProjectId, startDate, endDate]);
  const changeDescription = (event) => {
    setDescription(event.target.value);
  };
  const clickedProject = (id) => {
    setSelectedProjectId(id);
  };

  const handleChange = (range) => {
    const [startDateRange, endDaterange] = range;
    setStartDate(startDateRange);
    setEndDate(endDaterange);
  };

  const onSave = () => {
    setShowDescriptionEdit(false);
  };

  const selectPage = (e) => {
    setIsSelectedPage(e.currentTarget.name);
    setPage(e.currentTarget.value);
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 flex-wrap ">
      <div className="d-flex justify-conent-between align-items-center my-3 mb-4">
        <ul
          className="nav nav-pills outline-active mb-3 w-100 "
          style={{
            alignItems: "center",
          }}
        >
          {projects?.map((project) => (
            <li className="nav-item">
              <button
                type="button"
                className={`nav-link d-block ${selectedProjectId === project.id ? "active" : ""}`}
                style={{
                  overflow: "hidden",
                  maxWidth: "100px",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
                onClick={() => clickedProject(project.id)}
              >
                {project.title}
              </button>
            </li>
          ))}
        </ul>{" "}
        <DatePicker
          className="form-control"
          onChange={handleChange}
          selected={todayDate}
          maxDate={todayDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          placeholderText="mm/dd/yyyy - mm/dd/yyyy"
        />
      </div>
      <div
        className="w-100 d-flex align-items-center justify-content-center mb-5"
        style={{ fontSize: "20px", fontWeight: 700 }}
      >
        {`${formatDateString(new Date(startDate))} - ${formatDateString(new Date(endDate))}`}{" "}
      </div>
      <div className="d-flex justify-content-center flex-wrap">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Task</th>
              <th scope="col">Description</th>
              <th scope="col">Hours</th>
            </tr>
          </thead>
          <tbody>
            {trace?.results?.map((user, index) => (
              <tr>
                <td>{formatDateString(new Date(user.triggerDate))}</td>
                <td>{`#${user.taskId}`}</td>
                {showDescriptionEdit && descriptionId === index ? (
                  <td className="d-flex border-bottom-0">
                    <input
                      className="form-control"
                      type="text"
                      style={{ width: "125px", height: "30px" }}
                      onChange={(e) => {
                        changeDescription(e, index);
                      }}
                      value={description}
                    />

                    <button
                      className="btn rounded-circle text-success position-absolute"
                      style={{ marginLeft: "120px" }}
                      type="button"
                      onClick={onSave}
                    >
                      <Icon style={{ fontSize: "18px" }}>check_circle</Icon>
                    </button>
                  </td>
                ) : (
                  <td>
                    <button
                      type="button"
                      className="d-flex text-dark border-0"
                      style={{ cursor: "pointer", background: "transparent" }}
                      onClick={() => {
                        setDescription(user?.description || "description");
                        setShowDescriptionEdit(true);
                        setDescriptionId(index);
                      }}
                    >
                      {" "}
                      {user?.description || "description"}
                    </button>
                  </td>
                )}

                <td>0</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th scope="row">Total</th>
              <td>{trace?.meta.itemCount}</td>
              <td />
              <td>0</td>
            </tr>
          </tfoot>
        </table>
        <nav>
          <ul className="pagination">
            <button
              className={`page-item page-link rounded-start page-item-custom ${
                isSelectedPage === "Previous" && "active"
              } ${!trace?.meta?.hasPreviousPage && "disabled"}`}
              name="Previous"
              value={1}
              onClick={selectPage}
              type="button"
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
            {pageNumber.map((pageNum) => (
              <button
                className={`page-item page-link page-item-custom ${+isSelectedPage === pageNum && "active"}`}
                value={pageNum}
                onClick={selectPage}
                type="button"
                name={pageNum}
              >
                {pageNum}
              </button>
            ))}
            <button
              className={`page-item page-link rounded-end page-item-custom ${isSelectedPage === "Next" && "active"} ${
                !trace?.meta?.hasNextPage && "disabled"
              }`}
              name="Next"
              value={maxPages}
              onClick={selectPage}
              type="button"
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </ul>
        </nav>
      </div>
    </div>
  );
}

TimeSheet.propTypes = {
  projects: PropTypes.instanceOf(Array),
};

TimeSheet.defaultProps = {
  projects: [],
};
export default TimeSheet;

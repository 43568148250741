import PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../features/auth/authSlice";
import { getAllProjects } from "../../reducers/projectList";
import MainView from "./MainView";

/**
 * Home screen component
 *
 * @example
 * <Home />
 */
function Home({ query }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const auth = window.localStorage.getItem("status");

  useEffect(() => {
    if (isAuthenticated) dispatch(getAllProjects({}));
  }, [isAuthenticated]);
  return (
    <div className="home-page">
      {auth === "true" && (
        <div className="container page my-0">
          <div className="row" style={{ backgroundColor: "#f7f7f7" }}>
            <MainView query={query} />
          </div>
        </div>
      )}
    </div>
  );
}

Home.propTypes = {
  query: PropTypes.string,
};

Home.defaultProps = {
  query: "",
};

export default memo(Home);

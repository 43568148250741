import React from "react";
import PropTypes from "prop-types";

function OnlineStatus({ isOnline }) {
  return (
    <div className="status-circle">
      {isOnline ? (
        <div
          className="status-circle online"
          role="status"
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "green",
            border: "2px solid white",
            bottom: "8px",
            right: 0,
            position: "absolute",
          }}
        />
      ) : (
        <div
          className="status-circle offline"
          role="status"
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "grey",
            border: "2px solid white",
            bottom: "8px",
            right: 0,
            position: "absolute",
          }}
        />
      )}
    </div>
  );
}
OnlineStatus.propTypes = {
  isOnline: PropTypes.bool,
};

OnlineStatus.defaultProps = {
  isOnline: false,
};
export default OnlineStatus;

import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getAllUsers } from "../../reducers/users";
import { getAllArticles } from "../../reducers/articleList";
import ArticlePreview from "./ArticlePreview";
import ArticleListPagination from "./ArticleListPagination";

/**
 * List all articles and show pagination
 *
 * @example
 * <ArticleList />
 */
function ArticleList({ articles }) {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const favoriteArticles = useSelector((state) => state.articles?.favoriteArticles);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [favoriteArticles]);
  useEffect(() => {
    dispatch(getAllArticles());
  }, [favoriteArticles]);

  if (articles?.length === 0) {
    return <div className="article-preview pt-3">No articles found...</div>;
  }

  return (
    <>
      {[...articles]
        .sort((a, b) => b.id - a.id)
        .map((article) => {
          const user = users?.find((isUser) => isUser.id === article.authorId);
          return (
            <ArticlePreview
              article={article}
              key={article.id}
              user={user}
              favorited={favoriteArticles?.some((e) => e.id === article.id)}
            />
          );
        })}

      <ArticleListPagination />
    </>
  );
}

ArticleList.propTypes = {
  articles: PropTypes.instanceOf(Array),
};

ArticleList.defaultProps = {
  articles: [],
};

export default memo(ArticleList);

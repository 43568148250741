import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";

/**
 * Show a preview of an article
 *
 * @param {Object} props
 * @param {Object} props.article
 * @example
 * <ArticlePreview
 *    article={{
 *      slug: 'how-to-train-your-dragon',
 *      title: 'How to train your dragon',
 *      description: 'Ever wonder how?',
 *      body: 'It takes a Jacobian',
 *      tagList: ['dragons', 'training'],
 *      createdAt: '2016-02-18T03:22:56.637Z',
 *      updatedAt: '2016-02-18T03:48:35.824Z',
 *      favorited: false,
 *      favoritesCount: 0,
 *      author: {
 *        username: 'jake',
 *        bio: 'I work at statefarm',
 *        image: 'https://i.stack.imgur.com/xHWG8.jpg',
 *        following: false,
 *      },
 *    }}
 * />
 */
function ArticlePreview({ article, user }) {
  return (
    <div className="article-preview">
      <div className="col d-flex justify-content-start">
        {article?.image_url ? (
          <img className="img center me-3" alt={article?.title} src={article?.image_url} width="250" height="145" />
        ) : (
          <span
            className="d-flex justify-content-center align-items-center position-relative me-3"
            style={{
              border: "2px solid #ccc",
              height: "145px",
              width: "250px",
            }}
          >
            <div className="text-center">
              <p className="font-weight-bold text-muted h5">No image</p>
            </div>
          </span>
        )}
        <div className="col">
          <div className="article-meta">
            <Link to={`/user/${user?.id}`} style={{ textDecoration: "none" }}>
              {user?.image ? (
                <img src={user?.image} alt={user?.username} />
              ) : (
                <Icon style={{ fontSize: "35px", color: "#6C757D", marginBottom: "-13px" }}>account_circle</Icon>
              )}
            </Link>

            <div className="info">
              <Link className="author" to={`/user/${user?.id}`} style={{ textDecoration: "none" }}>
                {user?.username}
              </Link>
              <time className="date" dateTime={article.createdAt}>
                {new Date(article.createdAt).toDateString()}
              </time>
            </div>
          </div>

          <Link to={`/news-feed/article/${article.id}`} className="preview-link" style={{ textDecoration: "none" }}>
            <h1>{article.title}</h1>
            <p>{article.description}</p>
            <span>Read more...</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

ArticlePreview.propTypes = {
  article: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object),
};

ArticlePreview.defaultProps = {
  user: {},
};

export default memo(ArticlePreview);

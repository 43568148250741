import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Icon from "@mui/material/Icon";
import { forgotPassword } from "./authSlice";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [responseStatus, setResponseStatus] = useState();
  const [responseError, setResponseError] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const validation = (event) => {
    setErrorMessage("");
    const message = event?.target?.value?.replaceAll(" ", "");
    const regex = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    if (event?.target?.id === "email")
      if (!message.match(regex)) setErrorMessage("Please match the format requested ex: example@domain.com");
      else setErrorMessage("");

    if (event?.target?.value.length === 0) setErrorMessage("");
  };
  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeEmail = (event) => {
    setEmail(event.target.value?.replaceAll(" ", ""));
    validation(event);
  };
  const submitForm = (event) => {
    setResponseStatus("");
    event.preventDefault();
    dispatch(forgotPassword(email)).then((response) => setResponseStatus(response?.type.includes("fulfilled")));
  };

  useEffect(() => {
    if (responseStatus === true) setResponseError("");
    else if (responseStatus === false) setResponseError("This email is not registered ");
  }, [responseStatus]);

  return (
    <div className="auth-page">
      <div className="container page">
        <div className="row d-flex align-items-center justify-content-center px-4 px-lg-0 px-md-0">
          {!responseStatus ? (
            <div className="auth_box">
              <form onSubmit={submitForm}>
                {" "}
                <p
                  style={{ fontSize: "40px", fontWeight: "600", paddingBottom: "30px" }}
                  className="text-xs-center m-0 font"
                >
                  Forgot your password?
                </p>
                {responseError && (
                  <div
                    className="d-flex justify-content-center align-items-center text-center bg-danger text-white rounded mb-2"
                    style={{ minHeight: "40px" }}
                  >
                    <Icon style={{ fontSize: "18px", color: "white", marginRight: "4px" }}>warning</Icon>
                    {responseError}
                  </div>
                )}
                <fieldset className="form-group">
                  <input
                    className={`${responseError && "border border-danger"} input form-control form-control-lg`}
                    type="text"
                    placeholder="Email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => {
                      changeEmail(e);
                    }}
                    id="email"
                  />
                  <div style={{ color: "red" }}>{errorMessage}</div>
                </fieldset>
                <button className="btn btn-lg send_button mt-2" type="submit" disabled={email === "" || errorMessage}>
                  Sent
                </button>
                <button
                  className="btn btn-lg cancel_button mt-2"
                  style={{ marginBottom: "110px" }}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </form>
            </div>
          ) : (
            <div className="auth_box" style={{ minHeight: "322px" }}>
              <fieldset className="form-group m-0">
                <p className="text-xs-center mt-3 pt-3 font px-lg-4" style={{ fontSize: "40px", fontWeight: "600" }}>
                  Please verify your email to finish your reset
                </p>
                <div className="d-flex justify-content-center">
                  <Icon className="d-flex justify-content-center" style={{ fontSize: "100px", color: "#13B7FF" }}>
                    mail_outline
                  </Icon>
                </div>
                <p className="text-xs-center mt-3 pb-3 font">{email}</p>
              </fieldset>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import agent from "../agent";
import { serializeError } from "../common/utils";

export const getTask = createAsyncThunk("task/getTask", agent.Tasks.get, {
  serializeError,
});

export const createTask = createAsyncThunk("task/createTask", agent.Tasks.create, { serializeError });
export const updateTask = createAsyncThunk("task/updateTask", agent.Tasks.update, { serializeError });

export const assignUser = createAsyncThunk("task/assignUser", agent.Tasks.assignUser, { serializeError });
export const unAssignUser = createAsyncThunk("task/unAssignUser", agent.Tasks.unAssignUser, { serializeError });

export const upload = createAsyncThunk("upload/task", agent.Tasks.upload, {
  serializeError,
});

const initialState = {
  task: undefined,
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    taskPageUnloaded: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTask.fulfilled, (taskState, action) => {
      const state = taskState;
      state.task = action.payload.task;
    });

    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      () => {}
    );

    builder.addDefaultCase(() => {});
  },
});

export const { taskPageUnloaded } = taskSlice.actions;

export default taskSlice.reducer;

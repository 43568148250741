import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Members from "../../common/Members";
import { selectUser } from "../../features/auth/authSlice";
import EditButton from "../../utils/editButton";

function OrganizationScreen() {
  const location = useLocation();
  const { organization } = useSelector((state) => state.organization);
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    if (organization?.name) {
      document.getElementsByTagName("title")[0].innerHTML = organization?.name;
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="profile-page container">
        <div className="user-info" style={{ backgroundColor: "#F1F1F1", minHeight: "232px" }}>
          <div className="row">
            <div className="col-xs-12 col-md-10 offset-md-1">
              {organization?.logo ? (
                <img src={organization?.logo} width="100" height="100" className="mb-4" alt={organization?.name} />
              ) : (
                <img
                  src="https://api.images.northweb.solutions/important/logo-pm.jpg"
                  alt=""
                  width="100"
                  height="100"
                  className="mb-4"
                />
              )}
              <h4>{organization?.name}</h4>
            </div>
          </div>
          <div style={{ marginRight: "17px" }}>
            {currentUser?.role?.name === "manager" && <EditButton link={`${location.pathname}/edit-organization`} />}
          </div>
        </div>
      </div>
      <div className="profile-page container">
        <div className="mt-3 user-info container p-3" style={{ minHeightheight: "151px", backgroundColor: "#F1F1F1" }}>
          <div className="user-info container bg-white h-100 p-0">
            <div className="d-flex px-3 pt-2 pb-2 pt-3">
              <Icon style={{ fontSize: "25px", color: "#6C757D", marginTop: "9px" }}>email</Icon>
              <p
                style={{
                  color: "#6C757D",
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
                className="me-0 ms-3 mt-2"
              >
                Email:
              </p>
              <p
                style={{
                  color: "#6C757D",
                  fontSize: "17px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                className="ms-2 mt-2"
              >
                {organization?.email}
              </p>
            </div>
            <div className="col d-flex justify-content-start bg-white px-3 py-2">
              <Icon style={{ fontSize: "25px", color: "#6C757D", marginTop: "9px" }}>folder</Icon>
              <p style={{ color: "#6C757D", fontWeight: "bold", fontSize: "17px" }} className="me-0 ms-3 mt-2">
                Projects:
              </p>
              <div className="d-flex flex-wrap">
                {organization?.projects?.map((project) => (
                  <p
                    style={{
                      color: "#6C757D",
                      fontSize: "17px",
                      margin: 0,
                    }}
                    className="ms-2 mt-2"
                    key={project.id}
                  >
                    {project.title}
                  </p>
                ))}
              </div>
            </div>
            <div className="d-flex justify-content-start bg-white px-3 py-2 pb-3">
              <Icon style={{ fontSize: "25px", color: "#6C757D", marginTop: "8px" }}>people</Icon>
              <p style={{ color: "#6C757D", fontWeight: "bold", fontSize: "17px" }} className="me-0 ms-3 mt-2">
                Users:
              </p>
              <div className="d-flex flex-wrap ms-3 mt-2">
                <Members members={organization?.users} isOrganization />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrganizationScreen;

import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import "../components/style.css";

/**
 * Shows a banner for new users
 *
 * @example
 * <Banner />
 */
function CreateButton({ disabled, onSubmit, label = "Save", modal }) {
  const navigate = useNavigate();
  const onCancel = () => {
    if (!modal) {
      navigate(-1);
    }
  };
  return (
    <div className="d-flex justify-content-end pt-2">
      <button
        className="green_button btn"
        type={label === "Send" ? "submit" : "button"}
        disabled={disabled}
        onClick={onSubmit}
        data-bs-dismiss={modal ? "modal" : ""}
      >
        {label}
      </button>
      <button className="gray_button btn" type="button" onClick={onCancel} data-bs-dismiss={modal ? "modal" : ""}>
        Cancel
      </button>
    </div>
  );
}
CreateButton.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
  modal: PropTypes.bool,
};

CreateButton.defaultProps = {
  disabled: false,
  label: "Save",
  modal: false,
};

export default CreateButton;

import { Editor } from "@tinymce/tinymce-react";
import PropTypes from "prop-types";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { formatDate } from "../../common/utils";
import "../../components/style.css";
import { getProject } from "../../reducers/project";
import { getTask } from "../../reducers/task";
import { selectIsAuthenticated, selectUser } from "../auth/authSlice";
import CommentList from "./CommentList";
import {
  createArticleComment,
  createProjectComment,
  createTaskComment,
  editProjectComment,
  editTaskComment,
  editArticleComment,
} from "./commentsSlice";
import Toaster from "../../common/Toaster";
import { getArticle } from "../../reducers/article";

/**
 * Add comment
 *
 * @example
 * <CommentForm />
 */
function CommentForm({ setComments, comments, path, isEdit, comment, setIsEdit }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const { slug } = useParams();
  const { task } = useSelector((state) => state.task);
  const { status } = useSelector((state) => state.comments);
  const [toasterMessage, setToasterMessage] = useState();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(isEdit ? comment?.body : "");
  }, [isEdit]);

  /**
   * @type {React.ChangeEventHandler<HTMLTextAreaElement>}
   */

  const changeBody = (content) => {
    const limit = 500;
    if (content.length > limit) {
      setToasterMessage("The field must contain no more than 500 characters.");
    } else {
      setValue(content);
    }
    if (content.length < limit) {
      setToasterMessage(null);
    }
  };
  useEffect(() => {
    if (status === "success" && path.includes("/article")) dispatch(getArticle(slug));
    else if (status === "success" && !path.includes("/article")) dispatch(task ? getTask(task?.id) : getProject(slug));
  }, [status]);
  /**
   * @type {React.FormEventHandler<HTMLFormElement>}
   */
  const saveComment = async (event) => {
    event.preventDefault();

    if (path.includes("/project-tasks" || "/project-detail")) {
      dispatch(
        isEdit
          ? editTaskComment({ commentId: comment?.id, comment: { body: value } })
          : createTaskComment({ taskSlug: task?.id, comment: { body: value } })
      );
    } else if (path.includes("/article")) {
      dispatch(
        isEdit
          ? editArticleComment({ commentId: comment?.id, comment: { body: value } })
          : createArticleComment({ articleSlug: slug, comment: { body: value } })
      );
    } else {
      dispatch(
        isEdit
          ? editProjectComment({ commentId: comment?.id, comment: { body: value } })
          : createProjectComment({ projectSlug: slug, comment: { body: value } })
      );
    }

    setValue("");

    const updateComments = [
      ...comments,
      {
        body: value,
        authorId: currentUser.id,
        createdAt: formatDate(new Date()),
      },
    ];
    setComments(updateComments);
    setIsEdit(false);
  };
  const editorRef = useRef(null);

  return (
    <>
      <Toaster message={toasterMessage} type="warning" />
      <h6 className="card-subtitle text-muted mb-3">Comments ({comments?.length})</h6>
      <form className="comment-form">
        <Editor
          onInit={(editor) => {
            editorRef.current = editor;
          }}
          initialValue=""
          value={value}
          onEditorChange={changeBody}
          apiKey="ohu4mzel9wdugza1y231epy17k8iloze7z78ssej5t96b9lt"
          init={{
            height: 200,
            menubar: false,
            branding: false,
            elementpath: false,
            selector: "textarea",
            plugins: ["emoticons", "lists"],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist checklist outdent indent | " +
              "removeformat emoticons",
          }}
        />

        <div className="my-3 d-grid gap-3 d-flex justify-content-end">
          {isEdit && (
            <button
              className="btn btn-sm gray_button"
              style={{ margin: 0 }}
              type="submit"
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </button>
          )}
          <button
            className="btn blue_button"
            type="submit"
            disabled={value === "" || value === comment?.body}
            onClick={saveComment}
          >
            Comment
          </button>
        </div>
      </form>
    </>
  );
}

CommentForm.propTypes = {
  setComments: PropTypes.func.isRequired,
  setIsEdit: PropTypes.func.isRequired,
  comments: PropTypes.instanceOf(Array),
  path: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  comment: PropTypes.number,
};
CommentForm.defaultProps = {
  comments: [],
  comment: 0,
};
/**
 * Comments for a project
 *
 * @example
 * <CommentSection />
 */
function CommentSection() {
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [comment, setComment] = useState(0);
  const { project } = useSelector((state) => state.project);
  const { article } = useSelector((state) => state.article);
  const { slug } = useParams();
  const { task } = useSelector((state) => state.task);
  const path = location.pathname;
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if ((path.includes("task-detail") || path === `/project-detail/${slug}/project-tasks/${slug}`) && task) {
      setComments(task?.comments);
    }
    if (path === `/project-detail/${slug}`) {
      setComments(project?.comments);
    }
    if (path.includes("/article")) setComments(article?.Comments);
  }, [project, task, article]);

  return (
    <div className="row">
      {isAuthenticated ? (
        <div className="col-xs-12 col-md-12">
          <CommentForm
            comments={comments}
            setComments={setComments}
            path={path}
            isEdit={isEdit}
            comment={comment}
            setIsEdit={setIsEdit}
          />

          <CommentList comments={comments} setComments={setComments} setIsEdit={setIsEdit} setComment={setComment} />
        </div>
      ) : null}
    </div>
  );
}

export default memo(CommentSection);

import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { changeProjectTab } from "../../reducers/projectList";

/**
 * Show all tags in the sidebar
 *
 * @example
 * <TagsSidebar />
 */
function TagsSidebar({ setProjects, projects }) {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState("All");
  const { projects: allProjects } = useSelector((state) => state.projects);
  const findByStatus = (e) => {
    const filteredProjects = allProjects?.filter((obj) => obj.status === e.target.innerHTML);
    setProjects(filteredProjects);
  };

  let sortedProjects = [];
  if (projects !== undefined) {
    sortedProjects = [...projects].sort((a, b) => a.id - b.id);
  }
  useEffect(() => {
    setProjects(sortedProjects);
  }, []);

  const changeActiveLink = (name) => {
    setIsSelected(name);
  };
  /**
   * Change to all tab
   * @type{React.MouseEventHandler}
   */
  const dispatchChangeTab = () => {
    dispatch(changeProjectTab("all"));
  };

  return (
    <div>
      <nav>
        <ul className="pagination pagination-md">
          <li
            className={isSelected === "All" ? "page-item active" : "page-item text-dark"}
            style={{ cursor: "pointer" }}
          >
            <button
              type="submit"
              className={isSelected === "All" ? "page-link" : "page-link text-dark"}
              onClick={() => {
                dispatchChangeTab();
                changeActiveLink("All");
              }}
              onKeyUp={() => {
                dispatchChangeTab();
                changeActiveLink("All");
              }}
            >
              All
            </button>
          </li>
          <li
            className={isSelected === "Ongoing" ? "page-item active" : "page-item text-dark"}
            style={{ cursor: "pointer" }}
          >
            <button
              type="submit"
              className={isSelected === "Ongoing" ? "page-link" : "page-link text-dark"}
              onClick={(e) => {
                findByStatus(e);
                changeActiveLink("Ongoing");
              }}
            >
              Ongoing
            </button>
          </li>
          <li
            className={isSelected === "Finished" ? "page-item active" : "page-item text-dark"}
            style={{ cursor: "pointer" }}
          >
            <button
              type="submit"
              className={isSelected === "Finished" ? "page-link" : "page-link text-dark"}
              onClick={(e) => {
                findByStatus(e);
                changeActiveLink("Finished");
              }}
            >
              Finished
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}
TagsSidebar.propTypes = {
  setProjects: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(Array),
};
TagsSidebar.defaultProps = {
  projects: [],
};
export default memo(TagsSidebar);

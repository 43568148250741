import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import agent from "../agent";
import { articlePageUnloaded, createArticle, updateArticle } from "./article";
import { createProject, projectPageUnloaded, updateProject } from "./project";
import { inviteUserPageUnloaded } from "./user";
import { organizationPageUnloaded } from "./organization";
import { serializeError } from "../common/utils";
import { getUser, login, logout, register, setToken, updateUser } from "../features/auth/authSlice";
import { homePageUnloaded } from "./articleList";
import { tracePageUnloaded } from "./trace";
import { profilePageUnloaded } from "./profile";
import { createTask, taskPageUnloaded } from "./task";
import { clearErrors } from "./errors";

export const deleteArticle = createAsyncThunk("common/deleteArticle", agent.Articles.del, { serializeError });

export const deleteTask = createAsyncThunk("common/deleteTask", agent.Tasks.del, { serializeError });

export const deleteProject = createAsyncThunk("common/deleteProject", agent.Projects.del, { serializeError });

export const deleteUser = createAsyncThunk("common/deleteUser", agent.Projects.delUser, { serializeError });

export const deleteUpload = createAsyncThunk("common/deleteUpload", agent.Tasks.deleteUpload, { serializeError });

const initialState = {
  appName: "Project Management",
  appLoaded: false,
  viewChangeCounter: 0,
  redirectTo: undefined,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    loadApp(appState) {
      const state = appState;
      state.appLoaded = true;
    },
    clearRedirect(redirectState) {
      const state = redirectState;
      delete state.redirectTo;
      delete state.deleteUploadRes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteArticle.fulfilled, (redirectState) => {
      const state = redirectState;
      state.redirectTo = "/";
    });

    builder.addCase(deleteTask.fulfilled, (redirectState) => {
      const state = redirectState;
      state.redirectTo = "/";
    });

    builder.addCase(deleteUser.fulfilled, (redirectState, action) => {
      const state = redirectState;
      state.deletedUser = action.payload.user;
      state.redirectTo = "/users";
    });

    builder.addCase(deleteProject.fulfilled, (redirectState) => {
      const state = redirectState;
      state.redirectTo = "/";
    });

    builder.addCase(updateUser.fulfilled, (redirectState) => {
      const state = redirectState;
      state.redirectTo = "/";
    });

    builder.addCase(login.fulfilled, (redirectState) => {
      const state = redirectState;
      state.redirectTo = "/";
    });

    builder.addCase(register.fulfilled, (redirectState) => {
      const state = redirectState;
      state.redirectTo = "/";
    });

    builder.addCase(logout, (redirectState) => {
      const state = redirectState;
      state.redirectTo = "/";
    });

    builder.addCase(createArticle.fulfilled, (redirectState, action) => {
      const state = redirectState;
      state.redirectTo = `/article/${action.payload.article?.slug}`;
    });

    builder.addCase(updateArticle.fulfilled, (redirectState, action) => {
      const state = redirectState;
      state.redirectTo = `/article/${action.payload.article?.slug}`;
    });

    builder.addCase(createProject.fulfilled, (redirectState, action) => {
      const state = redirectState;
      state.redirectTo = `/project/${action.payload.project?.slug}`;
    });

    builder.addCase(updateProject.fulfilled, (redirectState, action) => {
      const state = redirectState;
      state.redirectTo = `/project/${action.payload.project?.slug}`;
    });

    builder.addCase(createTask.fulfilled, (redirectState, action) => {
      const state = redirectState;
      state.redirectTo = `/task/${action.payload.task?.slug}`;
    });

    builder.addCase(deleteUpload.fulfilled, (redirectState) => {
      const state = redirectState;
      state.deleteUploadRes = true;
    });

    builder.addMatcher(
      (action) =>
        [
          articlePageUnloaded.type,
          homePageUnloaded.type,
          inviteUserPageUnloaded.type,
          profilePageUnloaded.type,
          projectPageUnloaded.type,
          clearErrors.type,
          taskPageUnloaded.type,
          organizationPageUnloaded?.type,
          tracePageUnloaded?.type,
        ].includes(action.type),
      (viewState) => {
        const state = viewState;
        state.viewChangeCounter += 1;
      }
    );
  },
});

export const appLoad = (token) => (dispatch) => {
  dispatch(commonSlice.actions.loadApp());

  if (token) {
    agent.setToken(token);
    dispatch(setToken(token));
    return dispatch(getUser());
  }
  return false;
};

export const { clearRedirect } = commonSlice.actions;

export default commonSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import agent from "../agent";
import { serializeError } from "../common/utils";

export const getProfile = createAsyncThunk("profile/getProfile", agent.Profile.get, { serializeError });

const profileSlice = createSlice({
  name: "profile",
  initialState: {},
  reducers: {
    profilePageUnloaded: () => ({}),
  },
  extraReducers: (builder) => {
    const successCaseReducer = (_, action) => ({
      ...action.payload,
    });

    builder.addCase(getProfile.fulfilled, successCaseReducer);
  },
});

export const { profilePageUnloaded } = profileSlice.actions;

export default profileSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../agent";
import { profilePageUnloaded } from "./profile";
import { serializeError } from "../common/utils";

export const getAllRoles = createAsyncThunk("roles/getAll", agent.User.allRoles, { serializeError });

const initialState = {
  roles: [],
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (rolesState, action) => {
      const state = rolesState;
      state.tab = action.payload;
      delete state.tag;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRoles.fulfilled, (rolesState, action) => {
      const state = rolesState;
      state.roles = action.payload.roles;
    });

    builder.addMatcher(
      (action) => [profilePageUnloaded.type].includes(action.type),
      () => initialState
    );
  },
});

export const changeTab = (tab) => (dispatch) => {
  dispatch(rolesSlice.actions.changeTab(tab));
  return dispatch(getAllRoles());
};

export const { homePageUnloaded } = rolesSlice.actions;

export default rolesSlice.reducer;

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@mui/material";
import Toaster from "../../common/Toaster";
import { selectUser, updateUser, getUser, uploadAvatar, deleteAvatar } from "./authSlice";
import CreateButton from "../../utils/createButton";

/**
 * Settings form component
 *
 * @param {Object} props
 * @param {import('./authSlice').User} props.currentUser
 * @param {(user: Partial<import('./authSlice').User>) => Promise<any>} props.onSaveSettings
 * @example
 * <SettingsForm
 *    currentUser={{
 *      username: 'warren_boyd',
 *      email: 'warren.boyd@mailinator.com',
 *      image: 'https://static.productionready.io/images/smiley-cyrus.jpg',
 *      bio: null,
 *    }}
 *    onSaveSettings={user => dispatch(updateUser(user))}
 * />
 */
function EditProfileScreen() {
  const currentUser = useSelector(selectUser);
  const [userEditProfile, setUserEditProfile] = useState();
  const [responseMessage, setResponeMessage] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [disabledbutton, setDisabledButton] = useState(true);
  const [image, setImage] = useState();
  const [isSelectedImage, setIsSelectedImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageData, setImageData] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [deleteImage, setDeleteImage] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setUserEditProfile(currentUser);
  }, [currentUser]);

  useEffect(() => {
    setImage(currentUser?.image);
  }, [currentUser?.image]);

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeUsername = (event) => {
    const username = event.target.value;
    if (currentUser.username?.trim() !== event.target.value?.trim() && username.replace(/\s/g, "").length !== 0)
      setDisabledButton(false);
    else setDisabledButton(true);

    setUserEditProfile({ ...userEditProfile, username: username?.trimStart() });
  };

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeBio = (event) => {
    if (userEditProfile.username !== event.target.value) {
      setDisabledButton(false);
    }
    setUserEditProfile({ ...userEditProfile, bio: event.target.value?.trimStart() });
  };

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const changeEmail = (event) => {
    setUserEditProfile({ ...userEditProfile, email: event.target.value });
  };

  const fileRemove = async () => {
    setDeleteImage(true);
    const preview = document.getElementById(currentUser?.id);
    preview?.removeChild(previewImage);
    setPreviewImage(null);
    setIsSelectedImage(false);
    setImageData(null);
    setDisabledButton(false);
  };

  const validation = (event) => {
    const message = event?.target?.value?.trimStart();

    const specialCase = /.*[-'/`~!#$@_%+=.,^&(){}[\];:"<>?\\]/;
    if (message.match(specialCase)) setErrorMessage("Please create a username with only alphanumeric characters.");
    else setErrorMessage("");
  };
  /**
   * @type {React.FormEventHandler<HTMLFormElement>}
   */
  const saveSettings = (event) => {
    setSuccessMessage("");
    event.preventDefault();

    const user = {
      image: deleteImage ? null : userEditProfile?.image,
      username: userEditProfile?.username?.trim(),
      bio: userEditProfile?.bio?.trim(),
      email: userEditProfile?.email,
      id: userEditProfile?.id,
      role: userEditProfile?.role.name,
    };

    if (userEditProfile !== currentUser) dispatch(updateUser(user)).then((response) => setResponeMessage(response));
    if (imageData) dispatch(uploadAvatar(imageData)).then((response) => setResponeMessage(response));
    if (deleteImage)
      dispatch(deleteAvatar()).then((response) => {
        setResponeMessage(response);
        setDeleteImage(false);
        setUserEditProfile({ ...userEditProfile, image: null });
      });
  };

  useEffect(() => {
    if (responseMessage?.type.includes("fulfilled")) {
      setSuccessMessage("User has been successfully updated");
      dispatch(getUser());
    }
  }, [responseMessage]);

  async function dragNdrop(event) {
    const filename = URL.createObjectURL(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    setImageData(formData);
    const preview = document.getElementById(currentUser?.id); // the div where we will show image
    const previewImg = document.createElement("img"); // creating new image tag
    previewImg.setAttribute("src", filename); // setting attribute src of newly created "img" element to "filename"
    previewImg.setAttribute("height", 296);
    previewImg.setAttribute("width", 296);
    previewImg.setAttribute("style", "border-radius: 148px");
    preview.innerHTML = "";
    preview.appendChild(previewImg); // finally append "img" element to parent element "preview"
    setPreviewImage(previewImg);
    setIsSelectedImage(true);
    setDisabledButton(false);
  }

  // 2. drag
  const drag = () => {
    // while dragging it will fire this function that will add the following className
    document.getElementById(currentUser?.id);
  };

  // 3. finally the drop()
  const drop = () => {
    document.getElementById(currentUser?.id);
  };

  return (
    <div className="editProfile-page">
      <Toaster type="success" message={successMessage} />
      <div className="page">
        <div className="col-md-10 offset-md-1 col-xs-12">
          <form>
            <fieldset>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xs-12">
                    <fieldset className="form-group">
                      <p className="text-muted h5 pb-2">Username</p>
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Username"
                        name="username"
                        value={userEditProfile?.username || ""}
                        onChange={(e) => {
                          changeUsername(e);
                          validation(e);
                        }}
                        maxLength={50}
                      />
                      <div style={{ color: "red" }}>{errorMessage}</div>
                    </fieldset>
                    <fieldset className="form-group">
                      <p className="text-muted h5 pb-2">Bio</p>
                      <textarea
                        className="form-control form-control-lg"
                        rows={5}
                        maxLength={250}
                        placeholder="Short bio about you"
                        name="bio"
                        value={userEditProfile?.bio || ""}
                        onChange={changeBio}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <p className="text-muted h5 pb-2">Email</p>
                      <input
                        className="form-control form-control-lg input"
                        autoComplete="current-email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={userEditProfile?.email || ""}
                        onChange={changeEmail}
                        disabled={userEditProfile?.email}
                      />
                    </fieldset>
                    <fieldset className="form-group ps-3">
                      <Link
                        to={`${location.pathname}/reset-password?email=${userEditProfile?.email}`}
                        className="preview-link"
                      >
                        <p style={{ fontSize: "20px" }}>Reset Password</p>
                      </Link>
                    </fieldset>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xs-12">
                    <div>
                      <fieldset className="form-group">
                        <p className="h5 text-muted">Avatar</p>
                        <p className="text-muted h6 pb-2">Recomended thumbnail size 400x400 (px). Format .jpg, .png.</p>
                        <div
                          className="image d-flex justify-content-center align-items-center position-relative"
                          style={{ height: "300px", margin: 0, marginTop: "83px" }}
                        >
                          {userEditProfile?.image && !deleteImage ? (
                            <>
                              <img
                                src={image}
                                alt=""
                                className="position-absolute"
                                height="296"
                                width="296"
                                style={{ borderRadius: "50%" }}
                              />
                              <div
                                className="position-absolute px-2 "
                                style={{
                                  zIndex: 2,
                                  marginLeft: "260px",
                                  marginTop: "-250px",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{ backgroundColor: "transparent", border: 0 }}
                                  onClick={fileRemove}
                                >
                                  <Icon style={{ fontSize: "50px" }} className="delete_icon">
                                    delete
                                  </Icon>
                                </button>
                              </div>
                            </>
                          ) : (
                            <div>
                              <span
                                className="d-flex justify-content-center align-items-center position-relative"
                                style={
                                  isSelectedImage
                                    ? {
                                        height: "296px",
                                        width: "296px",
                                      }
                                    : {
                                        border: "2px dashed #ccc",
                                        height: "296px",
                                        width: "296px",
                                        borderRadius: "50%",
                                      }
                                }
                              >
                                <div className="text-center text-wrap w-75">
                                  <Icon
                                    style={{
                                      fontSize: "62px",
                                      color: "#6C757D",
                                      zIndex: 1,
                                    }}
                                  >
                                    cloud_upload
                                  </Icon>
                                  <p className="font-weight-bold text-muted h5">Drop avatar here or click to upload.</p>
                                </div>
                                <div id={currentUser?.id} style={{ position: "absolute" }} />
                                <input
                                  className="h-100 w-100 position-absolute opacity-0 top-0 left-0 rounded-circle"
                                  type="file"
                                  onChange={(event) => dragNdrop(event)}
                                  onDragOver={drag}
                                  onDrop={drop}
                                  name={currentUser?.username}
                                  id={currentUser?.id}
                                />
                                {isSelectedImage && (
                                  <div
                                    className="position-absolute px-2 "
                                    style={{
                                      zIndex: 2,
                                      marginLeft: "260px",
                                      marginTop: "-250px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{ backgroundColor: "transparent", border: 0 }}
                                      onClick={fileRemove}
                                    >
                                      <Icon style={{ fontSize: "50px" }} className="delete_icon">
                                        delete
                                      </Icon>
                                    </button>
                                  </div>
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <fieldset className="form-group pt-2">
                    <CreateButton disabled={disabledbutton || errorMessage} onSubmit={saveSettings} />
                  </fieldset>
                </div>
              </div>
            </fieldset>
          </form>{" "}
        </div>
      </div>
    </div>
  );
}

export default memo(EditProfileScreen);
